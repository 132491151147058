@import "font-awesome";
@import "animate.min";
@import "jquery.minicolors";
@import "slick";
@import "slick-theme";
@import "bootstrap-custom.min";
@import "linecons";
@import url(https://fonts.googleapis.com/css?family=Raleway:400,700,500,600,300,200,100,800,900);
@import "theme-custom";

body, html {
  width: 100%;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 22px
}


p {
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 11px
}

ul {
  margin-bottom: 11px
}

button {
  margin: 2px
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 20px;
  margin-bottom: 10px;
  text-transform: uppercase
}

h1, h2, h3 {
  text-align: center
}

h1 {
  font-size: 100px;
  line-height: 120px
}

@media (max-width: 991px) {
  h1 {
    font-size: 60px;
    line-height: 72px
  }
}

h2 {
  font-size: 70px;
  line-height: 84px
}

@media (max-width: 991px) {
  h2 {
    font-size: 35px;
    line-height: 48px
  }
}

h3 {
  font-size: 30px;
  line-height: 36px
}

@media (max-width: 991px) {
  h3 {
    font-size: 25x;
    line-height: 30px
  }
}

h4 {
  font-size: 20px;
  line-height: 24px
}

@media (max-width: 991px) {
  h4 {
    font-size: 15px;
    line-height: 18px
  }
}

h5 {
  font-size: 17px;
  line-height: 20px
}

@media (max-width: 991px) {
  h5 {
    font-size: 14px;
    line-height: 17px
  }
}

h6 {
  font-size: 15px;
  line-height: 18px
}

@media (max-width: 991px) {
  h6 {
    font-size: 13px;
    line-height: 16px
  }
}

a, a i {
  -webkit-transition: color .3s, background-color .3s;
  -moz-transition: color .3s, background-color .3s;
  -ms-transition: color .3s, background-color .3s;
  -o-transition: color .3s, background-color .3s;
  transition: color .3s, background-color .3s
}

.android-browser-4_3minus a, .android-browser-4_3minus a i {
  transition: none;
  -webkit-transition: none
}

a {
  text-decoration: none
}

a:focus {
  outline: 0;
  text-decoration: none
}

a:active, a:hover {
  text-decoration: none
}

form {
  margin-bottom: 10px
}

input {
  height: 44px
}

textarea {
  height: 220px
}

iframe {
  border: none
}

body, html {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  font-weight: 400
}

h1, h2, h3, h4, h5, h6 {
  font-family: Raleway, Arial, Helvetica, sans-serif
}

h1 {
  font-weight: 800
}

h2, h3, h4, h5 {
  font-weight: 700
}

h6 {
  font-weight: 400
}

strong {
  font-weight: 500
}

b {
  font-weight: 700
}

.btn, a.button, input.button {
  font-weight: 400
}

input, textarea {
  width: 100%;
  padding: 10px;
  font-weight: 700;
  border: 1px solid
}

input.button {
  font-weight: 400;
  float: right;
  width: auto;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s
}

.android-browser-4_3minus input.button {
  transition: none;
  -webkit-transition: none
}

input, textarea {
  outline: solid 1px;
  -webkit-transition: outline-color .2s;
  -moz-transition: outline-color .2s;
  -ms-transition: outline-color .2s;
  -o-transition: outline-color .2s;
  transition: outline-color .2s
}

.android-browser-4_3minus input, .android-browser-4_3minus textarea {
  transition: none;
  -webkit-transition: none
}

.thin-font-weight {
  font-weight: 100 !important
}

.extra-light-font-weight {
  font-weight: 200 !important
}

.light-font-weight {
  font-weight: 300 !important
}

.nirmal-font-weight {
  font-weight: 400 !important
}

.medium-font-weight {
  font-weight: 500 !important
}

.semi-bold-font-weight {
  font-weight: 600 !important
}

.bold-font-weight {
  font-weight: 700 !important
}

.extra-bold-font-weight {
  font-weight: 800 !important
}

.ultra-font-weight {
  font-weight: 900 !important
}

.heading-font, .normal-font {
  font-family: Raleway, Arial, Helvetica, sans-serif
}

.small-font {
  font-size: 11px
}

.counter .count {
  font-family: Helvetica, Arial, Sans-serif
}

.font-weight-100 {
  font-weight: 100
}

.font-weight-200 {
  font-weight: 200
}

.font-weight-300 {
  font-weight: 300
}

.font-weight-400 {
  font-weight: 400
}

.font-weight-500 {
  font-weight: 500
}

.font-weight-600 {
  font-weight: 600
}

.font-weight-700 {
  font-weight: 700
}

.font-weight-800 {
  font-weight: 800
}

.font-weight-900 {
  font-weight: 900
}

#top-nav {
  min-height: 0;
  margin: 0 24px;
  border: none;
  border-bottom: 1px solid;
  font-family: Raleway, Arial, Helvetica, sans-serif;
  font-weight: 800;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s
}

.android-browser-4_3minus #top-nav {
  transition: none;
  -webkit-transition: none
}

#top-nav .nav > li > a, #top-nav .navbar-brand {
  padding-top: 10px;
  padding-bottom: 10px
}

#top-nav .dropdown-menu > li > a {
  font-weight: 800
}

#top-nav li {
  text-transform: uppercase;
  font-size: 12px
}

@media (max-width: 1199px) {
  #top-nav li a {
    padding: 18px 12px
  }
}

@media (max-width: 991px) {
  #top-nav li a {
    padding: 18px 8px
  }
}

@media (max-width: 1199px) {
  #top-nav li {
    font-size: 10px
  }
}

@media (max-width: 991px) {
  #top-nav li {
    font-size: 9px
  }
}

#top-nav .navbar-brand {
  font-weight: 800;
  font-size: 20px;
  height: auto
}

@media (max-width: 1199px) {
  #top-nav .navbar-brand {
    font-size: 18px
  }
}

@media (max-width: 991px) {
  #top-nav .navbar-brand {
    font-size: 16px
  }
}

.state1 #top-nav .dropdown-menu > li > a, .state1 #top-nav .navbar-brand, .state1 #top-nav .navbar-header, .state1 #top-nav li, .state1 #top-nav li > a {
  line-height: 68px;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s
}

.android-browser-4_3minus .state1 #top-nav .dropdown-menu > li > a, .android-browser-4_3minus .state1 #top-nav .navbar-brand, .android-browser-4_3minus .state1 #top-nav .navbar-header, .android-browser-4_3minus .state1 #top-nav li, .android-browser-4_3minus .state1 #top-nav li > a {
  transition: none;
  -webkit-transition: none
}

.state1 #top-nav .dropdown-menu > li > a .dropdown-menu > li > a, .state1 #top-nav .dropdown-menu > li > a li, .state1 #top-nav .dropdown-menu > li > a li > a, .state1 #top-nav .navbar-brand .dropdown-menu > li > a, .state1 #top-nav .navbar-brand li, .state1 #top-nav .navbar-brand li > a, .state1 #top-nav .navbar-header .dropdown-menu > li > a, .state1 #top-nav .navbar-header li, .state1 #top-nav .navbar-header li > a, .state1 #top-nav li .dropdown-menu > li > a, .state1 #top-nav li li, .state1 #top-nav li li > a, .state1 #top-nav li > a .dropdown-menu > li > a, .state1 #top-nav li > a li, .state1 #top-nav li > a li > a {
  line-height: 20px
}

@media (max-width: 767px) {
  .state1 #top-nav li, .state1 #top-nav li > a {
    line-height: 30px
  }
}

@media (max-width: 767px) {
  .state1 #top-nav .dropdown-menu > li > a, .state1 #top-nav .navbar-brand, .state1 #top-nav .navbar-header, .state1 #top-nav li, .state1 #top-nav li > a {
    line-height: 28px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s
  }
  .android-browser-4_3minus .state1 #top-nav .dropdown-menu > li > a, .android-browser-4_3minus .state1 #top-nav .navbar-brand, .android-browser-4_3minus .state1 #top-nav .navbar-header, .android-browser-4_3minus .state1 #top-nav li, .android-browser-4_3minus .state1 #top-nav li > a {
    transition: none;
    -webkit-transition: none
  }
  .state1 #top-nav .dropdown-menu > li > a .dropdown-menu > li > a, .state1 #top-nav .dropdown-menu > li > a li, .state1 #top-nav .dropdown-menu > li > a li > a, .state1 #top-nav .navbar-brand .dropdown-menu > li > a, .state1 #top-nav .navbar-brand li, .state1 #top-nav .navbar-brand li > a, .state1 #top-nav .navbar-header .dropdown-menu > li > a, .state1 #top-nav .navbar-header li, .state1 #top-nav .navbar-header li > a, .state1 #top-nav li .dropdown-menu > li > a, .state1 #top-nav li li, .state1 #top-nav li li > a, .state1 #top-nav li > a .dropdown-menu > li > a, .state1 #top-nav li > a li, .state1 #top-nav li > a li > a {
    line-height: 20px
  }
}

@media (max-width: 767px) and (max-width: 767px) {
  .state1 #top-nav li, .state1 #top-nav li > a {
    line-height: 30px
  }
}

.state1.ext-nav-show #top-nav .dropdown-menu > li > a, .state1.ext-nav-show #top-nav .navbar-brand, .state1.ext-nav-show #top-nav .navbar-header, .state1.ext-nav-show #top-nav li, .state1.ext-nav-show #top-nav li > a, .state2 #top-nav .dropdown-menu > li > a, .state2 #top-nav .navbar-brand, .state2 #top-nav .navbar-header, .state2 #top-nav li, .state2 #top-nav li > a {
  line-height: 28px;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s
}

.android-browser-4_3minus .state1.ext-nav-show #top-nav .dropdown-menu > li > a, .android-browser-4_3minus .state1.ext-nav-show #top-nav .navbar-brand, .android-browser-4_3minus .state1.ext-nav-show #top-nav .navbar-header, .android-browser-4_3minus .state1.ext-nav-show #top-nav li, .android-browser-4_3minus .state1.ext-nav-show #top-nav li > a, .android-browser-4_3minus .state2 #top-nav .dropdown-menu > li > a, .android-browser-4_3minus .state2 #top-nav .navbar-brand, .android-browser-4_3minus .state2 #top-nav .navbar-header, .android-browser-4_3minus .state2 #top-nav li, .android-browser-4_3minus .state2 #top-nav li > a {
  transition: none;
  -webkit-transition: none
}

.state1.ext-nav-show #top-nav .dropdown-menu > li > a .dropdown-menu > li > a, .state1.ext-nav-show #top-nav .dropdown-menu > li > a li, .state1.ext-nav-show #top-nav .dropdown-menu > li > a li > a, .state1.ext-nav-show #top-nav .navbar-brand .dropdown-menu > li > a, .state1.ext-nav-show #top-nav .navbar-brand li, .state1.ext-nav-show #top-nav .navbar-brand li > a, .state1.ext-nav-show #top-nav .navbar-header .dropdown-menu > li > a, .state1.ext-nav-show #top-nav .navbar-header li, .state1.ext-nav-show #top-nav .navbar-header li > a, .state1.ext-nav-show #top-nav li .dropdown-menu > li > a, .state1.ext-nav-show #top-nav li li, .state1.ext-nav-show #top-nav li li > a, .state1.ext-nav-show #top-nav li > a .dropdown-menu > li > a, .state1.ext-nav-show #top-nav li > a li, .state1.ext-nav-show #top-nav li > a li > a, .state2 #top-nav .dropdown-menu > li > a .dropdown-menu > li > a, .state2 #top-nav .dropdown-menu > li > a li, .state2 #top-nav .dropdown-menu > li > a li > a, .state2 #top-nav .navbar-brand .dropdown-menu > li > a, .state2 #top-nav .navbar-brand li, .state2 #top-nav .navbar-brand li > a, .state2 #top-nav .navbar-header .dropdown-menu > li > a, .state2 #top-nav .navbar-header li, .state2 #top-nav .navbar-header li > a, .state2 #top-nav li .dropdown-menu > li > a, .state2 #top-nav li li, .state2 #top-nav li li > a, .state2 #top-nav li > a .dropdown-menu > li > a, .state2 #top-nav li > a li, .state2 #top-nav li > a li > a {
  line-height: 20px
}

@media (max-width: 767px) {
  .state1.ext-nav-show #top-nav li, .state1.ext-nav-show #top-nav li > a, .state2 #top-nav li, .state2 #top-nav li > a {
    line-height: 30px
  }
}

@media (max-width: 767px) {
  #top-nav {
    overflow: auto;
    max-height: 100%
  }
  #top-nav ul li a {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px
  }
  #top-nav .nav li li {
    margin-left: 50px
  }
  #top-nav .navbar-brand {
    font-size: 23px
  }
}

#top-nav .menu-toggle {
  margin-left: 15px;
  margin-right: 15px
}

.state1 #top-nav .menu-toggle {
  height: 88px
}

@media (max-width: 767px) {
  .state1 #top-nav .menu-toggle {
    height: 48px
  }
}

.state1.ext-nav-show #top-nav .menu-toggle, .state2 #top-nav .menu-toggle {
  height: 48px
}

.page-border {
  position: fixed;
  z-index: 1030;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s
}

.android-browser-4_3minus .page-border {
  transition: none;
  -webkit-transition: none
}

.page-border.left {
  left: 0;
  top: 0;
  bottom: 24px;
  width: 25px;
  border-right: 1px solid
}

.page-border.left ul {
  position: fixed;
  z-index: 1030;
  width: 25px;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  padding-left: 0;
  list-style: none
}

.page-border.left ul li {
  text-align: center;
  padding: 3px 0
}

.page-border.right {
  right: 0;
  top: 0;
  bottom: 24px;
  width: 25px;
  border-left: 1px solid
}

.page-border.bottom {
  bottom: 0;
  right: 0;
  left: 0;
  height: 25px;
  border-top: 1px solid;
  text-align: center;
  font-weight: 800;
  text-transform: uppercase;
  padding-top: 2px;
  font-size: 12px;
  letter-spacing: 1px
}

.page-border.bottom a {
  line-height: 15px;
  text-decoration: none
}

body {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 48px
}

@media (max-width: 1249px) {
  body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0
  }
  .page-border {
    display: none
  }
  #top-nav {
    margin: 0
  }
}

.ext-nav {
  position: fixed;
  z-index: 1030;
  bottom: 0;
  bottom: 25px;
  left: 25px;
  right: 25px;
  top: 49px;
  opacity: 0;
  visibility: hidden;
  overflow: auto;
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out
}

.ext-nav.show {
  opacity: 1;
  visibility: visible
}

.ext-nav a {
  display: block
}

.ext-nav a div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  max-width: none;
  line-height: 30px
}

.ext-nav a div span {
  display: block
}

.ext-nav a div .side-title {
  font-size: 30px;
  font-weight: 800;
  position: relative
}

.ext-nav a div .side-title::after, .ext-nav a div .side-title::before {
  display: inline-block;
  opacity: 0;
  -webkit-transition: -webkit-transform .3s, opacity .2s;
  -moz-transition: -moz-transform .3s, opacity .2s;
  transition: transform .3s, opacity .2s
}

.ext-nav a div .side-title::before {
  width: 20px;
  text-align: right;
  margin-right: 5px;
  margin-left: -25px;
  content: '[';
  -webkit-transform: translateX(20px);
  -moz-transform: translateX(20px);
  transform: translateX(20px)
}

.ext-nav a div .side-title::after {
  width: 20px;
  text-align: left;
  margin-left: 5px;
  margin-right: -25px;
  content: ']';
  -webkit-transform: translateX(-20px);
  -moz-transform: translateX(-20px);
  transform: translateX(-20px)
}

.ext-nav a:hover div .side-title:after, .ext-nav a:hover div .side-title:before {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  transform: translateX(0px)
}

.android-browser-4_3minus .ext-nav {
  transition: none;
  -webkit-transition: none
}

@media (max-width: 1249px) {
  .ext-nav {
    bottom: 0;
    left: 0;
    right: 0
  }
}

@media (max-width: 991px) {
  .ext-nav .full-height, .ext-nav .half-height {
    height: auto
  }
  .ext-nav a {
    height: 250px !important
  }
}

.menu-toggle {
  display: block;
  background: 0 0;
  width: 22px;
  height: 20px;
  position: relative;
  -webkit-transition: all .6s;
  -moz-transition: all .6s;
  -ms-transition: all .6s;
  -o-transition: all .6s;
  transition: all .6s;
  text-decoration: none
}

.android-browser-4_3minus .menu-toggle {
  transition: none;
  -webkit-transition: none
}

.menu-toggle span {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  width: 22px;
  height: 2px;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s
}

.android-browser-4_3minus .menu-toggle span {
  transition: none;
  -webkit-transition: none
}

.menu-toggle span:after, .menu-toggle span:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  right: 0;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  backface-visibility: hidden;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s
}

.android-browser-4_3minus .menu-toggle span:after, .android-browser-4_3minus .menu-toggle span:before {
  transition: none;
  -webkit-transition: none
}

.menu-toggle span:after {
  transform: translateY(6px) rotate(0deg);
  -webkit-transform: translateY(6px) rotate(0deg);
  -moz-transform: translateY(6px) rotate(0deg);
  -ms-transform: translateY(6px) rotate(0deg);
  -o-transform: translateY(6px) rotate(0deg)
}

.menu-toggle span:before {
  transform: translateY(-6px) rotate(0deg);
  -webkit-transform: translateY(-6px) rotate(0deg);
  -moz-transform: translateY(-6px) rotate(0deg);
  -ms-transform: translateY(-6px) rotate(0deg);
  -o-transform: translateY(-6px) rotate(0deg)
}

.menu-toggle:hover span:after {
  transform: translateY(8px) rotate(0deg);
  -webkit-transform: translateY(8px) rotate(0deg);
  -moz-transform: translateY(8px) rotate(0deg);
  -ms-transform: translateY(8px) rotate(0deg);
  -o-transform: translateY(8px) rotate(0deg)
}

.menu-toggle:hover span:before {
  transform: translateY(-8px) rotate(0deg);
  -webkit-transform: translateY(-8px) rotate(0deg);
  -moz-transform: translateY(-8px) rotate(0deg);
  -ms-transform: translateY(-8px) rotate(0deg);
  -o-transform: translateY(-8px) rotate(0deg)
}

.menu-toggle.show span:after {
  transform: translateY(0px) rotate(45deg);
  -webkit-transform: translateY(0px) rotate(45deg);
  -moz-transform: translateY(0px) rotate(45deg);
  -ms-transform: translateY(0px) rotate(45deg);
  -o-transform: translateY(0px) rotate(45deg)
}

.menu-toggle.show span:before {
  transform: translateY(0px) rotate(-45deg);
  -webkit-transform: translateY(0px) rotate(-45deg);
  -moz-transform: translateY(0px) rotate(-45deg);
  -ms-transform: translateY(0px) rotate(-45deg);
  -o-transform: translateY(0px) rotate(-45deg)
}

.menu-toggle.navbar-toggle {
  border: none;
  margin: 0
}

.menu-toggle.ext-nav-toggle {
  float: right
}

@media (min-width: 768px) {
  .menu-toggle.navbar-toggle {
    display: none
  }
}

.sidebar .title {
  font-size: 16px;
  text-transform: uppercase;
  text-align: left
}

.sidebar ul.tags-list {
  list-style: none;
  padding: 0
}

.sidebar ul.tags-list li {
  border: 1px solid;
  font-size: 11px;
  margin-top: 5px;
  display: inline-block;
  padding: 3px 9px
}

.navbar-brand img {
  display: inline
}

.gallery {
  position: relative;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s
}

.android-browser-4_3minus .gallery {
  transition: none;
  -webkit-transition: none
}

.gallery .filter {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 5px
}

.gallery .filter .button {
  margin-bottom: 5px;
  font-weight: 600
}

.gallery .filter .nav li {
  display: inline-block;
  float: none
}

.gallery .filter .nav li a {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 800
}

.gallery .grid {
  overflow: hidden;
  margin: 0
}

.gallery .grid .item {
  padding: 0;
  cursor: pointer
}

.gallery .grid .item > a {
  display: block;
  width: 100%;
  height: 100%
}

.gallery .grid .item > a img {
  width: 100%;
  height: auto
}

.gallery .hover-overlay .overlay > div {
  padding: 0 25%
}

.gallery-overlay .field {
  text-transform: uppercase;
  font-weight: 600
}

.page-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  opacity: 0;
  z-index: 1030;
  visibility: hidden
}

.android-browser-4_3minus .page-overlay {
  transition: none;
  -webkit-transition: none
}

.page-overlay.show {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  visibility: visible
}

.overlay-window {
  visibility: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1030;
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  opacity: 0
}

.android-browser-4_3minus .overlay-window {
  transition: none;
  -webkit-transition: none
}

.overlay-window.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0%);
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%)
}

.overlay-window .loaded-content {
  opacity: 0;
  -webkit-transition: opacity .5s;
  -moz-transition: opacity .5s;
  -ms-transition: opacity .5s;
  -o-transition: opacity .5s;
  transition: opacity .5s
}

.android-browser-4_3minus .overlay-window .loaded-content {
  transition: none;
  -webkit-transition: none
}

.overlay-window .loaded-content.show {
  opacity: 1
}

.overlay-window .content-container {
  overflow: auto;
  position: absolute;
  top: 90px;
  bottom: 25px;
  left: 0;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 20px
}

.overlay-window .content-container .field {
  text-transform: uppercase;
  font-weight: 600
}

.overlay-zoom {
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8)
}

.cross {
  display: block;
  float: right;
  position: relative;
  width: 40px;
  height: 40px;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  margin: 25px
}

.android-browser-4_3minus .cross {
  transition: none;
  -webkit-transition: none
}

.cross:after, .cross:before {
  content: '';
  width: 100%;
  position: absolute;
  background-color: #fff;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  backface-visibility: hidden;
  left: 0;
  top: 50%;
  margin-top: -1px;
  bottom: auto;
  right: auto;
  height: 2px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s
}

.android-browser-4_3minus .cross:after, .android-browser-4_3minus .cross:before {
  transition: none;
  -webkit-transition: none
}

.cross:after {
  transform: translateY(0px) rotate(45deg);
  -webkit-transform: translateY(0px) rotate(45deg);
  -moz-transform: translateY(0px) rotate(45deg);
  -ms-transform: translateY(0px) rotate(45deg);
  -o-transform: translateY(0px) rotate(45deg)
}

.cross:before {
  transform: translateY(0px) rotate(-45deg);
  -webkit-transform: translateY(0px) rotate(-45deg);
  -moz-transform: translateY(0px) rotate(-45deg);
  -ms-transform: translateY(0px) rotate(-45deg);
  -o-transform: translateY(0px) rotate(-45deg)
}

.next {
  display: block;
  float: left;
  position: relative;
  width: 20px;
  height: 40px;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  margin: 25px
}

.android-browser-4_3minus .next {
  transition: none;
  -webkit-transition: none
}

.next:after, .next:before {
  content: '';
  width: 100%;
  position: absolute;
  background-color: #fff;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  backface-visibility: hidden;
  left: 0;
  bottom: auto;
  right: auto;
  height: 2px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s
}

.android-browser-4_3minus .next:after, .android-browser-4_3minus .next:before {
  transition: none;
  -webkit-transition: none
}

.next:before {
  top: 12px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg)
}

.next:after {
  top: 26px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg)
}

.previos {
  display: block;
  float: left;
  position: relative;
  width: 20px;
  height: 40px;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  margin: 25px
}

.android-browser-4_3minus .previos {
  transition: none;
  -webkit-transition: none
}

.previos:after, .previos:before {
  content: '';
  width: 100%;
  position: absolute;
  background-color: #fff;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  backface-visibility: hidden;
  left: 0;
  bottom: auto;
  right: auto;
  height: 2px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s
}

.android-browser-4_3minus .previos:after, .android-browser-4_3minus .previos:before {
  transition: none;
  -webkit-transition: none
}

.previos:before {
  top: 12px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg)
}

.previos:after {
  top: 26px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg)
}

.overlay-control {
  height: 90px
}

.carousel, .slider {
  cursor: grab !important;
  cursor: -moz-grab !important;
  cursor: -webkit-grab !important
}

.carousel > div img, .slider > div img {
  width: 100%;
  height: auto
}

.carousel .slick-next, .carousel .slick-prev, .slider .slick-next, .slider .slick-prev {
  top: 50%;
  margin: -35px 0 0;
  padding: 20px 5px;
  width: 21px;
  height: 70px;
  overflow: hidden;
  font-size: 0
}

.carousel .slick-next:before, .carousel .slick-prev:before, .slider .slick-next:before, .slider .slick-prev:before {
  font-size: 30px;
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.carousel .slick-prev, .slider .slick-prev {
  left: 0;
  -moz-border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0
}

.carousel .slick-prev:before, .slider .slick-prev:before {
  content: "\f104"
}

.carousel .slick-next, .slider .slick-next {
  right: 0;
  -moz-border-radius: 5px 0 0 5px;
  -webkit-border-radius: 5px 0 0 5px;
  border-radius: 5px 0 0 5px
}

.carousel .slick-next:before, .slider .slick-next:before {
  content: "\f105"
}

.carousel .title, .slider .title {
  font-size: 22px;
  margin-top: 4px
}

.carousel .subtitle, .slider .subtitle {
  font-size: 14px !important;
  line-height: 20px !important;
  padding-bottom: 10px
}

.carousel {
  margin-top: 33px !important;
  margin-bottom: 0 !important
}

.carousel .slick-dots {
  padding-bottom: 5px;
  bottom: 100%;
  top: auto
}

.carousel .slick-dots button:before {
  font-size: 14px
}

.slider {
  margin-top: 0 !important;
  margin-bottom: 38px !important
}

.slider .slick-dots {
  padding-top: 5px;
  bottom: auto;
  top: 100%
}

.slider .slick-dots button:before {
  font-size: 14px
}

.counters {
  margin-top: 40px;
  margin-bottom: 20px
}

.counters .counter {
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 28px;
  padding-top: 60px;
  padding-bottom: 60px
}

.counters .counter .count {
  font-size: 45px;
  line-height: 78px;
  font-weight: 600
}

.scroll-animation, .scroll-in-animation {
  -webkit-animation-duration: 1.2s;
  -moz-animation-duration: 1.2s;
  -o-animation-duration: 1.2s;
  animation-duration: 1.2s
}

.android-browser-4_3minus .scroll-animation, .android-browser-4_3minus .scroll-in-animation {
  animation-duration: 0s;
  -webkit-animation-duration: 0s
}

.scroll-animation.flipInX, .scroll-animation.flipInY, .scroll-in-animation.flipInX, .scroll-in-animation.flipInY {
  -webkit-animation-duration: 2.6s;
  -moz-animation-duration: 2.6s;
  -o-animation-duration: 2.6s;
  animation-duration: 2.6s
}

.android-browser-4_3minus .scroll-animation.flipInX, .android-browser-4_3minus .scroll-animation.flipInY, .android-browser-4_3minus .scroll-in-animation.flipInX, .android-browser-4_3minus .scroll-in-animation.flipInY {
  animation-duration: 0s;
  -webkit-animation-duration: 0s
}

.scroll-animation.zoomInLeft, .scroll-animation.zoomInRight, .scroll-in-animation.zoomInLeft, .scroll-in-animation.zoomInRight {
  -webkit-animation-duration: 1.8s;
  -moz-animation-duration: 1.8s;
  -o-animation-duration: 1.8s;
  animation-duration: 1.8s
}

.android-browser-4_3minus .scroll-animation.zoomInLeft, .android-browser-4_3minus .scroll-animation.zoomInRight, .android-browser-4_3minus .scroll-in-animation.zoomInLeft, .android-browser-4_3minus .scroll-in-animation.zoomInRight {
  animation-duration: 0s;
  -webkit-animation-duration: 0s
}

.scroll-animation.fadeInLeft, .scroll-animation.fadeInRight, .scroll-in-animation.fadeInLeft, .scroll-in-animation.fadeInRight {
  -webkit-animation-duration: .8s;
  -moz-animation-duration: .8s;
  -o-animation-duration: .8s;
  animation-duration: .8s
}

.android-browser-4_3minus .scroll-animation.fadeInLeft, .android-browser-4_3minus .scroll-animation.fadeInRight, .android-browser-4_3minus .scroll-in-animation.fadeInLeft, .android-browser-4_3minus .scroll-in-animation.fadeInRight {
  animation-duration: 0s;
  -webkit-animation-duration: 0s
}

.scroll-in-animation {
  opacity: 0
}

.scroll-in-animation.css-animation-show {
  opacity: 1
}

@-webkit-keyframes zoomInSmall {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.9, .9, .9);
    transform: scale3d(0.9, .9, .9)
  }
  50% {
    opacity: 1
  }
}

@keyframes zoomInSmall {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.9, .9, .9);
    -ms-transform: scale3d(0.9, .9, .9);
    transform: scale3d(0.9, .9, .9)
  }
  50% {
    opacity: 1
  }
}

.zoomInSmall {
  -webkit-animation-name: zoomInSmall;
  animation-name: zoomInSmall
}

#dot-scroll {
  list-style: none;
  position: fixed;
  z-index: 1030;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  width: 5px;
  margin: 0;
  padding: 0;
  -webkit-transition: background-color .6s;
  -moz-transition: background-color .6s;
  -ms-transition: background-color .6s;
  -o-transition: background-color .6s;
  transition: background-color .6s
}

.android-browser-4_3minus #dot-scroll {
  transition: none;
  -webkit-transition: none
}

#dot-scroll li {
  display: block;
  padding: 8px 0;
  width: 5px;
  height: 21px
}

#dot-scroll li a {
  display: block;
  width: 100%;
  height: 100%
}

#dot-scroll li a span {
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  -webkit-transition: background-color .6s;
  -moz-transition: background-color .6s;
  -ms-transition: background-color .6s;
  -o-transition: background-color .6s;
  transition: background-color .6s
}

.android-browser-4_3minus #dot-scroll li a span {
  transition: none;
  -webkit-transition: none
}

#dot-scroll li a.active span {
  border: 1px solid;
  width: 10px;
  height: 10px;
  margin-top: -2.5px;
  margin-left: -2.5px
}

#dot-scroll li a.target span {
  border: 0 solid;
  width: 8px;
  height: 8px;
  margin-top: -1.5px;
  margin-left: -1.5px
}

@media (max-width: 1249px) {
  #dot-scroll {
    display: none
  }
}

.fluid {
  width: 100%;
  position: relative
}

.fluid > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto
}

.fluid-width {
  width: 100%;
  height: auto
}

.fluid-height {
  width: auto;
  height: 100%
}

.gate {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1000;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s
}

.android-browser-4_3minus .gate {
  transition: none;
  -webkit-transition: none
}

.gate .gate-bar {
  position: absolute;
  top: 88px;
  height: 5px;
  width: 0;
  float: left
}

@media (max-width: 767px) {
  .gate .gate-bar {
    top: 48px
  }
}

.gate .gate-count {
  font-size: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: right;
  width: 100px;
  margin-left: -50px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  font-weight: 600
}

.gate .gate-water {
  position: absolute;
  width: 100%;
  height: 0;
  bottom: 0
}

.gate .gate-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  -webkit-transition: all 1.5s;
  -moz-transition: all 1.5s;
  -ms-transition: all 1.5s;
  -o-transition: all 1.5s;
  transition: all 1.5s
}

.android-browser-4_3minus .gate .gate-overlay {
  transition: none;
  -webkit-transition: none
}

.gate img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%)
}

.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -30px;
  margin-left: -20px;
  padding: 0;
  width: 60px;
  height: 60px;
  list-style: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s
}

.android-browser-4_3minus .loader {
  transition: none;
  -webkit-transition: none
}

.loader li {
  background-color: #fff;
  width: 10px;
  height: 10px;
  float: left;
  margin-right: 5px;
  box-shadow: 0 80px 20px rgba(0, 0, 0, .2)
}

.loader.show {
  opacity: 1;
  visibility: visible
}

.loader.hided {
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  -ms-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s
}

.android-browser-4_3minus .loader.hided {
  transition: none;
  -webkit-transition: none
}

@-webkit-keyframes loading-animation {
  0% {
    height: 10px;
    margin-top: 25px
  }
  50% {
    height: 50px;
    margin-top: 0
  }
  100% {
    height: 10px;
    margin-top: 25px
  }
}

@-moz-keyframes loading-animation {
  0% {
    height: 10px;
    margin-top: 25px
  }
  50% {
    height: 50px;
    margin-top: 0
  }
  100% {
    height: 10px;
    margin-top: 25px
  }
}

@-ms-keyframes loading-animation {
  0% {
    height: 10px;
    margin-top: 25px
  }
  50% {
    height: 50px;
    margin-top: 0
  }
  100% {
    height: 10px;
    margin-top: 25px
  }
}

@-o-keyframes loading-animation {
  0% {
    height: 10px;
    margin-top: 25px
  }
  50% {
    height: 50px;
    margin-top: 0
  }
  100% {
    height: 10px;
    margin-top: 25px
  }
}

@keyframes loading-animation {
  0% {
    height: 10px;
    margin-top: 25px
  }
  50% {
    height: 50px;
    margin-top: 0
  }
  100% {
    height: 10px;
    margin-top: 25px
  }
}

.loader li:nth-child(1) {
  -webkit-animation: loading-animation .6s ease-in-out infinite 0s;
  -moz-animation: loading-animation .6s ease-in-out infinite 0s;
  -ms-animation: loading-animation .6s ease-in-out infinite 0s;
  animation: loading-animation .6s ease-in-out infinite 0s
}

.android-browser-4_3minus .loader li:nth-child(1) {
  animation: none;
  -webkit-animation: none
}

.loader li:nth-child(2) {
  -webkit-animation: loading-animation .6s ease-in-out infinite -.2s;
  -moz-animation: loading-animation .6s ease-in-out infinite -.2s;
  -ms-animation: loading-animation .6s ease-in-out infinite -.2s;
  animation: loading-animation .6s ease-in-out infinite -.2s
}

.android-browser-4_3minus .loader li:nth-child(2) {
  animation: none;
  -webkit-animation: none
}

.loader li:nth-child(3) {
  -webkit-animation: loading-animation .6s ease-in-out infinite -.4s;
  -moz-animation: loading-animation .6s ease-in-out infinite -.4s;
  -ms-animation: loading-animation .6s ease-in-out infinite -.4s;
  animation: loading-animation .6s ease-in-out infinite -.4s
}

.android-browser-4_3minus .loader li:nth-child(3) {
  animation: none;
  -webkit-animation: none
}

.map-open {
  font-size: 13px
}

.map-canvas {
  height: 100%
}

.map-view {
  padding: 0 25px 25px
}

.show-list {
  position: relative;
  pointer-events: none;
  text-align: center;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden
}

.show-list .show-item {
  text-align: center;
  position: absolute;
  width: 100%;
  opacity: 0;
  padding: 0 5px;
  pointer-events: none;
  white-space: nowrap
}

@-webkit-keyframes show-animation {
  0% {
    opacity: 0;
    transform: scale(0.67, .67) translateX(-150px);
    -webkit-transform: scale(0.67, .67) translateX(-150px);
    -moz-transform: scale(0.67, .67) translateX(-150px);
    -ms-transform: scale(0.67, .67) translateX(-150px);
    -o-transform: scale(0.67, .67) translateX(-150px)
  }
  30% {
    opacity: .5;
    transform: scale(0.83, .83) translateX(0px);
    -webkit-transform: scale(0.83, .83) translateX(0px);
    -moz-transform: scale(0.83, .83) translateX(0px);
    -ms-transform: scale(0.83, .83) translateX(0px);
    -o-transform: scale(0.83, .83) translateX(0px)
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1)
  }
}

@-moz-keyframes show-animation {
  0% {
    opacity: 0;
    transform: scale(0.67, .67) translateX(-150px);
    -webkit-transform: scale(0.67, .67) translateX(-150px);
    -moz-transform: scale(0.67, .67) translateX(-150px);
    -ms-transform: scale(0.67, .67) translateX(-150px);
    -o-transform: scale(0.67, .67) translateX(-150px)
  }
  30% {
    opacity: .5;
    transform: scale(0.83, .83) translateX(0px);
    -webkit-transform: scale(0.83, .83) translateX(0px);
    -moz-transform: scale(0.83, .83) translateX(0px);
    -ms-transform: scale(0.83, .83) translateX(0px);
    -o-transform: scale(0.83, .83) translateX(0px)
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1)
  }
}

@-ms-keyframes show-animation {
  0% {
    opacity: 0;
    transform: scale(0.67, .67) translateX(-150px);
    -webkit-transform: scale(0.67, .67) translateX(-150px);
    -moz-transform: scale(0.67, .67) translateX(-150px);
    -ms-transform: scale(0.67, .67) translateX(-150px);
    -o-transform: scale(0.67, .67) translateX(-150px)
  }
  30% {
    opacity: .5;
    transform: scale(0.83, .83) translateX(0px);
    -webkit-transform: scale(0.83, .83) translateX(0px);
    -moz-transform: scale(0.83, .83) translateX(0px);
    -ms-transform: scale(0.83, .83) translateX(0px);
    -o-transform: scale(0.83, .83) translateX(0px)
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1)
  }
}

@-o-keyframes show-animation {
  0% {
    opacity: 0;
    transform: scale(0.67, .67) translateX(-150px);
    -webkit-transform: scale(0.67, .67) translateX(-150px);
    -moz-transform: scale(0.67, .67) translateX(-150px);
    -ms-transform: scale(0.67, .67) translateX(-150px);
    -o-transform: scale(0.67, .67) translateX(-150px)
  }
  30% {
    opacity: .5;
    transform: scale(0.83, .83) translateX(0px);
    -webkit-transform: scale(0.83, .83) translateX(0px);
    -moz-transform: scale(0.83, .83) translateX(0px);
    -ms-transform: scale(0.83, .83) translateX(0px);
    -o-transform: scale(0.83, .83) translateX(0px)
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1)
  }
}

@keyframes show-animation {
  0% {
    opacity: 0;
    transform: scale(0.67, .67) translateX(-150px);
    -webkit-transform: scale(0.67, .67) translateX(-150px);
    -moz-transform: scale(0.67, .67) translateX(-150px);
    -ms-transform: scale(0.67, .67) translateX(-150px);
    -o-transform: scale(0.67, .67) translateX(-150px)
  }
  30% {
    opacity: .5;
    transform: scale(0.83, .83) translateX(0px);
    -webkit-transform: scale(0.83, .83) translateX(0px);
    -moz-transform: scale(0.83, .83) translateX(0px);
    -ms-transform: scale(0.83, .83) translateX(0px);
    -o-transform: scale(0.83, .83) translateX(0px)
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1)
  }
}

.show-animation {
  -webkit-animation-name: show-animation;
  -moz-animation-name: show-animation;
  -ms-animation-name: show-animation;
  -o-animation-name: show-animation;
  animation-name: show-animation;
  -webkit-animation-duration: 6s;
  -moz-animation-duration: 6s;
  -ms-animation-duration: 6s;
  -o-animation-duration: 6s;
  animation-duration: 6s;
  -webkit-animation-timing-function: ease-out;
  -moz-animation-timing-function: ease-out;
  -ms-animation-timing-function: ease-out;
  -o-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both
}

.android-browser-4_3minus .show-animation {
  animation: none;
  -webkit-animation: none
}

@-webkit-keyframes hide-animation {
  0% {
    opacity: 1;
    transform: rotate(0deg) translateY(0px);
    -webkit-transform: rotate(0deg) translateY(0px);
    -moz-transform: rotate(0deg) translateY(0px);
    -ms-transform: rotate(0deg) translateY(0px);
    -o-transform: rotate(0deg) translateY(0px)
  }
  100% {
    opacity: 0;
    transform: rotate(20deg) translateY(100px);
    -webkit-transform: rotate(20deg) translateY(100px);
    -moz-transform: rotate(20deg) translateY(100px);
    -ms-transform: rotate(20deg) translateY(100px);
    -o-transform: rotate(20deg) translateY(100px)
  }
}

@-moz-keyframes hide-animation {
  0% {
    opacity: 1;
    transform: rotate(0deg) translateY(0px);
    -webkit-transform: rotate(0deg) translateY(0px);
    -moz-transform: rotate(0deg) translateY(0px);
    -ms-transform: rotate(0deg) translateY(0px);
    -o-transform: rotate(0deg) translateY(0px)
  }
  100% {
    opacity: 0;
    transform: rotate(20deg) translateY(100px);
    -webkit-transform: rotate(20deg) translateY(100px);
    -moz-transform: rotate(20deg) translateY(100px);
    -ms-transform: rotate(20deg) translateY(100px);
    -o-transform: rotate(20deg) translateY(100px)
  }
}

@-ms-keyframes hide-animation {
  0% {
    opacity: 1;
    transform: rotate(0deg) translateY(0px);
    -webkit-transform: rotate(0deg) translateY(0px);
    -moz-transform: rotate(0deg) translateY(0px);
    -ms-transform: rotate(0deg) translateY(0px);
    -o-transform: rotate(0deg) translateY(0px)
  }
  100% {
    opacity: 0;
    transform: rotate(20deg) translateY(100px);
    -webkit-transform: rotate(20deg) translateY(100px);
    -moz-transform: rotate(20deg) translateY(100px);
    -ms-transform: rotate(20deg) translateY(100px);
    -o-transform: rotate(20deg) translateY(100px)
  }
}

@-o-keyframes hide-animation {
  0% {
    opacity: 1;
    transform: rotate(0deg) translateY(0px);
    -webkit-transform: rotate(0deg) translateY(0px);
    -moz-transform: rotate(0deg) translateY(0px);
    -ms-transform: rotate(0deg) translateY(0px);
    -o-transform: rotate(0deg) translateY(0px)
  }
  100% {
    opacity: 0;
    transform: rotate(20deg) translateY(100px);
    -webkit-transform: rotate(20deg) translateY(100px);
    -moz-transform: rotate(20deg) translateY(100px);
    -ms-transform: rotate(20deg) translateY(100px);
    -o-transform: rotate(20deg) translateY(100px)
  }
}

@keyframes hide-animation {
  0% {
    opacity: 1;
    transform: rotate(0deg) translateY(0px);
    -webkit-transform: rotate(0deg) translateY(0px);
    -moz-transform: rotate(0deg) translateY(0px);
    -ms-transform: rotate(0deg) translateY(0px);
    -o-transform: rotate(0deg) translateY(0px)
  }
  100% {
    opacity: 0;
    transform: rotate(20deg) translateY(100px);
    -webkit-transform: rotate(20deg) translateY(100px);
    -moz-transform: rotate(20deg) translateY(100px);
    -ms-transform: rotate(20deg) translateY(100px);
    -o-transform: rotate(20deg) translateY(100px)
  }
}

.hide-animation {
  -webkit-animation-name: hide-animation;
  -moz-animation-name: hide-animation;
  -ms-animation-name: hide-animation;
  -o-animation-name: hide-animation;
  animation-name: hide-animation;
  -webkit-animation-duration: 2.5s;
  -moz-animation-duration: 2.5s;
  -ms-animation-duration: 2.5s;
  -o-animation-duration: 2.5s;
  animation-duration: 2.5s;
  -webkit-animation-timing-function: ease-in;
  -moz-animation-timing-function: ease-in;
  -ms-animation-timing-function: ease-in;
  -o-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both
}

.android-browser-4_3minus .hide-animation {
  animation: none;
  -webkit-animation: none
}

@-webkit-keyframes show-animation-2 {
  0% {
    opacity: 0;
    transform: translateY(-200px);
    -webkit-transform: translateY(-200px);
    -moz-transform: translateY(-200px);
    -ms-transform: translateY(-200px);
    -o-transform: translateY(-200px)
  }
  50% {
    opacity: .7;
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px)
  }
  100% {
    opacity: 1
  }
}

@-moz-keyframes show-animation-2 {
  0% {
    opacity: 0;
    transform: translateY(-200px);
    -webkit-transform: translateY(-200px);
    -moz-transform: translateY(-200px);
    -ms-transform: translateY(-200px);
    -o-transform: translateY(-200px)
  }
  50% {
    opacity: .7;
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px)
  }
  100% {
    opacity: 1
  }
}

@-ms-keyframes show-animation-2 {
  0% {
    opacity: 0;
    transform: translateY(-200px);
    -webkit-transform: translateY(-200px);
    -moz-transform: translateY(-200px);
    -ms-transform: translateY(-200px);
    -o-transform: translateY(-200px)
  }
  50% {
    opacity: .7;
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px)
  }
  100% {
    opacity: 1
  }
}

@-o-keyframes show-animation-2 {
  0% {
    opacity: 0;
    transform: translateY(-200px);
    -webkit-transform: translateY(-200px);
    -moz-transform: translateY(-200px);
    -ms-transform: translateY(-200px);
    -o-transform: translateY(-200px)
  }
  50% {
    opacity: .7;
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px)
  }
  100% {
    opacity: 1
  }
}

@keyframes show-animation-2 {
  0% {
    opacity: 0;
    transform: translateY(-200px);
    -webkit-transform: translateY(-200px);
    -moz-transform: translateY(-200px);
    -ms-transform: translateY(-200px);
    -o-transform: translateY(-200px)
  }
  50% {
    opacity: .7;
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px)
  }
  100% {
    opacity: 1
  }
}

.mode-2 .show-animation {
  -webkit-animation-name: show-animation-2;
  -moz-animation-name: show-animation-2;
  -ms-animation-name: show-animation-2;
  -o-animation-name: show-animation-2;
  animation-name: show-animation-2;
  -webkit-animation-duration: 5s;
  -moz-animation-duration: 5s;
  -ms-animation-duration: 5s;
  -o-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-timing-function: ease-out;
  -moz-animation-timing-function: ease-out;
  -ms-animation-timing-function: ease-out;
  -o-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both
}

.android-browser-4_3minus .mode-2 .show-animation {
  animation: none;
  -webkit-animation: none
}

@-webkit-keyframes hide-animation-2 {
  0% {
    opacity: 1;
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px)
  }
  100% {
    opacity: 0;
    transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -moz-transform: translateY(200px);
    -ms-transform: translateY(200px);
    -o-transform: translateY(200px)
  }
}

@-moz-keyframes hide-animation-2 {
  0% {
    opacity: 1;
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px)
  }
  100% {
    opacity: 0;
    transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -moz-transform: translateY(200px);
    -ms-transform: translateY(200px);
    -o-transform: translateY(200px)
  }
}

@-ms-keyframes hide-animation-2 {
  0% {
    opacity: 1;
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px)
  }
  100% {
    opacity: 0;
    transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -moz-transform: translateY(200px);
    -ms-transform: translateY(200px);
    -o-transform: translateY(200px)
  }
}

@-o-keyframes hide-animation-2 {
  0% {
    opacity: 1;
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px)
  }
  100% {
    opacity: 0;
    transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -moz-transform: translateY(200px);
    -ms-transform: translateY(200px);
    -o-transform: translateY(200px)
  }
}

@keyframes hide-animation-2 {
  0% {
    opacity: 1;
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px)
  }
  100% {
    opacity: 0;
    transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -moz-transform: translateY(200px);
    -ms-transform: translateY(200px);
    -o-transform: translateY(200px)
  }
}

.mode-2 .hide-animation {
  -webkit-animation-name: hide-animation-2;
  -moz-animation-name: hide-animation-2;
  -ms-animation-name: hide-animation-2;
  -o-animation-name: hide-animation-2;
  animation-name: hide-animation-2;
  -webkit-animation-duration: 3s;
  -moz-animation-duration: 3s;
  -ms-animation-duration: 3s;
  -o-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: ease-in;
  -moz-animation-timing-function: ease-in;
  -ms-animation-timing-function: ease-in;
  -o-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both
}

.android-browser-4_3minus .mode-2 .hide-animation {
  animation: none;
  -webkit-animation: none
}

.textillate .texts {
  display: none
}

.icon-set {
  margin-top: 40px
}

.icon-set .title {
  margin-top: 28px;
  margin-bottom: 10px
}

.circle {
  display: inline-block;
  text-align: center;
  width: 145px;
  height: 145px;
  line-height: 145px;
  margin: 3px auto;
  border-radius: 50%;
  font-size: 32px;
  position: relative;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  text-decoration: none;
  outline: 0
}

.circle:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  left: 0;
  top: 0;
  border: 1px solid;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  -webkit-transform: scale(1.04137931);
  -moz-transform: scale(1.04137931);
  -ms-transform: scale(1.04137931);
  transform: scale(1.04137931)
}

.circle:hover {
  border: none
}

.circle:hover:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  border: 1px solid
}

.circle.big {
  display: inline-block;
  text-align: center;
  width: 170px;
  height: 170px;
  line-height: 170px;
  margin: 3px auto;
  border-radius: 50%;
  font-size: 40px;
  position: relative;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  text-decoration: none;
  outline: 0
}

.circle.big:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  left: 0;
  top: 0;
  border: 1px solid;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  -webkit-transform: scale(1.03529412);
  -moz-transform: scale(1.03529412);
  -ms-transform: scale(1.03529412);
  transform: scale(1.03529412)
}

.circle.big:hover {
  border: none
}

.circle.big:hover:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  border: 1px solid
}

.circle.small {
  display: inline-block;
  text-align: center;
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin: 3px auto;
  border-radius: 50%;
  font-size: 20px;
  position: relative;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  text-decoration: none;
  outline: 0
}

.circle.small:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  left: 0;
  top: 0;
  border: 1px solid;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1)
}

.circle.small:hover {
  border: none
}

.circle.small:hover:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  border: 1px solid
}

.circle-flat {
  display: inline-block;
  text-align: center;
  width: 200px;
  height: 200px;
  line-height: 200px;
  margin: 0 auto;
  border-radius: 50%;
  font-size: 130px;
  position: relative;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  text-decoration: none;
  outline: 0
}

.skillbars .skillbar {
  position: relative;
  display: block;
  margin-bottom: 15px;
  width: 100%;
  height: 22px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -webkit-transition: .4s linear;
  -moz-transition: .4s linear;
  -ms-transition: .4s linear;
  -o-transition: .4s linear;
  transition: .4s linear;
  -webkit-transition-property: width, background-color;
  -moz-transition-property: width, background-color;
  -ms-transition-property: width, background-color;
  -o-transition-property: width, background-color;
  transition-property: width, background-color
}

.skillbars .skillbar-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 110px;
  font-weight: 700;
  font-size: 13px;
  -webkit-border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px
}

.skillbars .skillbar-title span {
  display: block;
  padding: 0 20px;
  height: 22px;
  line-height: 22px;
  -webkit-border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px
}

.skillbars .skillbar-bar {
  height: 22px;
  width: 0;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px
}

.skillbars .skill-bar-percent {
  position: absolute;
  right: 10px;
  top: 0;
  font-size: 11px;
  height: 22px;
  line-height: 22px;
  font-weight: 700
}

body > iframe.vimeo-bg, body > iframe.youtube-bg, body > video.video-bg {
  position: fixed;
  pointer-events: none
}

html.mobile .vimeo-bg, html.mobile .youtube-bg, html.mobile video.bg {
  display: none
}

.process {
  position: relative;
  line-height: 36px
}

@media (min-width: 1200px) {
  .process .process-row:before {
    top: 75px;
    left: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    border-top: 1px dashed;
    z-index: 0
  }
}

.process .process-row .process-step {
  text-align: center;
  position: relative;
  padding-bottom: 20px
}

.process .process-row .process-step p {
  margin-top: 10px
}

.process .process-row .process-step .title {
  margin-top: 15px;
  margin-bottom: 15px
}

.process .process-row .process-step .process-box {
  position: relative;
  width: 150px;
  height: 150px;
  text-align: center;
  padding: 0;
  font-size: 36px;
  line-height: 150px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  border: 1px solid;
  overflow: hidden;
  margin: 0 auto
}

.process .process-row .process-step .process-box:before {
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: ' ';
  z-index: 0
}

.process .process-row .process-step .process-box i {
  position: relative;
  z-index: 10;
  font-size: 0
}

.process .process-row .process-step .process-box i:before {
  font-size: 36px
}

.box {
  padding: 20px !important
}

.hover-effect {
  position: relative
}

.hover-effect::after, .hover-effect::before {
  display: inline-block;
  opacity: 0;
  -webkit-transition: -webkit-transform .3s, opacity .2s;
  -moz-transition: -moz-transform .3s, opacity .2s;
  transition: transform .3s, opacity .2s
}

.hover-effect::before {
  width: 20px;
  text-align: right;
  margin-right: 5px;
  margin-left: -25px;
  content: '[';
  -webkit-transform: translateX(20px);
  -moz-transform: translateX(20px);
  transform: translateX(20px)
}

.hover-effect::after {
  width: 20px;
  text-align: left;
  margin-left: 5px;
  margin-right: -25px;
  content: ']';
  -webkit-transform: translateX(-20px);
  -moz-transform: translateX(-20px);
  transform: translateX(-20px)
}

.hover-effect:focus:after, .hover-effect:focus:before, .hover-effect:not(.active):hover:after, .hover-effect:not(.active):hover:before {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  transform: translateX(0px)
}

.non-mobile .button {
  position: relative;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s
}

.android-browser-4_3minus .non-mobile .button {
  transition: none;
  -webkit-transition: none
}

.non-mobile .button:before {
  content: '';
  position: absolute;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  width: 100%;
  height: 0;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%)
}

.android-browser-4_3minus .non-mobile .button:before {
  transition: none;
  -webkit-transition: none
}

.non-mobile .button:hover:before {
  height: 75%;
  opacity: 1
}

.non-mobile .button:active:before {
  height: 130%;
  opacity: 1
}

.hover-overlay {
  position: relative;
  overflow: hidden
}

.hover-overlay img {
  -webkit-transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  -ms-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s
}

.android-browser-4_3minus .hover-overlay img {
  transition: none;
  -webkit-transition: none
}

.hover-overlay:hover img {
  transform: scale(1.55, 1.55) rotate(-20deg);
  -webkit-transform: scale(1.55, 1.55) rotate(-20deg);
  -moz-transform: scale(1.55, 1.55) rotate(-20deg);
  -ms-transform: scale(1.55, 1.55) rotate(-20deg);
  -o-transform: scale(1.55, 1.55) rotate(-20deg)
}

.ie9 .hover-overlay:hover img, .mobile .hover-overlay:hover img {
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none
}

.hover-overlay:hover .overlay > div {
  transform: translateY(-50%) scale(1, 1);
  -webkit-transform: translateY(-50%) scale(1, 1);
  -moz-transform: translateY(-50%) scale(1, 1);
  -ms-transform: translateY(-50%) scale(1, 1);
  -o-transform: translateY(-50%) scale(1, 1)
}

.ie9 .hover-overlay:hover .overlay > div, .mobile .hover-overlay:hover .overlay > div {
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%)
}

.hover-overlay .overlay {
  -webkit-transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  -ms-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0
}

.android-browser-4_3minus .hover-overlay .overlay {
  transition: none;
  -webkit-transition: none
}

.hover-overlay .overlay:hover {
  opacity: 1
}

.hover-overlay .overlay > div {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 0 25px;
  -webkit-transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  -ms-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
  transform: translateY(-50%) scale(0.5, .5);
  -webkit-transform: translateY(-50%) scale(0.5, .5);
  -moz-transform: translateY(-50%) scale(0.5, .5);
  -ms-transform: translateY(-50%) scale(0.5, .5);
  -o-transform: translateY(-50%) scale(0.5, .5)
}

.android-browser-4_3minus .hover-overlay .overlay > div {
  transition: none;
  -webkit-transition: none
}

.ie9 .hover-overlay .overlay > div, .mobile .hover-overlay .overlay > div {
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%)
}

.hover-overlay .overlay > div .title {
  font-size: 17px
}

.hover-overlay .overlay > div i {
  font-size: 32px
}

.hover-overlay .overlay > div a i {
  font-size: 25px
}

@media (max-width: 991px) {
  .hover-overlay .overlay > div, .hover-overlay .overlay > div p {
    font-size: 11px;
    line-height: 16px
  }
  .hover-overlay .overlay > div .title {
    font-size: 12px;
    line-height: 16px
  }
  .hover-overlay .overlay > div a i, .hover-overlay .overlay > div i {
    font-size: 16px
  }
}

@media (max-width: 479px) {
  .hover-overlay .overlay > div, .hover-overlay .overlay > div p {
    font-size: 8px;
    line-height: 10px
  }
  .hover-overlay .overlay > div .title {
    font-size: 10px;
    line-height: 10px
  }
  .hover-overlay .overlay > div a i, .hover-overlay .overlay > div i {
    font-size: 10px
  }
}

.button, .icon-bar, .list-group-item {
  -webkit-transition: color .3s, background-color .3s;
  -moz-transition: color .3s, background-color .3s;
  -ms-transition: color .3s, background-color .3s;
  -o-transition: color .3s, background-color .3s;
  transition: color .3s, background-color .3s
}

.android-browser-4_3minus .button, .android-browser-4_3minus .icon-bar, .android-browser-4_3minus .list-group-item {
  transition: none;
  -webkit-transition: none
}

.line-through {
  text-decoration: line-through
}

.col-left-marginalia {
  float: left;
  margin-right: 10px
}

.col-right-marginalia {
  float: right;
  margin-left: 10px
}

.col-left-marginalia, .col-right-marginalia {
  font-size: 24px;
  -webkit-text-stroke: 1px
}

.col-content {
  overflow: hidden
}

a.button, input.button {
  font-size: 13px
}

.line {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  text-align: center;
  -webkit-transition-duration: .5s;
  -moz-transition-duration: .5s;
  -ms-transition-duration: .5s;
  -o-transition-duration: .5s;
  transition-duration: .5s;
  -webkit-transition-property: margin-top, margin-bottom, padding-top, padding-bottom, background-color;
  -moz-transition-property: margin-top, margin-bottom, padding-top, padding-bottom, background-color;
  -ms-transition-property: margin-top, margin-bottom, padding-top, padding-bottom, background-color;
  -o-transition-property: margin-top, margin-bottom, padding-top, padding-bottom, background-color;
  transition-property: margin-top, margin-bottom, padding-top, padding-bottom, background-color
}

.line:hover {
  margin-top: -10px;
  margin-bottom: -10px;
  padding-top: 20px;
  padding-bottom: 20px
}

.android-browser-4_3minus .line {
  transition-duration: 0s;
  -webkit-transition-duration: 0s;
  transition-property: none;
  -webkit-transition-property: none
}

.line-wrapper {
  margin: 10px 0
}

.position-middle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%)
}

.position-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%)
}

.position-middle-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%)
}

.help-block {
  margin-top: 0
}

.scale-width {
  width: 100%;
  height: auto
}

.scale-height {
  width: auto;
  height: 100%
}

.col-icon {
  font-size: 48px;
  float: left;
  padding: 20px 10px;
  text-align: left
}

.fa.col-icon {
  margin-bottom: 10px;
  margin-top: 30px
}

.col-content {
  padding: 0 15px
}

.border {
  border: 1px solid
}

.border-top {
  border-top: 1px solid
}

.border-right {
  border-right: 1px solid
}

.border-bottom {
  border-bottom: 1px solid
}

.border-left {
  border-left: 1px solid
}

.no-border {
  border: none !important;
  border-top: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-left: none !important;
  border-width: 0 !important
}

.btn, .button, .caption, .header-details, .help-title, .slogan, .subtitle, .title, input.button {
  font-family: Raleway, Arial, Helvetica, sans-serif
}

.separator {
  height: 20px
}

.separator-small {
  height: 10px
}

.separator-large {
  height: 30px
}

.separator-big {
  height: 40px
}

.separator-extra {
  height: 60px
}

.top-space {
  height: 180px
}

.button {
  margin: 2px
}

.caption {
  text-align: center;
  margin-top: 10px
}

.label-border {
  outline: #98abb9 dashed 1px;
  border: 1px solid;
  outline-offset: -7px;
  padding: 10px 20px;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  display: inline-block
}

.btn, a.button, input.button {
  border-style: solid;
  border-width: 1px;
  line-height: 22px;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block
}

.header-details, .title {
  text-align: center;
  text-transform: uppercase;
  margin-top: 0;
  font-weight: 700
}

.header-details.text-left, .title.text-left {
  text-align: left
}

.header-details.text-right, .title.text-right {
  text-align: right
}

@media (max-width: 991px) {
  .header-details, .title {
    font-size: 11px
  }
}

.header-details {
  margin-top: -10px;
  margin-bottom: 30px
}

.subtitle {
  text-align: left;
  text-transform: uppercase;
  margin-top: 0;
  font-weight: 700
}

.underline, .underline-dashed, .underline-dotted, .underline-heading, .underline-highlight, .underline-link, .underline-text {
  display: inline-block;
  border-bottom-width: 3px;
  padding-bottom: 7px
}

.underline, .underline-heading, .underline-highlight, .underline-link, .underline-text {
  border-bottom-style: solid
}

.underline-dashed {
  border-bottom-style: dashed
}

.underline-dotted {
  border-bottom-style: dotted
}

.bottom-line:after, .top-line:before {
  width: 100%;
  height: 2px;
  display: block;
  content: '';
  margin-top: 18px
}

.short-underline:after {
  display: block;
  content: "";
  height: 3px;
  width: 80px;
  margin: 20px auto 30px
}

.uppercase {
  text-transform: uppercase
}

.non-uppercase {
  text-transform: none !important
}

.slogan {
  text-transform: uppercase;
  font-size: 40px
}

.big-font {
  font-size: 15px
}

@media (max-width: 991px) {
  .big-font {
    font-size: 13px
  }
}

.extra-font {
  font-size: 26px;
  line-height: 44px
}

@media (max-width: 991px) {
  .extra-font {
    font-size: 20px;
    line-height: 33px
  }
}

.half-height {
  height: 50%
}

.full-height {
  height: 100%
}

ul.simple {
  padding: 0 0 0 10px
}

ul.simple li {
  text-indent: -5px;
  list-style: none;
  display: list-item;
  text-align: -webkit-match-parent
}

ul.simple li:before {
  content: "-";
  position: relative;
  left: -5px
}

.main-icon {
  font-size: 200px
}

.pane-float-right {
  float: right
}

.pane-float-left {
  float: left
}

.pane-float-left, .pane-float-right {
  width: 555px
}

@media (max-width: 1199px) {
  .pane-float-left, .pane-float-right {
    width: 455px
  }
}

@media (max-width: 991px) {
  .pane-float-left, .pane-float-right {
    float: none;
    width: 100%
  }
}

.credits-overlay, .map-overlay {
  transform: translateY(0%);
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%)
}

.credits-overlay .content-container > ul > li {
  text-transform: uppercase
}

.credits-overlay .content-container > ul > li li {
  text-transform: none
}

.credits-overlay h3 {
  font-size: 30px
}

.credits-overlay i {
  font-size: 120px
}

.credits-overlay ul {
  padding: 0;
  text-align: center
}

.credits-overlay ul li {
  list-style: none;
  display: block;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 1px
}

@media (max-width: 991px) {
  .slogan {
    font-size: 18px
  }
}

@media (max-width: 767px) {
  .hide-on-small-device {
    display: none
  }
  .slogan {
    font-size: 20px !important
  }
}

@media (max-width: 479px) {
  .slogan {
    font-size: 16px !important
  }
  .subtitle {
    font-size: 14px !important
  }
}

.alt-placeholder {
  display: none
}

.ie9 .alt-placeholder {
  display: block
}

.banner-cell {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center
}

.pane {
  padding: 0 !important
}

.incut {
  height: 400px
}

.incut > div {
  width: 50%
}

.incut > div h3 {
  margin-top: 0
}

@media (max-width: 991px) {
  .incut > div {
    width: 90%
  }
}

.text-uppercase {
  text-transform: uppercase
}

.file-protocol-alert {
  width: 600px;
  margin-left: -300px;
  position: fixed;
  top: 20%;
  left: 50%;
  z-index: 2000
}

@media (max-width: 767px) {
  .file-protocol-alert {
    width: 280px;
    margin-left: -140px
  }
}

.label {
  margin: 2px
}

.ajax-form-alert {
  width: 600px;
  position: fixed;
  left: 50%;
  top: 100px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  z-index: 2000
}

.ajax-form-alert button {
  margin-left: 10px;
  margin-right: 10px
}

@media (max-width: 767px) {
  .ajax-form-alert {
    width: 280px;
    margin-left: -140px
  }
}

.control-group.danger, .control-group.error, .control-group.info, .control-group.primary, .control-group.success, .control-group.warning {
  background-color: transparent
}

.view {
  overflow: hidden;
  width: 100%;
  position: relative
}

.view > .content {
  position: relative;
  padding-top: 70px;
  padding-bottom: 70px;
  -webkit-transition: -webkit-transform .5s;
  -moz-transition: -moz-transform .5s;
  -o-transition: -o-transform .5s;
  transition: transform .5s
}

.view > .content.border-bottom {
  border-bottom: 4px solid
}

.view > .content.border-top {
  border-top: 4px solid
}

.view > .content.skew-top-left, .view > .content.skew-top-right {
  padding-top: 130px
}

.view > .content.skew-bottom-left, .view > .content.skew-bottom-right {
  padding-bottom: 130px
}

.view > .content.small-top-padding {
  padding-top: 40px
}

.view > .content.small-bottom-padding {
  padding-bottom: 50px
}

.view.section-header .content {
  padding-top: 60px;
  padding-bottom: 20px
}

.view.section-header .content.skew-top-left, .view.section-header .content.skew-top-right {
  padding-top: 60px
}

.view.section-header .content.skew-bottom-left, .view.section-header .content.skew-bottom-right {
  padding-bottom: 60px
}

body > section:first-of-type > .view:first-of-type > .content {
  padding-top: 80px;
  padding-bottom: 75px
}

@media (max-width: 767px) {
  body > section:first-of-type > .view:first-of-type > .content {
    padding-top: 40px;
    padding-bottom: 40px
  }
}

.no-top-padding {
  padding-top: 0 !important
}

.no-top-padding.skew-top-left, .no-top-padding.skew-top-right {
  padding-top: 60px !important
}

.no-bottom-padding {
  padding-bottom: 0 !important
}

.no-bottom-padding.skew-bottom-left, .no-bottom-padding.skew-bottom-right {
  padding-bottom: 60px !important
}

body > footer {
  padding: 65px 0 25px;
  line-height: 18px;
  overflow: hidden;
  -webkit-transition: -webkit-transform .5s;
  -moz-transition: -moz-transform .5s;
  transition: transform .5s
}

body > footer .bottom {
  padding: 25px 0;
  margin-top: 30px
}

body > footer .social-links {
  font-size: 24px;
  line-height: 40px
}

@media (max-width: 479px) {
  body > footer .social-links {
    font-size: 16px
  }
}

body > footer.border-bottom {
  border-bottom: 4px solid
}

body > footer.border-top {
  border-top: 4px solid
}

body > footer ul.simple li {
  line-height: 29px
}

body > footer .footer-description {
  padding: 10px 0
}

body > footer .photo {
  margin: 10px -3px
}

body > footer .photo > div {
  padding: 3px
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  display: block
}

body > .bg {
  position: fixed
}

body > .bg, body > .video-bg, body > .vimeo-bg, body > .youtube-bg, body > iframe, body > img {
  z-index: 10
}

footer, section {
  z-index: 100;
  position: relative
}

footer > .bg, section > .bg {
  z-index: 200
}

body > .paper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 57px
}

.grid-example {
  font-size: 67%
}

.grid-example > .row {
  margin-bottom: 5px
}

.grid-example > .row > div {
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px
}

.mobile-only {
  display: none !important
}

.mobile .mobile-only {
  display: block !important
}

.mobile .mobile-hide {
  display: none !important
}

.poor-browser .view > .bg, .poor-browser body > .bg {
  display: none
}

.poor-browser .view > .bg:last-of-type, .poor-browser body > .bg:last-of-type {
  display: block
}

.pattern-dark-1:before, .pattern-dark-2:before, .pattern-dark-3:before, .pattern-light-1:before, .pattern-light-2:before, .pattern-light-3:before, .pattern:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: .8
}

.pattern:before {
  background: url(/assets/img/pattern.png)
}

.pattern-dark-1:before {
  background: url(/assets/img/pattern-dark-1.png)
}

.pattern-dark-2:before {
  background: url(/assets/img/pattern-dark-2.png)
}

.pattern-dark-3:before {
  background: url(/assets/img/pattern-dark-3.png)
}

.pattern-light-1:before {
  background: url(/assets/img/pattern-light-1.png)
}

.pattern-light-2:before {
  background: url(/assets/img/pattern-light-2.png)
}

.pattern-light-3:before {
  background: url(/assets/img/pattern-light-3.png)
}

.lead {
  font-weight: 400;

  p {
    font-size: 16px;
    margin-bottom: 40px;
    line-height: 24px;
  }
}

ul.pagination {
  list-style: none;
  padding: 0;
  display: inline-block;
  font-size: 0
}

ul.pagination a {
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s
}

.android-browser-4_3minus ul.pagination a {
  transition: none;
  -webkit-transition: none
}

ul.pagination li {
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px
}

.pagination > li > a, .pagination > li > span {
  line-height: 22px;
  font-size: 13px
}

.lead {
  text-align: center;
  font-size: 16px;
  margin-bottom: 40px;
  line-height: 24px
}

.lead.text-left {
  text-align: left
}

.lead.text-right {
  text-align: right
}

.widget-tabs {
  border-radius: 4px 4px 0 0
}

.panel > .list-group {
  border-top: 1px solid
}

.panel > .list-group .list-group-item {
  border-width: 0 0 1px;
  margin-bottom: 0
}

#comments {
  border-top: 1px solid
}

#comments .comment-list {
  list-style: none;
  margin: 20px 0 65px;
  padding: 0
}

#comments .comment-list li {
  margin: 0;
  padding: 0
}

#comments .comment-list .comment {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid;
  min-height: 85px
}

#comments .comment-list .children {
  list-style: none;
  margin: 0;
  padding: 0 0 0 30px
}

.avatar {
  float: left;
  margin-right: 15px;
  padding: 4px;
  line-height: 0;
  border: 1px solid
}

.avatar img {
  width: 54px;
  height: auto
}

.comment-form {
  margin-bottom: 50px
}

#post-author {
  position: relative;
  margin-bottom: 50px;
  min-height: 222px
}

#post-author .avatar img {
  width: 110px
}

.post {
  margin-bottom: 40px
}

.post .post-meta {
  font-size: 11px;
  padding-bottom: 15px
}

.post .post-meta .post-day {
  font-size: 70px;
  font-weight: 800;
  line-height: 1
}

.post .post-meta .post-month {
  border-bottom: 1px solid;
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
  padding-bottom: 20px;
  margin-bottom: 20px
}

.post .post-meta > div {
  text-align: right
}

@media (max-width: 991px) {
  .post .post-meta .post-author, .post .post-meta .post-comments, .post .post-meta .post-day, .post .post-meta .post-month, .post .post-meta .post-permalink {
    float: left;
    font-size: 12px;
    padding: 0 15px 10px 0;
    margin: 0;
    line-height: 1
  }
  .post .post-meta .post-day {
    padding-right: 3px
  }
  .post .post-meta .post-month {
    border-bottom: none
  }
}

.post .post-body {
  padding: 0 25px;
  clear: both
}

.post .post-read-more {
  text-transform: uppercase;
  font-weight: 500
}

.post .post-image {
  margin-left: -25px;
  margin-right: -25px
}

.post .post-image a {
  width: 100%
}

.post .post-tags {
  margin-top: 15px;
  font-weight: 500
}

.post .post-tags i {
  display: inline-block;
  margin-right: 4px
}

.post .post-tags ul {
  list-style: none;
  padding: 0;
  display: inline-block
}

.post .post-tags ul li {
  display: inline-block
}

.post .post-tags ul li:after {
  content: ' . '
}

.post .post-tags ul li:last-child:after {
  content: ''
}

.post .comment-form {
  padding-bottom: 25px
}

.help-toc-titles {
  font-size: 11px;
  padding-top: 80px;
  padding-bottom: 215px;
  padding-right: 35px
}

.help-toc-titles > div {
  text-align: right
}

.help-toc-titles h1 {
  font-size: 70px;
  font-weight: 800;
  line-height: 1;
  text-align: right;
  margin: 0;
  text-transform: none
}

.help-toc-titles .help-toc-title {
  border-bottom: 1px solid;
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
  padding-bottom: 20px;
  margin-bottom: 20px
}

.help-toc {
  padding-left: 35px;
  padding-top: 80px;
  padding-bottom: 80px
}

@media (max-width: 767px) {
  .help-toc {
    padding-top: 30px
  }
}

.help-toc h1 {
  font-weight: 800;
  text-transform: none
}

.help-toc h1 span {
  font-weight: 400
}

.help-credits ul {
  padding: 0;
  text-align: center
}

.help-credits ul li {
  list-style: none;
  display: block;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 1px
}

body.example-page {
  padding: 0
}

body.example-page .content {
  padding: 40px 0
}

.example {
  width: 100%
}

pre.example {
  max-height: 395px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px
}

iframe.example {
  height: 395px;
  border: none;
  border: 1px solid;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px
}

pre {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  background-color: #222;
  color: #fff;
  border: 1px solid #555;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 11px
}

pre code {
  background-color: transparent
}

pre code .token.comment, pre code .token.doctype {
  color: #787878
}

pre code .token.punctuation, pre code .token.tag {
  color: #ad895c
}

pre code .token.attr-value {
  color: #919e6b
}

.force3d .bg {
  transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0)
}

#top-nav li {
  letter-spacing: 3px
}

#top-nav .navbar-brand {
  letter-spacing: .9px
}

.btn, .button, input.button {
  letter-spacing: 2px
}

#upper {
  letter-spacing: .7px
}

.header-details, .title {
  letter-spacing: 2px
}

.counter .caption, .site-caption {
  letter-spacing: 1px
}

.site-title-small {
  letter-spacing: 5px
}

body > footer .social-links {
  letter-spacing: 3px
}

.select-demo h4, h4, h5, h6 {
  letter-spacing: 2px
}

.select-demo .caption {
  letter-spacing: 1px
}

.lead, p {
  letter-spacing: 1.2px
}

.gallery .filter a {
  letter-spacing: 2px
}

.home-angie .full-size {
  padding-top: 80px;
  padding-bottom: 75px
}

@media (max-width: 991px) {
  .home-angie .full-size {
    padding-top: 90px !important;
    padding-bottom: 75px !important
  }
}

.home-angie h1 {
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 20px;
  margin-top: 0;
  font-weight: 400
}

.home-angie h1 span {
  font-weight: 800
}

@media (max-width: 1199px) {
  .home-angie h1 {
    font-size: 45px;
    line-height: 45px
  }
}

@media (max-width: 991px) {
  .home-angie h1 {
    text-align: center
  }
}

@media (max-width: 767px) {
  .home-angie h1 {
    font-size: 35px;
    line-height: 35px
  }
}

.home-angie .textillate {
  font-size: 34px;
  line-height: 41px;
  font-weight: 400
}

.home-angie .header-details {
  margin-bottom: 15px;
  margin-top: -10px
}

.home-angie .box {
  padding: 60px 20px !important
}

.home-angie .simple {
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase
}

.home-lucy .textillate, .home-lynda .textillate {
  font-size: 35px;
  line-height: 35px
}

@media (max-width: 991px) {
  .home-lucy .textillate, .home-lynda .textillate {
    font-size: 24px;
    line-height: 24px
  }
}

.home-lucy h1, .home-lynda h1 {
  font-weight: 800;
  margin-top: 0;
  margin-bottom: 11px;
  font-size: 86px;
  line-height: 86px
}

@media (max-width: 991px) {
  .home-lucy h1, .home-lynda h1 {
    font-size: 60px;
    line-height: 60px
  }
}

@media (max-width: 767px) {
  .home-lucy h1, .home-lynda h1 {
    font-size: 48px;
    line-height: 48px
  }
}

.home-alice .full-size, .home-mary .full-size, .home-suzi .full-size {
  padding-top: 80px;
  padding-bottom: 75px
}

@media (max-width: 991px) {
  .home-alice .full-size, .home-mary .full-size, .home-suzi .full-size {
    padding-top: 90px !important;
    padding-bottom: 75px !important
  }
}

.home-alice h1, .home-mary h1, .home-suzi h1 {
  font-size: 90px;
  line-height: 90px;
  margin-top: 0;
  margin-bottom: 11px
}

@media (max-width: 1199px) {
  .home-alice h1, .home-mary h1, .home-suzi h1 {
    font-size: 70px;
    line-height: 70px
  }
}

@media (max-width: 991px) {
  .home-alice h1, .home-mary h1, .home-suzi h1 {
    text-align: center
  }
}

@media (max-width: 767px) {
  .home-alice h1, .home-mary h1, .home-suzi h1 {
    font-size: 50px;
    line-height: 50px
  }
}

@media (max-width: 991px) {
  .home-alice p, .home-mary p, .home-suzi p {
    text-align: center !important
  }
}

.home-alice .textillate, .home-mary .textillate, .home-suzi .textillate {
  font-size: 35px;
  line-height: 35px
}

@media (max-width: 991px) {
  .home-alice .textillate, .home-mary .textillate, .home-suzi .textillate {
    font-size: 24px;
    line-height: 24px
  }
}

.home-alice ul, .home-mary ul, .home-suzi ul {
  font-weight: 700;
  letter-spacing: 1px
}

.home-mary h1, .home-suzi h1 {
  font-size: 70px;
  line-height: 84px;
  margin-bottom: 11px
}

@media (max-width: 1199px) {
  .home-mary h1, .home-suzi h1 {
    font-size: 60px;
    line-height: 72px
  }
}

@media (max-width: 991px) {
  .home-mary h1, .home-suzi h1 {
    font-size: 50px;
    line-height: 60px
  }
}

@media (max-width: 767px) {
  .home-mary h1, .home-suzi h1 {
    font-size: 40px;
    line-height: 48px
  }
}

.home-mary .lead, .home-suzi .lead {
  margin-bottom: 30px
}

.home-mary .textillate, .home-suzi .textillate {
  font-size: 28px;
  line-height: 34px
}

.home-suzi h1 {
  font-size: 100px;
  line-height: 100px;
  margin-bottom: 11px
}

@media (max-width: 1199px) {
  .home-suzi h1 {
    font-size: 70px;
    line-height: 70px
  }
}

@media (max-width: 991px) {
  .home-suzi h1 {
    font-size: 60px;
    line-height: 60px
  }
}

@media (max-width: 767px) {
  .home-suzi h1 {
    font-size: 50px;
    line-height: 50px
  }
}

.home-suzi h2 {
  font-size: 72px;
  line-height: 72px;
  margin-bottom: 0
}

@media (max-width: 1199px) {
  .home-suzi h2 {
    font-size: 60px;
    line-height: 60px
  }
}

@media (max-width: 991px) {
  .home-suzi h2 {
    font-size: 50px;
    line-height: 50px
  }
}

@media (max-width: 767px) {
  .home-suzi h2 {
    font-size: 40px;
    line-height: 40px
  }
}

.home-suzi .title {
  font-weight: 500;
  margin-bottom: 20px
}

#customize-panel {
  position: fixed;
  top: 130px;
  z-index: 1040;
  left: -1200px;
  font-size: 10px;
  -webkit-transition: left .5s;
  -moz-transition: left .5s;
  -ms-transition: left .5s;
  -o-transition: left .5s;
  transition: left .5s;
  pointer-events: none
}

.android-browser-4_3minus #customize-panel {
  transition: none;
  -webkit-transition: none
}

#customize-panel .title {
  text-align: center;
  font-size: 18px;
  padding: 0 10px 10px;
  line-height: normal;
  border-bottom: 1px solid;
  margin: 0 10px 10px
}

#customize-panel .options {
  border-top: 10px solid;
  border-bottom: 10px solid;
  pointer-events: auto;
  width: 340px;
  height: 630px;
  overflow-y: auto;
  overflow-x: hidden;
  float: left;
  padding-bottom: 10px;
  position: relative
}

#customize-panel .options .options-gate {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  padding: 10px;
  text-align: center;
  font-size: 18px;
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -ms-transition: opacity 1s;
  -o-transition: opacity 1s;
  transition: opacity 1s
}

.android-browser-4_3minus #customize-panel .options .options-gate {
  transition: none;
  -webkit-transition: none
}

#customize-panel .options a.button {
  border: 1px solid;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px
}

#customize-panel .options a.button span {
  text-transform: none
}

#customize-panel .options .themes {
  margin: 0 10px 10px
}

#customize-panel .options .themes select {
  width: 100%;
  font-size: 18px
}

#customize-panel .options .themes a.button {
  width: 100%;
  text-align: center
}

#customize-panel .options .colors, #customize-panel .options .statuses {
  padding: 0 10px;
  margin: 0 10px
}

#customize-panel .options .colors > div.row, #customize-panel .options .statuses > div.row {
  margin-left: -10px;
  margin-right: -10px
}

#customize-panel .options .colors > div.row > div, #customize-panel .options .statuses > div.row > div {
  padding: 10px 10px 5px
}

#customize-panel .toggle-button {
  pointer-events: auto;
  float: left;
  width: 26px;
  height: 26px;
  line-height: 26px;
  font-size: 12px;
  text-align: center;
  -moz-border-top-right-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-bottom-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px
}

#customize-panel .custom-css {
  z-index: 1060;
  position: fixed;
  width: 750px;
  margin-left: -375px;
  top: 110px;
  left: 50%;
  visibility: hidden;
  padding-bottom: 10px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  -ms-transition: opacity .3s;
  -o-transition: opacity .3s;
  transition: opacity .3s
}

.android-browser-4_3minus #customize-panel .custom-css {
  transition: none;
  -webkit-transition: none
}

#customize-panel .custom-css h2, #customize-panel .custom-css p {
  padding: 5px 10px;
  margin: 0 0 10px
}

#customize-panel .custom-css h4 {
  -moz-border-top-left-radius: 5px;
  -webkit-border-top-left-radius: 5px;
  border-top-left-radius: 5px;
  -moz-border-top-right-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  border-top-right-radius: 5px;
  margin: 0;
  line-height: normal;
  text-transform: none;
  text-align: center;
  padding: 10px
}

#customize-panel .controls {
  clear: both;
  text-align: center;
  margin: 0 10px;
  padding: 10px 0 5px
}

#customize-panel .file-name {
  font-size: 14px;
  font-weight: 700
}

#customize-panel textarea {
  font-size: 13px
}

.factory-flag {
  display: none
}

body.select-demo {
  padding: 0
}

body.select-demo > section:first-of-type > .view:first-of-type > .content {
  padding-top: 60px
}

body.select-demo .view > .content .features {
  text-align: center;
  margin-top: 20px
}

body.select-demo .view > .content img {
  width: 100%;
  height: auto
}

body.select-demo .view > .content a {
  text-decoration: none
}

body.select-demo .view > .content h1 {
  font-size: 70px
}

@media (max-width: 991px) {
  body.select-demo .view > .content h1 {
    font-size: 60px
  }
}

@media (max-width: 767px) {
  body.select-demo .view > .content h1 {
    font-size: 48px
  }
}

body.select-demo .view > .content h4 {
  font-size: 18px;
  text-align: center;
  font-weight: 700
}

body.select-demo .view > .content .title {
  margin-bottom: 50px;
  font-size: 18px
}

body.select-demo .view > .content .caption {
  text-transform: uppercase;
  font-size: 16px;
  line-height: 22px;
  margin-top: 20px;
  margin-bottom: 50px;
  font-weight: 700
}

#top-nav a:hover {
  background-color: transparent
}

.button.light-hover:before, .button:before {
  background-color: rgba(255, 255, 255, .13)
}

.button.dark-hover:before {
  background-color: rgba(0, 0, 0, .13)
}

.button.active:before {
  opacity: 0
}

#gate.light {
  background-color: #fff;
  color: #222
}

#gate.light #gate-bar {
  background-color: #222
}

#gate.dark {
  background-color: #1c1c1c;
  color: #fff
}

#gate.dark #gate-bar {
  background-color: #fff
}

.loading i {
  color: #fff;
  opacity: .5
}

.loading i.fa-inverse {
  color: #222;
  opacity: 1
}

body, html {
  background-color: #fff
}

#customize-panel .title {
  border-color: #555
}

#customize-panel .toggle-button, #customize-panel h2 {
  background-color: #222;
  color: #efefef
}

#customize-panel a.button {
  background-color: #111;
  color: #efefef;
  border-color: #fff
}

#customize-panel a.button:hover {
  background-color: #191919
}

#customize-panel .options {
  border-color: transparent;
  background-color: #222;
  color: #efefef
}

#customize-panel .options .options-gate {
  background-color: #222;
  background-color: rgba(34, 34, 34, .8)
}

#customize-panel .options .themes select {
  background-color: #444;
  color: #efefef
}

#customize-panel .options .disable-area span {
  background-color: #444
}

#customize-panel textarea {
  background-color: #444;
  color: #efefef
}

#customize-panel .custom-css {
  background-color: #222;
  color: #efefef
}

#customize-panel .custom-css h2 {
  background-color: #444
}

#customize-panel .custom-css h4 {
  background-color: #181818;
  color: #ccc
}

#customize-panel .custom-css textarea {
  border-color: #656565
}

.background-5-light {
  background-color: rgba(255, 255, 255, .05) !important
}

.background-10-light {
  background-color: rgba(255, 255, 255, .1) !important
}

.background-15-light {
  background-color: rgba(255, 255, 255, .15) !important
}

.background-20-light {
  background-color: rgba(255, 255, 255, .2) !important
}

.background-25-light {
  background-color: rgba(255, 255, 255, .25) !important
}

.background-30-light {
  background-color: rgba(255, 255, 255, .3) !important
}

.background-35-light {
  background-color: rgba(255, 255, 255, .35) !important
}

.background-40-light {
  background-color: rgba(255, 255, 255, .4) !important
}

.background-45-light {
  background-color: rgba(255, 255, 255, .45) !important
}

.background-50-light {
  background-color: rgba(255, 255, 255, .5) !important
}

.background-55-light {
  background-color: rgba(255, 255, 255, .55) !important
}

.background-60-light {
  background-color: rgba(255, 255, 255, .6) !important
}

.background-65-light {
  background-color: rgba(255, 255, 255, .65) !important
}

.background-70-light {
  background-color: rgba(255, 255, 255, .7) !important
}

.background-75-light {
  background-color: rgba(255, 255, 255, .75) !important
}

.background-80-light {
  background-color: rgba(255, 255, 255, .8) !important
}

.background-85-light {
  background-color: rgba(255, 255, 255, .85) !important
}

.background-90-light {
  background-color: rgba(255, 255, 255, .9) !important
}

.background-95-light {
  background-color: rgba(255, 255, 255, .95) !important
}

.background-100-light {
  background-color: #fff !important
}

.background-5-dark {
  background-color: rgba(0, 0, 0, .05) !important
}

.background-10-dark {
  background-color: rgba(0, 0, 0, .1) !important
}

.background-15-dark {
  background-color: rgba(0, 0, 0, .15) !important
}

.background-20-dark {
  background-color: rgba(0, 0, 0, .2) !important
}

.background-25-dark {
  background-color: rgba(0, 0, 0, .25) !important
}

.background-30-dark {
  background-color: rgba(0, 0, 0, .3) !important
}

.background-35-dark {
  background-color: rgba(0, 0, 0, .35) !important
}

.background-40-dark {
  background-color: rgba(0, 0, 0, .4) !important
}

.background-45-dark {
  background-color: rgba(0, 0, 0, .45) !important
}

.background-50-dark {
  background-color: rgba(0, 0, 0, .5) !important
}

.background-55-dark {
  background-color: rgba(0, 0, 0, .55) !important
}

.background-60-dark {
  background-color: rgba(0, 0, 0, .6) !important
}

.background-65-dark {
  background-color: rgba(0, 0, 0, .65) !important
}

.background-70-dark {
  background-color: rgba(0, 0, 0, .7) !important
}

.background-75-dark {
  background-color: rgba(0, 0, 0, .75) !important
}

.background-80-dark {
  background-color: rgba(0, 0, 0, .8) !important
}

.background-85-dark {
  background-color: rgba(0, 0, 0, .85) !important
}

.background-90-dark {
  background-color: rgba(0, 0, 0, .9) !important
}

.background-95-dark {
  background-color: rgba(0, 0, 0, .95) !important
}

.background-100-dark {
  background-color: #000 !important
}

.m-b-11 {
  margin-bottom: 11px;
}

//.slick-dots {
//  li {
//    button {
//      border-radius: 50%;
//      background-color: red;
//      width: 20px;
//      height: 20px;
//      padding: 5px;
//    }
//  }
//}