.colors-a {
  color: #767676;
  background-color: rgba(255, 255, 255, .6)
}

.colors-a .bg-color {
  color: #fff
}

.colors-a .out {
  opacity: .6
}

.colors-a .heading, .colors-a.heading {
  color: #333
}

.colors-a .highlight, .colors-a .link, .colors-a.highlight, .colors-a.link {
  color: #7a8288
}

.colors-a .background, .colors-a.background {
  background-color: rgba(255, 255, 255, .6)
}

.colors-a .background-solid, .colors-a.background-solid {
  background-color: #fff
}

.colors-a .background-hard, .colors-a.background-hard {
  background-color: rgba(255, 255, 255, .86666667)
}

.colors-a .background-lite, .colors-a.background-lite {
  background-color: rgba(255, 255, 255, .34248927)
}

.colors-a .col-icon, .colors-a .col-left-marginalia, .colors-a col-right-marginalia, .colors-a h1, .colors-a h2, .colors-a h3, .colors-a h4, .colors-a h5, .colors-a h6 {
  color: #333
}

.colors-a a, a.colors-a {
  color: #7a8288
}

.colors-a a:hover, a.colors-a:hover {
  color: rgba(219, 171, 131, .75)
}

.colors-a .line {
  border-color: #767676;
  border-color: rgba(118, 118, 118, .3)
}

.colors-a .line:hover {
  background-color: rgba(255, 255, 255, .34248927)
}

.colors-a .help-block li {
  color: #7a8288
}

.colors-a .grid-example > .row > div:nth-child(1n) {
  color: #333;
  background-color: rgba(118, 118, 118, .1)
}

.colors-a .grid-example > .row > div:nth-child(2n) {
  color: #333;
  background-color: rgba(118, 118, 118, .3)
}

.colors-a ::-webkit-input-placeholder {
  color: rgba(118, 118, 118, .6)
}

.colors-a :-moz-placeholder {
  color: rgba(118, 118, 118, .6);
  opacity: 1
}

.colors-a ::-moz-placeholder {
  color: rgba(118, 118, 118, .6);
  opacity: 1
}

.colors-a :-ms-input-placeholder {
  color: rgba(118, 118, 118, .6)
}

.colors-a .border, .colors-a .border-bottom, .colors-a .border-left, .colors-a .border-right, .colors-a .border-top, .colors-a.border, .colors-a.border-bottom, .colors-a.border-left, .colors-a.border-right, .colors-a.border-top {
  border-color: rgba(51, 51, 51, .3) !important
}

.colors-a .border-hard, .colors-a.border-hard {
  border-color: rgba(51, 51, 51, .7) !important
}

.colors-a .border-lite, .colors-a.border-lite {
  border-color: rgba(51, 51, 51, .08) !important
}

.colors-a .text {
  color: #767676 !important
}

.colors-a .text-background, .colors-a.text-background {
  color: #fff !important
}

.colors-a .background-highlight, .colors-a.background-highlight {
  background-color: #7a8288 !important
}

.colors-a .background-heading, .colors-a.background-heading {
  background-color: #333 !important
}

.colors-a .background-text, .colors-a.background-text {
  background-color: #767676 !important
}

.colors-a .background-link, .colors-a.background-link {
  background-color: #7a8288 !important
}

.colors-a .border-text, .colors-a.border-text {
  border-color: rgba(118, 118, 118, .7) !important
}

.colors-a .border-highlight, .colors-a.border-highlight {
  border-color: rgba(219, 171, 131, .7) !important
}

.colors-a .border-heading, .colors-a.border-heading {
  border-color: rgba(51, 51, 51, .7) !important
}

.colors-a .border-link, .colors-a.border-link {
  border-color: rgba(219, 171, 131, .7) !important
}

.colors-a .background-0, .colors-a.background-0 {
  background-color: transparent !important
}

.colors-a .background-5, .colors-a.background-5 {
  background-color: rgba(255, 255, 255, .05) !important
}

.colors-a .background-10, .colors-a.background-10 {
  background-color: rgba(255, 255, 255, .1) !important
}

.colors-a .background-15, .colors-a.background-15 {
  background-color: rgba(255, 255, 255, .15) !important
}

.colors-a .background-20, .colors-a.background-20 {
  background-color: rgba(255, 255, 255, .2) !important
}

.colors-a .background-25, .colors-a.background-25 {
  background-color: rgba(255, 255, 255, .25) !important
}

.colors-a .background-30, .colors-a.background-30 {
  background-color: rgba(255, 255, 255, .3) !important
}

.colors-a .background-35, .colors-a.background-35 {
  background-color: rgba(255, 255, 255, .35) !important
}

.colors-a .background-40, .colors-a.background-40 {
  background-color: rgba(255, 255, 255, .4) !important
}

.colors-a .background-45, .colors-a.background-45 {
  background-color: rgba(255, 255, 255, .45) !important
}

.colors-a .background-50, .colors-a.background-50 {
  background-color: rgba(255, 255, 255, .5) !important
}

.colors-a .background-55, .colors-a.background-55 {
  background-color: rgba(255, 255, 255, .55) !important
}

.colors-a .background-60, .colors-a.background-60 {
  background-color: rgba(255, 255, 255, .6) !important
}

.colors-a .background-65, .colors-a.background-65 {
  background-color: rgba(255, 255, 255, .65) !important
}

.colors-a .background-70, .colors-a.background-70 {
  background-color: rgba(255, 255, 255, .7) !important
}

.colors-a .background-75, .colors-a.background-75 {
  background-color: rgba(255, 255, 255, .75) !important
}

.colors-a .background-80, .colors-a.background-80 {
  background-color: rgba(255, 255, 255, .8) !important
}

.colors-a .background-85, .colors-a.background-85 {
  background-color: rgba(255, 255, 255, .85) !important
}

.colors-a .background-90, .colors-a.background-90 {
  background-color: rgba(255, 255, 255, .9) !important
}

.colors-a .background-95, .colors-a.background-95 {
  background-color: rgba(255, 255, 255, .95) !important
}

.colors-a .background-100, .colors-a.background-100 {
  background-color: #fff !important
}

.colors-a h2::after {
  background-color: rgba(51, 51, 51, .4)
}

.colors-a h3 a {
  color: #333
}

.colors-a h3 a:hover {
  color: rgba(51, 51, 51, .75)
}

.colors-a h3 {
  border-color: rgba(219, 171, 131, .5)
}

.colors-a input, .colors-a textarea {
  background-color: transparent;
  border-color: rgba(51, 51, 51, .6);
  outline-color: rgba(118, 118, 118, 0)
}

.colors-a input:focus, .colors-a textarea:focus {
  outline-color: rgba(118, 118, 118, .37)
}

#top-nav.colors-a {
  border-color: rgba(219, 171, 131, .16);
  color: #333
}

#top-nav.colors-a li a {
  color: #333
}

#top-nav.colors-a li a:hover {
  color: inherit
}

#top-nav.colors-a .navbar-brand, #top-nav.colors-a .navbar-brand:hover {
  color: #333
}

#top-nav.colors-a .nav > li ul {
  background-color: rgba(255, 255, 255, .6)
}

#top-nav.colors-a .nav > li ul a {
  color: #333
}

#top-nav.colors-a .nav > li ul a.active {
  color: #7a8288
}

#top-nav.colors-a .nav > li ul a:hover {
  background-color: transparent
}

#top-nav.colors-a li.open > a {
  background-color: rgba(255, 255, 255, .34248927)
}

#top-nav.colors-a .navbar-nav a.active {
  color: #7a8288
}

#top-nav.colors-a .navbar-toggle {
  background-color: transparent !important;
  border-color: #333
}

#top-nav.colors-a .navbar-toggle .icon-bar {
  background-color: #333
}

.colors-a.page-border {
  border-color: rgba(219, 171, 131, .16)
}

.colors-a.page-border a, .ext-nav a.colors-a .side-title {
  color: #333
}

.ext-nav a.colors-a .side-label {
  color: #7a8288
}

.ext-nav a.colors-a:hover .side-title {
  color: #333
}

.ext-nav a.colors-a:hover .side-label {
  color: #7a8288
}

.colors-a .menu-toggle span, .colors-a .menu-toggle span:after, .colors-a .menu-toggle span:before {
  background-color: #333
}

.colors-a .menu-toggle.navbar-toggle span, .colors-a .menu-toggle.navbar-toggle span:after, .colors-a .menu-toggle.navbar-toggle span:before {
  background-color: #7a8288
}

.colors-a .menu-toggle.show span {
  background-color: transparent
}

.colors-a .carousel .slick-next, .colors-a .carousel .slick-prev, .colors-a .slider .slick-next, .colors-a .slider .slick-prev {
  background-color: #f9f9f9;
  background-color: rgba(255, 255, 255, .8)
}

.colors-a .carousel .slick-next:before, .colors-a .carousel .slick-prev:before, .colors-a .slider .slick-next:before, .colors-a .slider .slick-prev:before {
  color: #222;
  background-color: transparent
}

.colors-a .carousel .slick-next:hover, .colors-a .carousel .slick-prev:hover, .colors-a .slider .slick-next:hover, .colors-a .slider .slick-prev:hover {
  background-color: #efefef;
  background-color: rgba(255, 255, 255, .6)
}

.colors-a .carousel .slick-dots li button:before, .colors-a .slider .slick-dots li button:before {
  color: #767676;
  color: rgba(118, 118, 118, .4);
  opacity: 1
}

.colors-a .carousel .slick-dots li.slick-active button:before, .colors-a .slider .slick-dots li.slick-active button:before {
  color: #7a8288;
  color: rgba(118, 118, 118, .7);
  opacity: 1
}

.colors-a .carousel .slick-slide {
  background-color: rgba(255, 255, 255, .6);
  -webkit-box-shadow: 0 0 8px #000;
  -moz-box-shadow: 0 0 8px #000;
  box-shadow: 0 0 8px #000
}

.colors-a .gallery .filter .nav li a {
  color: #333;
  background-color: transparent
}

.colors-a .gallery .filter .nav li a.active {
  color: #7a8288
}

.colors-a.gallery-overlay .field {
  color: #333
}

.colors-a .cross:after, .colors-a .cross:before {
  background-color: #333
}

.colors-a.circle {
  border-color: rgba(255, 255, 255, .6);
  color: #333
}

.colors-a.circle:after {
  background-color: transparent;
  border-color: rgba(255, 255, 255, .6)
}

.colors-a.circle:hover {
  background-color: transparent;
  color: rgba(255, 255, 255, .6)
}

.colors-a.circle:hover:after {
  border-color: rgba(255, 255, 255, .6)
}

.colors-a .process .process-row .process-step .process-box {
  border-color: rgba(118, 118, 118, .7)
}

.colors-a .process .process-row .process-step .process-box:before {
  background-color: #fff
}

#dot-scroll.colors-a li a span {
  background-color: #333
}

#dot-scroll.colors-a li a.active span, #dot-scroll.colors-a li a:hover.active span {
  background-color: rgba(255, 255, 255, .6)
}

#dot-scroll.colors-a li a.active span, #dot-scroll.colors-a li a.target span, #dot-scroll.colors-a li a.target.active span, #dot-scroll.colors-a li a:hover.active span, #dot-scroll.colors-a li a:hover.target span, #dot-scroll.colors-a li a:hover.target.active span {
  border-color: #333
}

.colors-a.skew.skew-bottom-left {
  border-bottom-color: transparent;
  border-left-color: #fff;
  background-color: transparent !important
}

.colors-a.skew.skew-bottom-right {
  border-bottom-color: #fff;
  border-left-color: transparent;
  background-color: transparent !important
}

.colors-a.skew.skew-top-right {
  border-top-color: transparent;
  border-right-color: #fff;
  background-color: transparent !important
}

.colors-a.skew.skew-top-left {
  border-top-color: #fff;
  border-right-color: transparent;
  background-color: transparent !important
}

.colors-a .loader {
  border-color: rgba(118, 118, 118, .1)
}

.colors-a .loader li:nth-child(1), .colors-a .loader li:nth-child(2), .colors-a .loader li:nth-child(3), .colors-a .loader li:nth-child(4) {
  background: #767676
}

.colors-a #comments, .colors-a #comments .comment-list .comment, .colors-a .avatar, .colors-a ul.tags-list li {
  border-color: rgba(51, 51, 51, .3) !important
}

.colors-a .post .post-meta, .colors-a .post .post-meta a {
  color: #333
}

.colors-a .post .post-meta a:hover {
  color: #7a8288
}

.colors-a .post .post-meta .post-month {
  border-color: rgba(51, 51, 51, .08) !important
}

.colors-a .post h3 a:hover {
  color: #333
}

.colors-a .post .post-tags {
  color: rgba(118, 118, 118, .85)
}

.colors-a .post .post-month {
  color: #7a8288
}

.colors-a .sidebar .title {
  color: #333
}

.colors-a .pagination .active a {
  background-color: rgba(51, 51, 51, .85);
  color: #fff
}

.colors-a .pagination .active a:hover {
  color: #fff
}

.colors-a .pagination a {
  background-color: transparent;
  border-color: rgba(51, 51, 51, .4) !important;
  color: #333
}

.colors-a .pagination a:hover {
  color: #7a8288
}

.colors-a .navbar-collapse {
  max-height: none
}

.colors-a .caption, .colors-a .header-details, .colors-a .subtitle, .colors-a .title {
  color: #333
}

.colors-a .header-details.highlight, .colors-a .slogan, .colors-a .subtitle.highlight, .colors-a .title.highlight {
  color: #7a8288
}

.colors-a .underline-dashed, .colors-a .underline-dotted {
  border-color: rgba(219, 171, 131, .7)
}

.colors-a .underline, .colors-a .underline-highlight, .colors-a .underline-link {
  border-color: rgba(219, 171, 131, .5)
}

.colors-a .underline-heading {
  border-color: rgba(51, 51, 51, .5)
}

.colors-a .underline-text {
  border-color: rgba(118, 118, 118, .5)
}

.colors-a .top-line:before {
  background-color: rgba(219, 171, 131, .7)
}

.colors-a .bottom-line:after, .colors-a .short-underline:after {
  background-color: #7a8288
}

.colors-a .stroke-highlight {
  text-shadow: -1px -1px 0 #7a8288, 1px -1px 0 #7a8288, -1px 1px 0 #7a8288, 1px 1px 0 #7a8288
}

.colors-a .stroke-heading {
  text-shadow: -1px -1px 0 #333, 1px -1px 0 #333, -1px 1px 0 #333, 1px 1px 0 #333
}

.colors-a .stroke-foreground {
  text-shadow: -1px -1px 0 #767676, 1px -1px 0 #767676, -1px 1px 0 #767676, 1px 1px 0 #767676
}

.colors-a .stroke-background {
  text-shadow: -1px -1px 0 rgba(255, 255, 255, .6), 1px -1px 0 rgba(255, 255, 255, .6), -1px 1px 0 rgba(255, 255, 255, .6), 1px 1px 0 rgba(255, 255, 255, .6)
}

.colors-a .label-border {
  border-color: rgba(219, 171, 131, .7);
  outline-color: #7a8288
}

.colors-a .bottom-menu, .colors-a .bottom-menu a, .colors-a .bottom-menu i {
  color: #767676 !important
}

.colors-a .bottom-menu a:hover {
  background-color: transparent;
  color: #7a8288
}

.colors-a .bottom-menu a:hover i {
  color: #7a8288
}

footer.colors-a a i:hover {
  color: #767676 !important
}

.colors-a .main-icon, .colors-a .main-icon:hover {
  color: #333
}

.colors-a.content.border-bottom, .colors-a.content.border-top, footer.colors-a.border-bottom, footer.colors-a.border-top {
  border-color: rgba(219, 171, 131, .8)
}

.colors-a input.button, .colors-a.credits-overlay .content-container > ul > li, .colors-a.credits-overlay .content-container > ul > li li {
  color: #7a8288
}

.colors-a input.button:hover {
  color: #333
}

.colors-a iframe.example, .colors-a pre.example {
  border-color: rgba(118, 118, 118, .3) !important
}

.colors-a.help-toc a, .colors-a.help-toc li, .colors-a.help-toc ul {
  color: #333
}

.colors-a .help-toc-title {
  border-color: rgba(51, 51, 51, .2)
}

.text-a {
  color: #767676
}

.background-0-a {
  background-color: transparent !important
}

.background-5-a {
  background-color: rgba(255, 255, 255, .05) !important
}

.background-10-a {
  background-color: rgba(255, 255, 255, .1) !important
}

.background-15-a {
  background-color: rgba(255, 255, 255, .15) !important
}

.background-20-a {
  background-color: rgba(255, 255, 255, .2) !important
}

.background-25-a {
  background-color: rgba(255, 255, 255, .25) !important
}

.background-30-a {
  background-color: rgba(255, 255, 255, .3) !important
}

.background-35-a {
  background-color: rgba(255, 255, 255, .35) !important
}

.background-40-a {
  background-color: rgba(255, 255, 255, .4) !important
}

.background-45-a {
  background-color: rgba(255, 255, 255, .45) !important
}

.background-50-a {
  background-color: rgba(255, 255, 255, .5) !important
}

.background-55-a {
  background-color: rgba(255, 255, 255, .55) !important
}

.background-60-a {
  background-color: rgba(255, 255, 255, .6) !important
}

.background-65-a {
  background-color: rgba(255, 255, 255, .65) !important
}

.background-70-a {
  background-color: rgba(255, 255, 255, .7) !important
}

.background-75-a {
  background-color: rgba(255, 255, 255, .75) !important
}

.background-80-a {
  background-color: rgba(255, 255, 255, .8) !important
}

.background-85-a {
  background-color: rgba(255, 255, 255, .85) !important
}

.background-90-a {
  background-color: rgba(255, 255, 255, .9) !important
}

.background-95-a {
  background-color: rgba(255, 255, 255, .95) !important
}

.background-100-a {
  background-color: #fff !important
}

.background-a {
  background-color: rgba(255, 255, 255, .6) !important
}

.background-lite-a {
  background-color: rgba(255, 255, 255, .34248927) !important
}

.background-lite-lite-a {
  background-color: rgba(255, 255, 255, .17142857) !important
}

.background-hard-a {
  background-color: rgba(255, 255, 255, .86666667) !important
}

.background-hard-hard-a {
  background-color: rgba(255, 255, 255, .92) !important
}

.border-background-a {
  border-color: rgba(255, 255, 255, .7) !important
}

.border-text-a {
  border-color: rgba(118, 118, 118, .7) !important
}

.border-highlight-a {
  border-color: rgba(219, 171, 131, .7) !important
}

.border-heading-a {
  border-color: rgba(51, 51, 51, .7) !important
}

.border-link-a {
  border-color: rgba(219, 171, 131, .7) !important
}

.heading-a {
  color: #333 !important
}

.text-a {
  color: #767676 !important
}

.text-background-a {
  color: #fff !important
}

.background-highlight-a {
  background-color: #7a8288 !important
}

.background-heading-a {
  background-color: #333 !important
}

.background-text-a {
  background-color: #767676 !important
}

.background-link-a {
  background-color: #7a8288 !important
}

.highlight-a, .link-a {
  color: #7a8288 !important
}

.colors-b {
  color: #efefef;
  background-color: rgba(71, 67, 64, .82)
}

.colors-b .bg-color {
  color: #474340
}

.colors-b .out {
  opacity: .82
}

.colors-b .heading, .colors-b.heading {
  color: #fff
}

.colors-b .highlight, .colors-b .link, .colors-b.highlight, .colors-b.link {
  color: #7a8288
}

.colors-b .background, .colors-b.background {
  background-color: rgba(71, 67, 64, .82)
}

.colors-b .background-solid, .colors-b.background-solid {
  background-color: #474340
}

.colors-b .background-hard, .colors-b.background-hard {
  background-color: rgba(71, 67, 64, .94)
}

.colors-b .background-lite, .colors-b.background-lite {
  background-color: rgba(71, 67, 64, .46806867)
}

.colors-b .col-icon, .colors-b .col-left-marginalia, .colors-b col-right-marginalia, .colors-b h1, .colors-b h2, .colors-b h3, .colors-b h4, .colors-b h5, .colors-b h6 {
  color: #fff
}

.colors-b a, a.colors-b {
  color: #7a8288
}

.colors-b a:hover, a.colors-b:hover {
  color: rgba(219, 171, 131, .75)
}

.colors-b .line {
  border-color: #efefef;
  border-color: rgba(239, 239, 239, .3)
}

.colors-b .line:hover {
  background-color: rgba(71, 67, 64, .46806867)
}

.colors-b .help-block li {
  color: #7a8288
}

.colors-b .grid-example > .row > div:nth-child(1n) {
  color: #fff;
  background-color: rgba(239, 239, 239, .1)
}

.colors-b .grid-example > .row > div:nth-child(2n) {
  color: #fff;
  background-color: rgba(239, 239, 239, .3)
}

.colors-b ::-webkit-input-placeholder {
  color: rgba(239, 239, 239, .6)
}

.colors-b :-moz-placeholder {
  color: rgba(239, 239, 239, .6);
  opacity: 1
}

.colors-b ::-moz-placeholder {
  color: rgba(239, 239, 239, .6);
  opacity: 1
}

.colors-b :-ms-input-placeholder {
  color: rgba(239, 239, 239, .6)
}

.colors-b .border, .colors-b .border-bottom, .colors-b .border-left, .colors-b .border-right, .colors-b .border-top, .colors-b.border, .colors-b.border-bottom, .colors-b.border-left, .colors-b.border-right, .colors-b.border-top {
  border-color: rgba(255, 255, 255, .3) !important
}

.colors-b .border-hard, .colors-b.border-hard {
  border-color: rgba(255, 255, 255, .7) !important
}

.colors-b .border-lite, .colors-b.border-lite {
  border-color: rgba(255, 255, 255, .08) !important
}

.colors-b .text {
  color: #efefef !important
}

.colors-b .text-background, .colors-b.text-background {
  color: #474340 !important
}

.colors-b .background-highlight, .colors-b.background-highlight {
  background-color: #7a8288 !important
}

.colors-b .background-heading, .colors-b.background-heading {
  background-color: #fff !important
}

.colors-b .background-text, .colors-b.background-text {
  background-color: #efefef !important
}

.colors-b .background-link, .colors-b.background-link {
  background-color: #7a8288 !important
}

.colors-b .border-text, .colors-b.border-text {
  border-color: rgba(239, 239, 239, .7) !important
}

.colors-b .border-highlight, .colors-b.border-highlight {
  border-color: rgba(219, 171, 131, .7) !important
}

.colors-b .border-heading, .colors-b.border-heading {
  border-color: rgba(255, 255, 255, .7) !important
}

.colors-b .border-link, .colors-b.border-link {
  border-color: rgba(219, 171, 131, .7) !important
}

.colors-b .background-0, .colors-b.background-0 {
  background-color: transparent !important
}

.colors-b .background-5, .colors-b.background-5 {
  background-color: rgba(71, 67, 64, .05) !important
}

.colors-b .background-10, .colors-b.background-10 {
  background-color: rgba(71, 67, 64, .1) !important
}

.colors-b .background-15, .colors-b.background-15 {
  background-color: rgba(71, 67, 64, .15) !important
}

.colors-b .background-20, .colors-b.background-20 {
  background-color: rgba(71, 67, 64, .2) !important
}

.colors-b .background-25, .colors-b.background-25 {
  background-color: rgba(71, 67, 64, .25) !important
}

.colors-b .background-30, .colors-b.background-30 {
  background-color: rgba(71, 67, 64, .3) !important
}

.colors-b .background-35, .colors-b.background-35 {
  background-color: rgba(71, 67, 64, .35) !important
}

.colors-b .background-40, .colors-b.background-40 {
  background-color: rgba(71, 67, 64, .4) !important
}

.colors-b .background-45, .colors-b.background-45 {
  background-color: rgba(71, 67, 64, .45) !important
}

.colors-b .background-50, .colors-b.background-50 {
  background-color: rgba(71, 67, 64, .5) !important
}

.colors-b .background-55, .colors-b.background-55 {
  background-color: rgba(71, 67, 64, .55) !important
}

.colors-b .background-60, .colors-b.background-60 {
  background-color: rgba(71, 67, 64, .6) !important
}

.colors-b .background-65, .colors-b.background-65 {
  background-color: rgba(71, 67, 64, .65) !important
}

.colors-b .background-70, .colors-b.background-70 {
  background-color: rgba(71, 67, 64, .7) !important
}

.colors-b .background-75, .colors-b.background-75 {
  background-color: rgba(71, 67, 64, .75) !important
}

.colors-b .background-80, .colors-b.background-80 {
  background-color: rgba(71, 67, 64, .8) !important
}

.colors-b .background-85, .colors-b.background-85 {
  background-color: rgba(71, 67, 64, .85) !important
}

.colors-b .background-90, .colors-b.background-90 {
  background-color: rgba(71, 67, 64, .9) !important
}

.colors-b .background-95, .colors-b.background-95 {
  background-color: rgba(71, 67, 64, .95) !important
}

.colors-b .background-100, .colors-b.background-100 {
  background-color: #474340 !important
}

.colors-b h2::after {
  background-color: rgba(255, 255, 255, .4)
}

.colors-b h3 a {
  color: #fff
}

.colors-b h3 a:hover {
  color: rgba(255, 255, 255, .75)
}

.colors-b h3 {
  border-color: rgba(219, 171, 131, .5)
}

.colors-b input, .colors-b textarea {
  background-color: transparent;
  border-color: rgba(255, 255, 255, .6);
  outline-color: rgba(239, 239, 239, 0)
}

.colors-b input:focus, .colors-b textarea:focus {
  outline-color: rgba(239, 239, 239, .37)
}

#top-nav.colors-b {
  border-color: rgba(219, 171, 131, .16);
  color: #fff
}

#top-nav.colors-b li a {
  color: #fff
}

#top-nav.colors-b li a:hover {
  color: inherit
}

#top-nav.colors-b .navbar-brand, #top-nav.colors-b .navbar-brand:hover {
  color: #fff
}

#top-nav.colors-b .nav > li ul {
  background-color: rgba(71, 67, 64, .82)
}

#top-nav.colors-b .nav > li ul a {
  color: #fff
}

#top-nav.colors-b .nav > li ul a.active {
  color: #7a8288
}

#top-nav.colors-b .nav > li ul a:hover {
  background-color: transparent
}

#top-nav.colors-b li.open > a {
  background-color: rgba(71, 67, 64, .46806867)
}

#top-nav.colors-b .navbar-nav a.active {
  color: #7a8288
}

#top-nav.colors-b .navbar-toggle {
  background-color: transparent !important;
  border-color: #fff
}

#top-nav.colors-b .navbar-toggle .icon-bar {
  background-color: #fff
}

.colors-b.page-border {
  border-color: rgba(219, 171, 131, .16)
}

.colors-b.page-border a, .ext-nav a.colors-b .side-title {
  color: #fff
}

.ext-nav a.colors-b .side-label {
  color: #7a8288
}

.ext-nav a.colors-b:hover .side-title {
  color: #fff
}

.ext-nav a.colors-b:hover .side-label {
  color: #7a8288
}

.colors-b .menu-toggle span, .colors-b .menu-toggle span:after, .colors-b .menu-toggle span:before {
  background-color: #fff
}

.colors-b .menu-toggle.navbar-toggle span, .colors-b .menu-toggle.navbar-toggle span:after, .colors-b .menu-toggle.navbar-toggle span:before {
  background-color: #7a8288
}

.colors-b .menu-toggle.show span {
  background-color: transparent
}

.colors-b .carousel .slick-next, .colors-b .carousel .slick-prev, .colors-b .slider .slick-next, .colors-b .slider .slick-prev {
  background-color: #f9f9f9;
  background-color: rgba(255, 255, 255, .8)
}

.colors-b .carousel .slick-next:before, .colors-b .carousel .slick-prev:before, .colors-b .slider .slick-next:before, .colors-b .slider .slick-prev:before {
  color: #222;
  background-color: transparent
}

.colors-b .carousel .slick-next:hover, .colors-b .carousel .slick-prev:hover, .colors-b .slider .slick-next:hover, .colors-b .slider .slick-prev:hover {
  background-color: #efefef;
  background-color: rgba(255, 255, 255, .6)
}

.colors-b .carousel .slick-dots li button:before, .colors-b .slider .slick-dots li button:before {
  color: #efefef;
  color: rgba(239, 239, 239, .4);
  opacity: 1
}

.colors-b .carousel .slick-dots li.slick-active button:before, .colors-b .slider .slick-dots li.slick-active button:before {
  color: #7a8288;
  color: rgba(239, 239, 239, .7);
  opacity: 1
}

.colors-b .carousel .slick-slide {
  background-color: rgba(71, 67, 64, .82);
  -webkit-box-shadow: 0 0 8px #fff;
  -moz-box-shadow: 0 0 8px #fff;
  box-shadow: 0 0 8px #fff
}

.colors-b .gallery .filter .nav li a {
  color: #fff;
  background-color: transparent
}

.colors-b .gallery .filter .nav li a.active {
  color: #7a8288
}

.colors-b.gallery-overlay .field {
  color: #fff
}

.colors-b .cross:after, .colors-b .cross:before {
  background-color: #fff
}

.colors-b.circle {
  border-color: rgba(71, 67, 64, .82);
  color: #fff
}

.colors-b.circle:after {
  background-color: transparent;
  border-color: rgba(71, 67, 64, .82)
}

.colors-b.circle:hover {
  background-color: transparent;
  color: rgba(71, 67, 64, .82)
}

.colors-b.circle:hover:after {
  border-color: rgba(71, 67, 64, .82)
}

.colors-b .process .process-row .process-step .process-box {
  border-color: rgba(239, 239, 239, .7)
}

.colors-b .process .process-row .process-step .process-box:before {
  background-color: #474340
}

#dot-scroll.colors-b li a span {
  background-color: #fff
}

#dot-scroll.colors-b li a.active span, #dot-scroll.colors-b li a:hover.active span {
  background-color: rgba(71, 67, 64, .82)
}

#dot-scroll.colors-b li a.active span, #dot-scroll.colors-b li a.target span, #dot-scroll.colors-b li a.target.active span, #dot-scroll.colors-b li a:hover.active span, #dot-scroll.colors-b li a:hover.target span, #dot-scroll.colors-b li a:hover.target.active span {
  border-color: #fff
}

.colors-b.skew.skew-bottom-left {
  border-bottom-color: transparent;
  border-left-color: #474340;
  background-color: transparent !important
}

.colors-b.skew.skew-bottom-right {
  border-bottom-color: #474340;
  border-left-color: transparent;
  background-color: transparent !important
}

.colors-b.skew.skew-top-right {
  border-top-color: transparent;
  border-right-color: #474340;
  background-color: transparent !important
}

.colors-b.skew.skew-top-left {
  border-top-color: #474340;
  border-right-color: transparent;
  background-color: transparent !important
}

.colors-b .loader {
  border-color: rgba(239, 239, 239, .1)
}

.colors-b .loader li:nth-child(1), .colors-b .loader li:nth-child(2), .colors-b .loader li:nth-child(3), .colors-b .loader li:nth-child(4) {
  background: #efefef
}

.colors-b #comments, .colors-b #comments .comment-list .comment, .colors-b .avatar, .colors-b ul.tags-list li {
  border-color: rgba(255, 255, 255, .3) !important
}

.colors-b .post .post-meta, .colors-b .post .post-meta a {
  color: #fff
}

.colors-b .post .post-meta a:hover {
  color: #7a8288
}

.colors-b .post .post-meta .post-month {
  border-color: rgba(255, 255, 255, .08) !important
}

.colors-b .post h3 a:hover {
  color: #fff
}

.colors-b .post .post-tags {
  color: rgba(239, 239, 239, .85)
}

.colors-b .post .post-month {
  color: #7a8288
}

.colors-b .sidebar .title {
  color: #fff
}

.colors-b .pagination .active a {
  background-color: rgba(255, 255, 255, .85);
  color: #474340
}

.colors-b .pagination .active a:hover {
  color: #474340
}

.colors-b .pagination a {
  background-color: transparent;
  border-color: rgba(255, 255, 255, .4) !important;
  color: #fff
}

.colors-b .pagination a:hover {
  color: #7a8288
}

.colors-b .navbar-collapse {
  max-height: none
}

.colors-b .caption, .colors-b .header-details, .colors-b .subtitle, .colors-b .title {
  color: #fff
}

.colors-b .header-details.highlight, .colors-b .slogan, .colors-b .subtitle.highlight, .colors-b .title.highlight {
  color: #7a8288
}

.colors-b .underline-dashed, .colors-b .underline-dotted {
  border-color: rgba(219, 171, 131, .7)
}

.colors-b .underline, .colors-b .underline-highlight, .colors-b .underline-link {
  border-color: rgba(219, 171, 131, .5)
}

.colors-b .underline-heading {
  border-color: rgba(255, 255, 255, .5)
}

.colors-b .underline-text {
  border-color: rgba(239, 239, 239, .5)
}

.colors-b .top-line:before {
  background-color: rgba(219, 171, 131, .7)
}

.colors-b .bottom-line:after, .colors-b .short-underline:after {
  background-color: #7a8288
}

.colors-b .stroke-highlight {
  text-shadow: -1px -1px 0 #7a8288, 1px -1px 0 #7a8288, -1px 1px 0 #7a8288, 1px 1px 0 #7a8288
}

.colors-b .stroke-heading {
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff
}

.colors-b .stroke-foreground {
  text-shadow: -1px -1px 0 #efefef, 1px -1px 0 #efefef, -1px 1px 0 #efefef, 1px 1px 0 #efefef
}

.colors-b .stroke-background {
  text-shadow: -1px -1px 0 rgba(71, 67, 64, .82), 1px -1px 0 rgba(71, 67, 64, .82), -1px 1px 0 rgba(71, 67, 64, .82), 1px 1px 0 rgba(71, 67, 64, .82)
}

.colors-b .label-border {
  border-color: rgba(219, 171, 131, .7);
  outline-color: #7a8288
}

.colors-b .bottom-menu, .colors-b .bottom-menu a, .colors-b .bottom-menu i {
  color: #efefef !important
}

.colors-b .bottom-menu a:hover {
  background-color: transparent;
  color: #7a8288
}

.colors-b .bottom-menu a:hover i {
  color: #7a8288
}

footer.colors-b a i:hover {
  color: #efefef !important
}

.colors-b .main-icon, .colors-b .main-icon:hover {
  color: #fff
}

.colors-b.content.border-bottom, .colors-b.content.border-top, footer.colors-b.border-bottom, footer.colors-b.border-top {
  border-color: rgba(219, 171, 131, .8)
}

.colors-b input.button, .colors-b.credits-overlay .content-container > ul > li, .colors-b.credits-overlay .content-container > ul > li li {
  color: #7a8288
}

.colors-b input.button:hover {
  color: #fff
}

.colors-b iframe.example, .colors-b pre.example {
  border-color: rgba(239, 239, 239, .3) !important
}

.colors-b.help-toc a, .colors-b.help-toc li, .colors-b.help-toc ul {
  color: #fff
}

.colors-b .help-toc-title {
  border-color: rgba(255, 255, 255, .2)
}

.text-b {
  color: #efefef
}

.background-0-b {
  background-color: transparent !important
}

.background-5-b {
  background-color: rgba(71, 67, 64, .05) !important
}

.background-10-b {
  background-color: rgba(71, 67, 64, .1) !important
}

.background-15-b {
  background-color: rgba(71, 67, 64, .15) !important
}

.background-20-b {
  background-color: rgba(71, 67, 64, .2) !important
}

.background-25-b {
  background-color: rgba(71, 67, 64, .25) !important
}

.background-30-b {
  background-color: rgba(71, 67, 64, .3) !important
}

.background-35-b {
  background-color: rgba(71, 67, 64, .35) !important
}

.background-40-b {
  background-color: rgba(71, 67, 64, .4) !important
}

.background-45-b {
  background-color: rgba(71, 67, 64, .45) !important
}

.background-50-b {
  background-color: rgba(71, 67, 64, .5) !important
}

.background-55-b {
  background-color: rgba(71, 67, 64, .55) !important
}

.background-60-b {
  background-color: rgba(71, 67, 64, .6) !important
}

.background-65-b {
  background-color: rgba(71, 67, 64, .65) !important
}

.background-70-b {
  background-color: rgba(71, 67, 64, .7) !important
}

.background-75-b {
  background-color: rgba(71, 67, 64, .75) !important
}

.background-80-b {
  background-color: rgba(71, 67, 64, .8) !important
}

.background-85-b {
  background-color: rgba(71, 67, 64, .85) !important
}

.background-90-b {
  background-color: rgba(71, 67, 64, .9) !important
}

.background-95-b {
  background-color: rgba(71, 67, 64, .95) !important
}

.background-100-b {
  background-color: #474340 !important
}

.background-b {
  background-color: rgba(71, 67, 64, .82) !important
}

.background-lite-b {
  background-color: rgba(71, 67, 64, .46806867) !important
}

.background-lite-lite-b {
  background-color: rgba(71, 67, 64, .23428571) !important
}

.background-hard-b {
  background-color: rgba(71, 67, 64, .94) !important
}

.background-hard-hard-b {
  background-color: rgba(71, 67, 64, .964) !important
}

.border-background-b {
  border-color: rgba(71, 67, 64, .7) !important
}

.border-text-b {
  border-color: rgba(239, 239, 239, .7) !important
}

.border-highlight-b {
  border-color: rgba(219, 171, 131, .7) !important
}

.border-heading-b {
  border-color: rgba(255, 255, 255, .7) !important
}

.border-link-b {
  border-color: rgba(219, 171, 131, .7) !important
}

.heading-b {
  color: #fff !important
}

.text-b {
  color: #efefef !important
}

.text-background-b {
  color: #474340 !important
}

.background-highlight-b {
  background-color: #7a8288 !important
}

.background-heading-b {
  background-color: #fff !important
}

.background-text-b {
  background-color: #efefef !important
}

.background-link-b {
  background-color: #7a8288 !important
}

.highlight-b, .link-b {
  color: #7a8288 !important
}

.colors-c {
  color: #efefef;
  background-color: rgba(33, 30, 28, .82)
}

.colors-c .bg-color {
  color: #211e1c
}

.colors-c .out {
  opacity: .82
}

.colors-c .heading, .colors-c.heading {
  color: #fff
}

.colors-c .highlight, .colors-c .link, .colors-c.highlight, .colors-c.link {
  color: #7a8288
}

.colors-c .background, .colors-c.background {
  background-color: rgba(33, 30, 28, .82)
}

.colors-c .background-solid, .colors-c.background-solid {
  background-color: #211e1c
}

.colors-c .background-hard, .colors-c.background-hard {
  background-color: rgba(33, 30, 28, .94)
}

.colors-c .background-lite, .colors-c.background-lite {
  background-color: rgba(33, 30, 28, .46806867)
}

.colors-c .col-icon, .colors-c .col-left-marginalia, .colors-c col-right-marginalia, .colors-c h1, .colors-c h2, .colors-c h3, .colors-c h4, .colors-c h5, .colors-c h6 {
  color: #fff
}

.colors-c a, a.colors-c {
  color: #7a8288
}

.colors-c a:hover, a.colors-c:hover {
  color: rgba(219, 171, 131, .75)
}

.colors-c .line {
  border-color: #efefef;
  border-color: rgba(239, 239, 239, .3)
}

.colors-c .line:hover {
  background-color: rgba(33, 30, 28, .46806867)
}

.colors-c .help-block li {
  color: #7a8288
}

.colors-c .grid-example > .row > div:nth-child(1n) {
  color: #fff;
  background-color: rgba(239, 239, 239, .1)
}

.colors-c .grid-example > .row > div:nth-child(2n) {
  color: #fff;
  background-color: rgba(239, 239, 239, .3)
}

.colors-c ::-webkit-input-placeholder {
  color: rgba(239, 239, 239, .6)
}

.colors-c :-moz-placeholder {
  color: rgba(239, 239, 239, .6);
  opacity: 1
}

.colors-c ::-moz-placeholder {
  color: rgba(239, 239, 239, .6);
  opacity: 1
}

.colors-c :-ms-input-placeholder {
  color: rgba(239, 239, 239, .6)
}

.colors-c .border, .colors-c .border-bottom, .colors-c .border-left, .colors-c .border-right, .colors-c .border-top, .colors-c.border, .colors-c.border-bottom, .colors-c.border-left, .colors-c.border-right, .colors-c.border-top {
  border-color: rgba(255, 255, 255, .3) !important
}

.colors-c .border-hard, .colors-c.border-hard {
  border-color: rgba(255, 255, 255, .7) !important
}

.colors-c .border-lite, .colors-c.border-lite {
  border-color: rgba(255, 255, 255, .08) !important
}

.colors-c .text {
  color: #efefef !important
}

.colors-c .text-background, .colors-c.text-background {
  color: #211e1c !important
}

.colors-c .background-highlight, .colors-c.background-highlight {
  background-color: #7a8288 !important
}

.colors-c .background-heading, .colors-c.background-heading {
  background-color: #fff !important
}

.colors-c .background-text, .colors-c.background-text {
  background-color: #efefef !important
}

.colors-c .background-link, .colors-c.background-link {
  background-color: #7a8288 !important
}

.colors-c .border-text, .colors-c.border-text {
  border-color: rgba(239, 239, 239, .7) !important
}

.colors-c .border-highlight, .colors-c.border-highlight {
  border-color: rgba(219, 171, 131, .7) !important
}

.colors-c .border-heading, .colors-c.border-heading {
  border-color: rgba(255, 255, 255, .7) !important
}

.colors-c .border-link, .colors-c.border-link {
  border-color: rgba(219, 171, 131, .7) !important
}

.colors-c .background-0, .colors-c.background-0 {
  background-color: transparent !important
}

.colors-c .background-5, .colors-c.background-5 {
  background-color: rgba(33, 30, 28, .05) !important
}

.colors-c .background-10, .colors-c.background-10 {
  background-color: rgba(33, 30, 28, .1) !important
}

.colors-c .background-15, .colors-c.background-15 {
  background-color: rgba(33, 30, 28, .15) !important
}

.colors-c .background-20, .colors-c.background-20 {
  background-color: rgba(33, 30, 28, .2) !important
}

.colors-c .background-25, .colors-c.background-25 {
  background-color: rgba(33, 30, 28, .25) !important
}

.colors-c .background-30, .colors-c.background-30 {
  background-color: rgba(33, 30, 28, .3) !important
}

.colors-c .background-35, .colors-c.background-35 {
  background-color: rgba(33, 30, 28, .35) !important
}

.colors-c .background-40, .colors-c.background-40 {
  background-color: rgba(33, 30, 28, .4) !important
}

.colors-c .background-45, .colors-c.background-45 {
  background-color: rgba(33, 30, 28, .45) !important
}

.colors-c .background-50, .colors-c.background-50 {
  background-color: rgba(33, 30, 28, .5) !important
}

.colors-c .background-55, .colors-c.background-55 {
  background-color: rgba(33, 30, 28, .55) !important
}

.colors-c .background-60, .colors-c.background-60 {
  background-color: rgba(33, 30, 28, .6) !important
}

.colors-c .background-65, .colors-c.background-65 {
  background-color: rgba(33, 30, 28, .65) !important
}

.colors-c .background-70, .colors-c.background-70 {
  background-color: rgba(33, 30, 28, .7) !important
}

.colors-c .background-75, .colors-c.background-75 {
  background-color: rgba(33, 30, 28, .75) !important
}

.colors-c .background-80, .colors-c.background-80 {
  background-color: rgba(33, 30, 28, .8) !important
}

.colors-c .background-85, .colors-c.background-85 {
  background-color: rgba(33, 30, 28, .85) !important
}

.colors-c .background-90, .colors-c.background-90 {
  background-color: rgba(33, 30, 28, .9) !important
}

.colors-c .background-95, .colors-c.background-95 {
  background-color: rgba(33, 30, 28, .95) !important
}

.colors-c .background-100, .colors-c.background-100 {
  background-color: #211e1c !important
}

.colors-c h2::after {
  background-color: rgba(255, 255, 255, .4)
}

.colors-c h3 a {
  color: #fff
}

.colors-c h3 a:hover {
  color: rgba(255, 255, 255, .75)
}

.colors-c h3 {
  border-color: rgba(219, 171, 131, .5)
}

.colors-c input, .colors-c textarea {
  background-color: transparent;
  border-color: rgba(255, 255, 255, .6);
  outline-color: rgba(239, 239, 239, 0)
}

.colors-c input:focus, .colors-c textarea:focus {
  outline-color: rgba(239, 239, 239, .37)
}

#top-nav.colors-c {
  border-color: rgba(219, 171, 131, .16);
  color: #fff
}

#top-nav.colors-c li a {
  color: #fff
}

#top-nav.colors-c li a:hover {
  color: inherit
}

#top-nav.colors-c .navbar-brand, #top-nav.colors-c .navbar-brand:hover {
  color: #fff
}

#top-nav.colors-c .nav > li ul {
  background-color: rgba(33, 30, 28, .82)
}

#top-nav.colors-c .nav > li ul a {
  color: #fff
}

#top-nav.colors-c .nav > li ul a.active {
  color: #7a8288
}

#top-nav.colors-c .nav > li ul a:hover {
  background-color: transparent
}

#top-nav.colors-c li.open > a {
  background-color: rgba(33, 30, 28, .46806867)
}

#top-nav.colors-c .navbar-nav a.active {
  color: #7a8288
}

#top-nav.colors-c .navbar-toggle {
  background-color: transparent !important;
  border-color: #fff
}

#top-nav.colors-c .navbar-toggle .icon-bar {
  background-color: #fff
}

.colors-c.page-border {
  border-color: rgba(219, 171, 131, .16)
}

.colors-c.page-border a, .ext-nav a.colors-c .side-title {
  color: #fff
}

.ext-nav a.colors-c .side-label {
  color: #7a8288
}

.ext-nav a.colors-c:hover .side-title {
  color: #fff
}

.ext-nav a.colors-c:hover .side-label {
  color: #7a8288
}

.colors-c .menu-toggle span, .colors-c .menu-toggle span:after, .colors-c .menu-toggle span:before {
  background-color: #fff
}

.colors-c .menu-toggle.navbar-toggle span, .colors-c .menu-toggle.navbar-toggle span:after, .colors-c .menu-toggle.navbar-toggle span:before {
  background-color: #7a8288
}

.colors-c .menu-toggle.show span {
  background-color: transparent
}

.colors-c .carousel .slick-next, .colors-c .carousel .slick-prev, .colors-c .slider .slick-next, .colors-c .slider .slick-prev {
  background-color: #f9f9f9;
  background-color: rgba(255, 255, 255, .8)
}

.colors-c .carousel .slick-next:before, .colors-c .carousel .slick-prev:before, .colors-c .slider .slick-next:before, .colors-c .slider .slick-prev:before {
  color: #222;
  background-color: transparent
}

.colors-c .carousel .slick-next:hover, .colors-c .carousel .slick-prev:hover, .colors-c .slider .slick-next:hover, .colors-c .slider .slick-prev:hover {
  background-color: #efefef;
  background-color: rgba(255, 255, 255, .6)
}

.colors-c .carousel .slick-dots li button:before, .colors-c .slider .slick-dots li button:before {
  color: #efefef;
  color: rgba(239, 239, 239, .4);
  opacity: 1
}

.colors-c .carousel .slick-dots li.slick-active button:before, .colors-c .slider .slick-dots li.slick-active button:before {
  color: #7a8288;
  color: rgba(239, 239, 239, .7);
  opacity: 1
}

.colors-c .carousel .slick-slide {
  background-color: rgba(33, 30, 28, .82);
  -webkit-box-shadow: 0 0 8px #fff;
  -moz-box-shadow: 0 0 8px #fff;
  box-shadow: 0 0 8px #fff
}

.colors-c .gallery .filter .nav li a {
  color: #fff;
  background-color: transparent
}

.colors-c .gallery .filter .nav li a.active {
  color: #7a8288
}

.colors-c.gallery-overlay .field {
  color: #fff
}

.colors-c .cross:after, .colors-c .cross:before {
  background-color: #fff
}

.colors-c.circle {
  border-color: rgba(33, 30, 28, .82);
  color: #fff
}

.colors-c.circle:after {
  background-color: transparent;
  border-color: rgba(33, 30, 28, .82)
}

.colors-c.circle:hover {
  background-color: transparent;
  color: rgba(33, 30, 28, .82)
}

.colors-c.circle:hover:after {
  border-color: rgba(33, 30, 28, .82)
}

.colors-c .process .process-row .process-step .process-box {
  border-color: rgba(239, 239, 239, .7)
}

.colors-c .process .process-row .process-step .process-box:before {
  background-color: #211e1c
}

#dot-scroll.colors-c li a span {
  background-color: #fff
}

#dot-scroll.colors-c li a.active span, #dot-scroll.colors-c li a:hover.active span {
  background-color: rgba(33, 30, 28, .82)
}

#dot-scroll.colors-c li a.active span, #dot-scroll.colors-c li a.target span, #dot-scroll.colors-c li a.target.active span, #dot-scroll.colors-c li a:hover.active span, #dot-scroll.colors-c li a:hover.target span, #dot-scroll.colors-c li a:hover.target.active span {
  border-color: #fff
}

.colors-c.skew.skew-bottom-left {
  border-bottom-color: transparent;
  border-left-color: #211e1c;
  background-color: transparent !important
}

.colors-c.skew.skew-bottom-right {
  border-bottom-color: #211e1c;
  border-left-color: transparent;
  background-color: transparent !important
}

.colors-c.skew.skew-top-right {
  border-top-color: transparent;
  border-right-color: #211e1c;
  background-color: transparent !important
}

.colors-c.skew.skew-top-left {
  border-top-color: #211e1c;
  border-right-color: transparent;
  background-color: transparent !important
}

.colors-c .loader {
  border-color: rgba(239, 239, 239, .1)
}

.colors-c .loader li:nth-child(1), .colors-c .loader li:nth-child(2), .colors-c .loader li:nth-child(3), .colors-c .loader li:nth-child(4) {
  background: #efefef
}

.colors-c #comments, .colors-c #comments .comment-list .comment, .colors-c .avatar, .colors-c ul.tags-list li {
  border-color: rgba(255, 255, 255, .3) !important
}

.colors-c .post .post-meta, .colors-c .post .post-meta a {
  color: #fff
}

.colors-c .post .post-meta a:hover {
  color: #7a8288
}

.colors-c .post .post-meta .post-month {
  border-color: rgba(255, 255, 255, .08) !important
}

.colors-c .post h3 a:hover {
  color: #fff
}

.colors-c .post .post-tags {
  color: rgba(239, 239, 239, .85)
}

.colors-c .post .post-month {
  color: #7a8288
}

.colors-c .sidebar .title {
  color: #fff
}

.colors-c .pagination .active a {
  background-color: rgba(255, 255, 255, .85);
  color: #211e1c
}

.colors-c .pagination .active a:hover {
  color: #211e1c
}

.colors-c .pagination a {
  background-color: transparent;
  border-color: rgba(255, 255, 255, .4) !important;
  color: #fff
}

.colors-c .pagination a:hover {
  color: #7a8288
}

.colors-c .navbar-collapse {
  max-height: none
}

.colors-c .caption, .colors-c .header-details, .colors-c .subtitle, .colors-c .title {
  color: #fff
}

.colors-c .header-details.highlight, .colors-c .slogan, .colors-c .subtitle.highlight, .colors-c .title.highlight {
  color: #7a8288
}

.colors-c .underline-dashed, .colors-c .underline-dotted {
  border-color: rgba(219, 171, 131, .7)
}

.colors-c .underline, .colors-c .underline-highlight, .colors-c .underline-link {
  border-color: rgba(219, 171, 131, .5)
}

.colors-c .underline-heading {
  border-color: rgba(255, 255, 255, .5)
}

.colors-c .underline-text {
  border-color: rgba(239, 239, 239, .5)
}

.colors-c .top-line:before {
  background-color: rgba(219, 171, 131, .7)
}

.colors-c .bottom-line:after, .colors-c .short-underline:after {
  background-color: #7a8288
}

.colors-c .stroke-highlight {
  text-shadow: -1px -1px 0 #7a8288, 1px -1px 0 #7a8288, -1px 1px 0 #7a8288, 1px 1px 0 #7a8288
}

.colors-c .stroke-heading {
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff
}

.colors-c .stroke-foreground {
  text-shadow: -1px -1px 0 #efefef, 1px -1px 0 #efefef, -1px 1px 0 #efefef, 1px 1px 0 #efefef
}

.colors-c .stroke-background {
  text-shadow: -1px -1px 0 rgba(33, 30, 28, .82), 1px -1px 0 rgba(33, 30, 28, .82), -1px 1px 0 rgba(33, 30, 28, .82), 1px 1px 0 rgba(33, 30, 28, .82)
}

.colors-c .label-border {
  border-color: rgba(219, 171, 131, .7);
  outline-color: #7a8288
}

.colors-c .bottom-menu, .colors-c .bottom-menu a, .colors-c .bottom-menu i {
  color: #efefef !important
}

.colors-c .bottom-menu a:hover {
  background-color: transparent;
  color: #7a8288
}

.colors-c .bottom-menu a:hover i {
  color: #7a8288
}

footer.colors-c a i:hover {
  color: #efefef !important
}

.colors-c .main-icon, .colors-c .main-icon:hover {
  color: #fff
}

.colors-c.content.border-bottom, .colors-c.content.border-top, footer.colors-c.border-bottom, footer.colors-c.border-top {
  border-color: rgba(219, 171, 131, .8)
}

.colors-c input.button, .colors-c.credits-overlay .content-container > ul > li, .colors-c.credits-overlay .content-container > ul > li li {
  color: #7a8288
}

.colors-c input.button:hover {
  color: #fff
}

.colors-c iframe.example, .colors-c pre.example {
  border-color: rgba(239, 239, 239, .3) !important
}

.colors-c.help-toc a, .colors-c.help-toc li, .colors-c.help-toc ul {
  color: #fff
}

.colors-c .help-toc-title {
  border-color: rgba(255, 255, 255, .2)
}

.text-c {
  color: #efefef
}

.background-0-c {
  background-color: transparent !important
}

.background-5-c {
  background-color: rgba(33, 30, 28, .05) !important
}

.background-10-c {
  background-color: rgba(33, 30, 28, .1) !important
}

.background-15-c {
  background-color: rgba(33, 30, 28, .15) !important
}

.background-20-c {
  background-color: rgba(33, 30, 28, .2) !important
}

.background-25-c {
  background-color: rgba(33, 30, 28, .25) !important
}

.background-30-c {
  background-color: rgba(33, 30, 28, .3) !important
}

.background-35-c {
  background-color: rgba(33, 30, 28, .35) !important
}

.background-40-c {
  background-color: rgba(33, 30, 28, .4) !important
}

.background-45-c {
  background-color: rgba(33, 30, 28, .45) !important
}

.background-50-c {
  background-color: rgba(33, 30, 28, .5) !important
}

.background-55-c {
  background-color: rgba(33, 30, 28, .55) !important
}

.background-60-c {
  background-color: rgba(33, 30, 28, .6) !important
}

.background-65-c {
  background-color: rgba(33, 30, 28, .65) !important
}

.background-70-c {
  background-color: rgba(33, 30, 28, .7) !important
}

.background-75-c {
  background-color: rgba(33, 30, 28, .75) !important
}

.background-80-c {
  background-color: rgba(33, 30, 28, .8) !important
}

.background-85-c {
  background-color: rgba(33, 30, 28, .85) !important
}

.background-90-c {
  background-color: rgba(33, 30, 28, .9) !important
}

.background-95-c {
  background-color: rgba(33, 30, 28, .95) !important
}

.background-100-c {
  background-color: #211e1c !important
}

.background-c {
  background-color: rgba(33, 30, 28, .82) !important
}

.background-lite-c {
  background-color: rgba(33, 30, 28, .46806867) !important
}

.background-lite-lite-c {
  background-color: rgba(33, 30, 28, .23428571) !important
}

.background-hard-c {
  background-color: rgba(33, 30, 28, .94) !important
}

.background-hard-hard-c {
  background-color: rgba(33, 30, 28, .964) !important
}

.border-background-c {
  border-color: rgba(33, 30, 28, .7) !important
}

.border-text-c {
  border-color: rgba(239, 239, 239, .7) !important
}

.border-highlight-c {
  border-color: rgba(219, 171, 131, .7) !important
}

.border-heading-c {
  border-color: rgba(255, 255, 255, .7) !important
}

.border-link-c {
  border-color: rgba(219, 171, 131, .7) !important
}

.heading-c {
  color: #fff !important
}

.text-c {
  color: #efefef !important
}

.text-background-c {
  color: #211e1c !important
}

.background-highlight-c {
  background-color: #7a8288 !important
}

.background-heading-c {
  background-color: #fff !important
}

.background-text-c {
  background-color: #efefef !important
}

.background-link-c {
  background-color: #7a8288 !important
}

.highlight-c, .link-c {
  color: #7a8288 !important
}

.colors-d {
  color: #efefef;
  background-color: rgba(77, 56, 36, .82)
}

.colors-d .bg-color {
  color: #4d3824
}

.colors-d .out {
  opacity: .82
}

.colors-d .heading, .colors-d.heading {
  color: #fff
}

.colors-d .highlight, .colors-d .link, .colors-d.highlight, .colors-d.link {
  color: #ffe0cc
}

.colors-d .background, .colors-d.background {
  background-color: rgba(77, 56, 36, .82)
}

.colors-d .background-solid, .colors-d.background-solid {
  background-color: #4d3824
}

.colors-d .background-hard, .colors-d.background-hard {
  background-color: rgba(77, 56, 36, .94)
}

.colors-d .background-lite, .colors-d.background-lite {
  background-color: rgba(77, 56, 36, .46806867)
}

.colors-d .col-icon, .colors-d .col-left-marginalia, .colors-d col-right-marginalia, .colors-d h1, .colors-d h2, .colors-d h3, .colors-d h4, .colors-d h5, .colors-d h6 {
  color: #fff
}

.colors-d a, a.colors-d {
  color: #ffe0cc
}

.colors-d a:hover, a.colors-d:hover {
  color: rgba(255, 224, 204, .75)
}

.colors-d .line {
  border-color: #efefef;
  border-color: rgba(239, 239, 239, .3)
}

.colors-d .line:hover {
  background-color: rgba(77, 56, 36, .46806867)
}

.colors-d .help-block li {
  color: #ffe0cc
}

.colors-d .grid-example > .row > div:nth-child(1n) {
  color: #fff;
  background-color: rgba(239, 239, 239, .1)
}

.colors-d .grid-example > .row > div:nth-child(2n) {
  color: #fff;
  background-color: rgba(239, 239, 239, .3)
}

.colors-d ::-webkit-input-placeholder {
  color: rgba(239, 239, 239, .6)
}

.colors-d :-moz-placeholder {
  color: rgba(239, 239, 239, .6);
  opacity: 1
}

.colors-d ::-moz-placeholder {
  color: rgba(239, 239, 239, .6);
  opacity: 1
}

.colors-d :-ms-input-placeholder {
  color: rgba(239, 239, 239, .6)
}

.colors-d .border, .colors-d .border-bottom, .colors-d .border-left, .colors-d .border-right, .colors-d .border-top, .colors-d.border, .colors-d.border-bottom, .colors-d.border-left, .colors-d.border-right, .colors-d.border-top {
  border-color: rgba(255, 255, 255, .3) !important
}

.colors-d .border-hard, .colors-d.border-hard {
  border-color: rgba(255, 255, 255, .7) !important
}

.colors-d .border-lite, .colors-d.border-lite {
  border-color: rgba(255, 255, 255, .08) !important
}

.colors-d .text {
  color: #efefef !important
}

.colors-d .text-background, .colors-d.text-background {
  color: #4d3824 !important
}

.colors-d .background-highlight, .colors-d.background-highlight {
  background-color: #ffe0cc !important
}

.colors-d .background-heading, .colors-d.background-heading {
  background-color: #fff !important
}

.colors-d .background-text, .colors-d.background-text {
  background-color: #efefef !important
}

.colors-d .background-link, .colors-d.background-link {
  background-color: #ffe0cc !important
}

.colors-d .border-text, .colors-d.border-text {
  border-color: rgba(239, 239, 239, .7) !important
}

.colors-d .border-highlight, .colors-d.border-highlight {
  border-color: rgba(255, 224, 204, .7) !important
}

.colors-d .border-heading, .colors-d.border-heading {
  border-color: rgba(255, 255, 255, .7) !important
}

.colors-d .border-link, .colors-d.border-link {
  border-color: rgba(255, 224, 204, .7) !important
}

.colors-d .background-0, .colors-d.background-0 {
  background-color: transparent !important
}

.colors-d .background-5, .colors-d.background-5 {
  background-color: rgba(77, 56, 36, .05) !important
}

.colors-d .background-10, .colors-d.background-10 {
  background-color: rgba(77, 56, 36, .1) !important
}

.colors-d .background-15, .colors-d.background-15 {
  background-color: rgba(77, 56, 36, .15) !important
}

.colors-d .background-20, .colors-d.background-20 {
  background-color: rgba(77, 56, 36, .2) !important
}

.colors-d .background-25, .colors-d.background-25 {
  background-color: rgba(77, 56, 36, .25) !important
}

.colors-d .background-30, .colors-d.background-30 {
  background-color: rgba(77, 56, 36, .3) !important
}

.colors-d .background-35, .colors-d.background-35 {
  background-color: rgba(77, 56, 36, .35) !important
}

.colors-d .background-40, .colors-d.background-40 {
  background-color: rgba(77, 56, 36, .4) !important
}

.colors-d .background-45, .colors-d.background-45 {
  background-color: rgba(77, 56, 36, .45) !important
}

.colors-d .background-50, .colors-d.background-50 {
  background-color: rgba(77, 56, 36, .5) !important
}

.colors-d .background-55, .colors-d.background-55 {
  background-color: rgba(77, 56, 36, .55) !important
}

.colors-d .background-60, .colors-d.background-60 {
  background-color: rgba(77, 56, 36, .6) !important
}

.colors-d .background-65, .colors-d.background-65 {
  background-color: rgba(77, 56, 36, .65) !important
}

.colors-d .background-70, .colors-d.background-70 {
  background-color: rgba(77, 56, 36, .7) !important
}

.colors-d .background-75, .colors-d.background-75 {
  background-color: rgba(77, 56, 36, .75) !important
}

.colors-d .background-80, .colors-d.background-80 {
  background-color: rgba(77, 56, 36, .8) !important
}

.colors-d .background-85, .colors-d.background-85 {
  background-color: rgba(77, 56, 36, .85) !important
}

.colors-d .background-90, .colors-d.background-90 {
  background-color: rgb(122, 130, 136) !important
}

.colors-d .background-95, .colors-d.background-95 {
  background-color: rgba(77, 56, 36, .95) !important
}

.colors-d .background-100, .colors-d.background-100 {
  background-color: #4d3824 !important
}

.colors-d h2::after {
  background-color: rgba(255, 255, 255, .4)
}

.colors-d h3 a {
  color: #fff
}

.colors-d h3 a:hover {
  color: rgba(255, 255, 255, .75)
}

.colors-d h3 {
  border-color: rgba(255, 224, 204, .5)
}

.colors-d input, .colors-d textarea {
  background-color: transparent;
  border-color: rgba(255, 255, 255, .6);
  outline-color: rgba(239, 239, 239, 0)
}

.colors-d input:focus, .colors-d textarea:focus {
  outline-color: rgba(239, 239, 239, .37)
}

#top-nav.colors-d {
  border-color: rgba(255, 224, 204, .16);
  color: #fff
}

#top-nav.colors-d li a {
  color: #fff
}

#top-nav.colors-d li a:hover {
  color: inherit
}

#top-nav.colors-d .navbar-brand, #top-nav.colors-d .navbar-brand:hover {
  color: #fff
}

#top-nav.colors-d .nav > li ul {
  background-color: rgba(77, 56, 36, .82)
}

#top-nav.colors-d .nav > li ul a {
  color: #fff
}

#top-nav.colors-d .nav > li ul a.active {
  color: #ffe0cc
}

#top-nav.colors-d .nav > li ul a:hover {
  background-color: transparent
}

#top-nav.colors-d li.open > a {
  background-color: rgba(77, 56, 36, .46806867)
}

#top-nav.colors-d .navbar-nav a.active {
  color: #ffe0cc
}

#top-nav.colors-d .navbar-toggle {
  background-color: transparent !important;
  border-color: #fff
}

#top-nav.colors-d .navbar-toggle .icon-bar {
  background-color: #fff
}

.colors-d.page-border {
  border-color: rgba(255, 224, 204, .16)
}

.colors-d.page-border a, .ext-nav a.colors-d .side-title {
  color: #fff
}

.ext-nav a.colors-d .side-label {
  color: #ffe0cc
}

.ext-nav a.colors-d:hover .side-title {
  color: #fff
}

.ext-nav a.colors-d:hover .side-label {
  color: #ffe0cc
}

.colors-d .menu-toggle span, .colors-d .menu-toggle span:after, .colors-d .menu-toggle span:before {
  background-color: #fff
}

.colors-d .menu-toggle.navbar-toggle span, .colors-d .menu-toggle.navbar-toggle span:after, .colors-d .menu-toggle.navbar-toggle span:before {
  background-color: #ffe0cc
}

.colors-d .menu-toggle.show span {
  background-color: transparent
}

.colors-d .carousel .slick-next, .colors-d .carousel .slick-prev, .colors-d .slider .slick-next, .colors-d .slider .slick-prev {
  background-color: #f9f9f9;
  background-color: rgba(255, 255, 255, .8)
}

.colors-d .carousel .slick-next:before, .colors-d .carousel .slick-prev:before, .colors-d .slider .slick-next:before, .colors-d .slider .slick-prev:before {
  color: #222;
  background-color: transparent
}

.colors-d .carousel .slick-next:hover, .colors-d .carousel .slick-prev:hover, .colors-d .slider .slick-next:hover, .colors-d .slider .slick-prev:hover {
  background-color: #efefef;
  background-color: rgba(255, 255, 255, .6)
}

.colors-d .carousel .slick-dots li button:before, .colors-d .slider .slick-dots li button:before {
  color: #efefef;
  color: rgba(239, 239, 239, .4);
  opacity: 1
}

.colors-d .carousel .slick-dots li.slick-active button:before, .colors-d .slider .slick-dots li.slick-active button:before {
  color: #ffe0cc;
  color: rgba(239, 239, 239, .7);
  opacity: 1
}

.colors-d .carousel .slick-slide {
  background-color: rgba(77, 56, 36, .82);
  -webkit-box-shadow: 0 0 8px #fff;
  -moz-box-shadow: 0 0 8px #fff;
  box-shadow: 0 0 8px #fff
}

.colors-d .gallery .filter .nav li a {
  color: #fff;
  background-color: transparent
}

.colors-d .gallery .filter .nav li a.active {
  color: #ffe0cc
}

.colors-d.gallery-overlay .field {
  color: #fff
}

.colors-d .cross:after, .colors-d .cross:before {
  background-color: #fff
}

.colors-d.circle {
  border-color: rgba(77, 56, 36, .82);
  color: #fff
}

.colors-d.circle:after {
  background-color: transparent;
  border-color: rgba(77, 56, 36, .82)
}

.colors-d.circle:hover {
  background-color: transparent;
  color: rgba(77, 56, 36, .82)
}

.colors-d.circle:hover:after {
  border-color: rgba(77, 56, 36, .82)
}

.colors-d .process .process-row .process-step .process-box {
  border-color: rgba(239, 239, 239, .7)
}

.colors-d .process .process-row .process-step .process-box:before {
  background-color: #4d3824
}

#dot-scroll.colors-d li a span {
  background-color: #fff
}

#dot-scroll.colors-d li a.active span, #dot-scroll.colors-d li a:hover.active span {
  background-color: rgba(77, 56, 36, .82)
}

#dot-scroll.colors-d li a.active span, #dot-scroll.colors-d li a.target span, #dot-scroll.colors-d li a.target.active span, #dot-scroll.colors-d li a:hover.active span, #dot-scroll.colors-d li a:hover.target span, #dot-scroll.colors-d li a:hover.target.active span {
  border-color: #fff
}

.colors-d.skew.skew-bottom-left {
  border-bottom-color: transparent;
  border-left-color: #4d3824;
  background-color: transparent !important
}

.colors-d.skew.skew-bottom-right {
  border-bottom-color: #4d3824;
  border-left-color: transparent;
  background-color: transparent !important
}

.colors-d.skew.skew-top-right {
  border-top-color: transparent;
  border-right-color: #4d3824;
  background-color: transparent !important
}

.colors-d.skew.skew-top-left {
  border-top-color: #4d3824;
  border-right-color: transparent;
  background-color: transparent !important
}

.colors-d .loader {
  border-color: rgba(239, 239, 239, .1)
}

.colors-d .loader li:nth-child(1), .colors-d .loader li:nth-child(2), .colors-d .loader li:nth-child(3), .colors-d .loader li:nth-child(4) {
  background: #efefef
}

.colors-d #comments, .colors-d #comments .comment-list .comment, .colors-d .avatar, .colors-d ul.tags-list li {
  border-color: rgba(255, 255, 255, .3) !important
}

.colors-d .post .post-meta, .colors-d .post .post-meta a {
  color: #fff
}

.colors-d .post .post-meta a:hover {
  color: #ffe0cc
}

.colors-d .post .post-meta .post-month {
  border-color: rgba(255, 255, 255, .08) !important
}

.colors-d .post h3 a:hover {
  color: #fff
}

.colors-d .post .post-tags {
  color: rgba(239, 239, 239, .85)
}

.colors-d .post .post-month {
  color: #ffe0cc
}

.colors-d .sidebar .title {
  color: #fff
}

.colors-d .pagination .active a {
  background-color: rgba(255, 255, 255, .85);
  color: #4d3824
}

.colors-d .pagination .active a:hover {
  color: #4d3824
}

.colors-d .pagination a {
  background-color: transparent;
  border-color: rgba(255, 255, 255, .4) !important;
  color: #fff
}

.colors-d .pagination a:hover {
  color: #ffe0cc
}

.colors-d .navbar-collapse {
  max-height: none
}

.colors-d .caption, .colors-d .header-details, .colors-d .subtitle, .colors-d .title {
  color: #fff
}

.colors-d .header-details.highlight, .colors-d .slogan, .colors-d .subtitle.highlight, .colors-d .title.highlight {
  color: #ffe0cc
}

.colors-d .underline-dashed, .colors-d .underline-dotted {
  border-color: rgba(255, 224, 204, .7)
}

.colors-d .underline, .colors-d .underline-highlight, .colors-d .underline-link {
  border-color: rgba(255, 224, 204, .5)
}

.colors-d .underline-heading {
  border-color: rgba(255, 255, 255, .5)
}

.colors-d .underline-text {
  border-color: rgba(239, 239, 239, .5)
}

.colors-d .top-line:before {
  background-color: rgba(255, 224, 204, .7)
}

.colors-d .bottom-line:after, .colors-d .short-underline:after {
  background-color: #ffe0cc
}

.colors-d .stroke-highlight {
  text-shadow: -1px -1px 0 #ffe0cc, 1px -1px 0 #ffe0cc, -1px 1px 0 #ffe0cc, 1px 1px 0 #ffe0cc
}

.colors-d .stroke-heading {
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff
}

.colors-d .stroke-foreground {
  text-shadow: -1px -1px 0 #efefef, 1px -1px 0 #efefef, -1px 1px 0 #efefef, 1px 1px 0 #efefef
}

.colors-d .stroke-background {
  text-shadow: -1px -1px 0 rgba(77, 56, 36, .82), 1px -1px 0 rgba(77, 56, 36, .82), -1px 1px 0 rgba(77, 56, 36, .82), 1px 1px 0 rgba(77, 56, 36, .82)
}

.colors-d .label-border {
  border-color: rgba(255, 224, 204, .7);
  outline-color: #ffe0cc
}

.colors-d .bottom-menu, .colors-d .bottom-menu a, .colors-d .bottom-menu i {
  color: #efefef !important
}

.colors-d .bottom-menu a:hover {
  background-color: transparent;
  color: #ffe0cc
}

.colors-d .bottom-menu a:hover i {
  color: #ffe0cc
}

footer.colors-d a i:hover {
  color: #efefef !important
}

.colors-d .main-icon, .colors-d .main-icon:hover {
  color: #fff
}

.colors-d.content.border-bottom, .colors-d.content.border-top, footer.colors-d.border-bottom, footer.colors-d.border-top {
  border-color: rgba(255, 224, 204, .8)
}

.colors-d input.button, .colors-d.credits-overlay .content-container > ul > li, .colors-d.credits-overlay .content-container > ul > li li {
  color: #ffe0cc
}

.colors-d input.button:hover {
  color: #fff
}

.colors-d iframe.example, .colors-d pre.example {
  border-color: rgba(239, 239, 239, .3) !important
}

.colors-d.help-toc a, .colors-d.help-toc li, .colors-d.help-toc ul {
  color: #fff
}

.colors-d .help-toc-title {
  border-color: rgba(255, 255, 255, .2)
}

.text-d {
  color: #efefef
}

.background-0-d {
  background-color: transparent !important
}

.background-5-d {
  background-color: rgba(77, 56, 36, .05) !important
}

.background-10-d {
  background-color: rgba(77, 56, 36, .1) !important
}

.background-15-d {
  background-color: rgba(77, 56, 36, .15) !important
}

.background-20-d {
  background-color: rgba(77, 56, 36, .2) !important
}

.background-25-d {
  background-color: rgba(77, 56, 36, .25) !important
}

.background-30-d {
  background-color: rgba(77, 56, 36, .3) !important
}

.background-35-d {
  background-color: rgba(77, 56, 36, .35) !important
}

.background-40-d {
  background-color: rgba(77, 56, 36, .4) !important
}

.background-45-d {
  background-color: rgba(77, 56, 36, .45) !important
}

.background-50-d {
  background-color: rgba(77, 56, 36, .5) !important
}

.background-55-d {
  background-color: rgba(77, 56, 36, .55) !important
}

.background-60-d {
  background-color: rgba(77, 56, 36, .6) !important
}

.background-65-d {
  background-color: rgba(77, 56, 36, .65) !important
}

.background-70-d {
  background-color: rgba(77, 56, 36, .7) !important
}

.background-75-d {
  background-color: rgba(77, 56, 36, .75) !important
}

.background-80-d {
  background-color: rgba(77, 56, 36, .8) !important
}

.background-85-d {
  background-color: rgba(77, 56, 36, .85) !important
}

.background-90-d {
  background-color: rgb(122, 130, 136) !important
}

.background-95-d {
  background-color: rgba(77, 56, 36, .95) !important
}

.background-100-d {
  background-color: #4d3824 !important
}

.background-d {
  background-color: rgba(77, 56, 36, .82) !important
}

.background-lite-d {
  background-color: rgba(77, 56, 36, .46806867) !important
}

.background-lite-lite-d {
  background-color: rgba(77, 56, 36, .23428571) !important
}

.background-hard-d {
  background-color: rgba(77, 56, 36, .94) !important
}

.background-hard-hard-d {
  background-color: rgba(77, 56, 36, .964) !important
}

.border-background-d {
  border-color: rgba(77, 56, 36, .7) !important
}

.border-text-d {
  border-color: rgba(239, 239, 239, .7) !important
}

.border-highlight-d {
  border-color: rgba(255, 224, 204, .7) !important
}

.border-heading-d {
  border-color: rgba(255, 255, 255, .7) !important
}

.border-link-d {
  border-color: rgba(255, 224, 204, .7) !important
}

.heading-d {
  color: #fff !important
}

.text-d {
  color: #efefef !important
}

.text-background-d {
  color: #4d3824 !important
}

.background-highlight-d {
  background-color: #ffe0cc !important
}

.background-heading-d {
  background-color: #fff !important
}

.background-text-d {
  background-color: #efefef !important
}

.background-link-d {
  background-color: #ffe0cc !important
}

.highlight-d, .link-d {
  color: #ffe0cc !important
}

.colors-e {
  color: #dfdfdf;
  background-color: rgba(20, 20, 20, .88)
}

.colors-e .bg-color {
  color: #141414
}

.colors-e .out {
  opacity: .88
}

.colors-e .heading, .colors-e .link, .colors-e.heading, .colors-e.link {
  color: #7a8288
}

.colors-e .highlight, .colors-e.highlight {
  color: #fff
}

.colors-e .background, .colors-e.background {
  background-color: rgba(20, 20, 20, .88)
}

.colors-e .background-solid, .colors-e.background-solid {
  background-color: #141414
}

.colors-e .background-hard, .colors-e.background-hard {
  background-color: rgba(20, 20, 20, .96)
}

.colors-e .background-lite, .colors-e.background-lite {
  background-color: rgba(20, 20, 20, .5023176)
}

.colors-e .col-icon, .colors-e .col-left-marginalia, .colors-e a, .colors-e col-right-marginalia, .colors-e h1, .colors-e h2, .colors-e h3, .colors-e h4, .colors-e h5, .colors-e h6, a.colors-e {
  color: #7a8288
}

.colors-e a:hover, a.colors-e:hover {
  color: rgba(219, 171, 131, .75)
}

.colors-e .line {
  border-color: #dfdfdf;
  border-color: rgba(223, 223, 223, .3)
}

.colors-e .line:hover {
  background-color: rgba(20, 20, 20, .5023176)
}

.colors-e .help-block li {
  color: #fff
}

.colors-e .grid-example > .row > div:nth-child(1n) {
  color: #7a8288;
  background-color: rgba(223, 223, 223, .1)
}

.colors-e .grid-example > .row > div:nth-child(2n) {
  color: #7a8288;
  background-color: rgba(223, 223, 223, .3)
}

.colors-e ::-webkit-input-placeholder {
  color: rgba(223, 223, 223, .6)
}

.colors-e :-moz-placeholder {
  color: rgba(223, 223, 223, .6);
  opacity: 1
}

.colors-e ::-moz-placeholder {
  color: rgba(223, 223, 223, .6);
  opacity: 1
}

.colors-e :-ms-input-placeholder {
  color: rgba(223, 223, 223, .6)
}

.colors-e .border, .colors-e .border-bottom, .colors-e .border-left, .colors-e .border-right, .colors-e .border-top, .colors-e.border, .colors-e.border-bottom, .colors-e.border-left, .colors-e.border-right, .colors-e.border-top {
  border-color: rgba(219, 171, 131, .3) !important
}

.colors-e .border-hard, .colors-e.border-hard {
  border-color: rgba(219, 171, 131, .7) !important
}

.colors-e .border-lite, .colors-e.border-lite {
  border-color: rgba(219, 171, 131, .08) !important
}

.colors-e .text {
  color: #dfdfdf !important
}

.colors-e .text-background, .colors-e.text-background {
  color: #141414 !important
}

.colors-e .background-highlight, .colors-e.background-highlight {
  background-color: #fff !important
}

.colors-e .background-heading, .colors-e.background-heading {
  background-color: #7a8288 !important
}

.colors-e .background-text, .colors-e.background-text {
  background-color: #dfdfdf !important
}

.colors-e .background-link, .colors-e.background-link {
  background-color: #7a8288 !important
}

.colors-e .border-text, .colors-e.border-text {
  border-color: rgba(223, 223, 223, .7) !important
}

.colors-e .border-highlight, .colors-e.border-highlight {
  border-color: rgba(255, 255, 255, .7) !important
}

.colors-e .border-heading, .colors-e .border-link, .colors-e.border-heading, .colors-e.border-link {
  border-color: rgba(219, 171, 131, .7) !important
}

.colors-e .background-0, .colors-e.background-0 {
  background-color: transparent !important
}

.colors-e .background-5, .colors-e.background-5 {
  background-color: rgba(20, 20, 20, .05) !important
}

.colors-e .background-10, .colors-e.background-10 {
  background-color: rgba(20, 20, 20, .1) !important
}

.colors-e .background-15, .colors-e.background-15 {
  background-color: rgba(20, 20, 20, .15) !important
}

.colors-e .background-20, .colors-e.background-20 {
  background-color: rgba(20, 20, 20, .2) !important
}

.colors-e .background-25, .colors-e.background-25 {
  background-color: rgba(20, 20, 20, .25) !important
}

.colors-e .background-30, .colors-e.background-30 {
  background-color: rgba(20, 20, 20, .3) !important
}

.colors-e .background-35, .colors-e.background-35 {
  background-color: rgba(20, 20, 20, .35) !important
}

.colors-e .background-40, .colors-e.background-40 {
  background-color: rgba(20, 20, 20, .4) !important
}

.colors-e .background-45, .colors-e.background-45 {
  background-color: rgba(20, 20, 20, .45) !important
}

.colors-e .background-50, .colors-e.background-50 {
  background-color: rgba(20, 20, 20, .5) !important
}

.colors-e .background-55, .colors-e.background-55 {
  background-color: rgba(20, 20, 20, .55) !important
}

.colors-e .background-60, .colors-e.background-60 {
  background-color: rgba(20, 20, 20, .6) !important
}

.colors-e .background-65, .colors-e.background-65 {
  background-color: rgba(20, 20, 20, .65) !important
}

.colors-e .background-70, .colors-e.background-70 {
  background-color: rgba(20, 20, 20, .7) !important
}

.colors-e .background-75, .colors-e.background-75 {
  background-color: rgba(20, 20, 20, .75) !important
}

.colors-e .background-80, .colors-e.background-80 {
  background-color: rgba(20, 20, 20, .8) !important
}

.colors-e .background-85, .colors-e.background-85 {
  background-color: rgba(20, 20, 20, .85) !important
}

.colors-e .background-90, .colors-e.background-90 {
  background-color: rgba(20, 20, 20, .9) !important
}

.colors-e .background-95, .colors-e.background-95 {
  background-color: rgba(20, 20, 20, .95) !important
}

.colors-e .background-100, .colors-e.background-100 {
  background-color: #141414 !important
}

.colors-e h2::after {
  background-color: rgba(219, 171, 131, .4)
}

.colors-e h3 a {
  color: #7a8288
}

.colors-e h3 a:hover {
  color: rgba(219, 171, 131, .75)
}

.colors-e h3 {
  border-color: rgba(255, 255, 255, .5)
}

.colors-e input, .colors-e textarea {
  background-color: transparent;
  border-color: rgba(219, 171, 131, .6);
  outline-color: rgba(223, 223, 223, 0)
}

.colors-e input:focus, .colors-e textarea:focus {
  outline-color: rgba(223, 223, 223, .37)
}

#top-nav.colors-e {
  border-color: rgba(255, 255, 255, .16);
  color: #7a8288
}

#top-nav.colors-e li a {
  color: #7a8288
}

#top-nav.colors-e li a:hover {
  color: inherit
}

#top-nav.colors-e .navbar-brand, #top-nav.colors-e .navbar-brand:hover {
  color: #7a8288
}

#top-nav.colors-e .nav > li ul {
  background-color: rgba(20, 20, 20, .88)
}

#top-nav.colors-e .nav > li ul a {
  color: #7a8288
}

#top-nav.colors-e .nav > li ul a.active {
  color: #fff
}

#top-nav.colors-e .nav > li ul a:hover {
  background-color: transparent
}

#top-nav.colors-e li.open > a {
  background-color: rgba(20, 20, 20, .5023176)
}

#top-nav.colors-e .navbar-nav a.active {
  color: #fff
}

#top-nav.colors-e .navbar-toggle {
  background-color: transparent !important;
  border-color: #7a8288
}

#top-nav.colors-e .navbar-toggle .icon-bar {
  background-color: #7a8288
}

.colors-e.page-border {
  border-color: rgba(255, 255, 255, .16)
}

.colors-e.page-border a, .ext-nav a.colors-e .side-title {
  color: #7a8288
}

.ext-nav a.colors-e .side-label {
  color: #fff
}

.ext-nav a.colors-e:hover .side-title {
  color: #7a8288
}

.ext-nav a.colors-e:hover .side-label {
  color: #fff
}

.colors-e .menu-toggle span, .colors-e .menu-toggle span:after, .colors-e .menu-toggle span:before {
  background-color: #7a8288
}

.colors-e .menu-toggle.navbar-toggle span, .colors-e .menu-toggle.navbar-toggle span:after, .colors-e .menu-toggle.navbar-toggle span:before {
  background-color: #fff
}

.colors-e .menu-toggle.show span {
  background-color: transparent
}

.colors-e .carousel .slick-next, .colors-e .carousel .slick-prev, .colors-e .slider .slick-next, .colors-e .slider .slick-prev {
  background-color: #f9f9f9;
  background-color: rgba(255, 255, 255, .8)
}

.colors-e .carousel .slick-next:before, .colors-e .carousel .slick-prev:before, .colors-e .slider .slick-next:before, .colors-e .slider .slick-prev:before {
  color: #222;
  background-color: transparent
}

.colors-e .carousel .slick-next:hover, .colors-e .carousel .slick-prev:hover, .colors-e .slider .slick-next:hover, .colors-e .slider .slick-prev:hover {
  background-color: #efefef;
  background-color: rgba(255, 255, 255, .6)
}

.colors-e .carousel .slick-dots li button:before, .colors-e .slider .slick-dots li button:before {
  color: #dfdfdf;
  color: rgba(223, 223, 223, .4);
  opacity: 1
}

.colors-e .carousel .slick-dots li.slick-active button:before, .colors-e .slider .slick-dots li.slick-active button:before {
  color: #fff;
  color: rgba(223, 223, 223, .7);
  opacity: 1
}

.colors-e .carousel .slick-slide {
  background-color: rgba(20, 20, 20, .88);
  -webkit-box-shadow: 0 0 8px #fff;
  -moz-box-shadow: 0 0 8px #fff;
  box-shadow: 0 0 8px #fff
}

.colors-e .gallery .filter .nav li a {
  color: #7a8288;
  background-color: transparent
}

.colors-e .gallery .filter .nav li a.active {
  color: #fff
}

.colors-e.gallery-overlay .field {
  color: #7a8288
}

.colors-e .cross:after, .colors-e .cross:before {
  background-color: #7a8288
}

.colors-e.circle {
  border-color: rgba(20, 20, 20, .88);
  color: #7a8288
}

.colors-e.circle:after {
  background-color: transparent;
  border-color: rgba(20, 20, 20, .88)
}

.colors-e.circle:hover {
  background-color: transparent;
  color: rgba(20, 20, 20, .88)
}

.colors-e.circle:hover:after {
  border-color: rgba(20, 20, 20, .88)
}

.colors-e .process .process-row .process-step .process-box {
  border-color: rgba(223, 223, 223, .7)
}

.colors-e .process .process-row .process-step .process-box:before {
  background-color: #141414
}

#dot-scroll.colors-e li a span {
  background-color: #7a8288
}

#dot-scroll.colors-e li a.active span, #dot-scroll.colors-e li a:hover.active span {
  background-color: rgba(20, 20, 20, .88)
}

#dot-scroll.colors-e li a.active span, #dot-scroll.colors-e li a.target span, #dot-scroll.colors-e li a.target.active span, #dot-scroll.colors-e li a:hover.active span, #dot-scroll.colors-e li a:hover.target span, #dot-scroll.colors-e li a:hover.target.active span {
  border-color: #7a8288
}

.colors-e.skew.skew-bottom-left {
  border-bottom-color: transparent;
  border-left-color: #141414;
  background-color: transparent !important
}

.colors-e.skew.skew-bottom-right {
  border-bottom-color: #141414;
  border-left-color: transparent;
  background-color: transparent !important
}

.colors-e.skew.skew-top-right {
  border-top-color: transparent;
  border-right-color: #141414;
  background-color: transparent !important
}

.colors-e.skew.skew-top-left {
  border-top-color: #141414;
  border-right-color: transparent;
  background-color: transparent !important
}

.colors-e .loader {
  border-color: rgba(223, 223, 223, .1)
}

.colors-e .loader li:nth-child(1), .colors-e .loader li:nth-child(2), .colors-e .loader li:nth-child(3), .colors-e .loader li:nth-child(4) {
  background: #dfdfdf
}

.colors-e #comments, .colors-e #comments .comment-list .comment, .colors-e .avatar, .colors-e ul.tags-list li {
  border-color: rgba(219, 171, 131, .3) !important
}

.colors-e .post .post-meta, .colors-e .post .post-meta a {
  color: #7a8288
}

.colors-e .post .post-meta a:hover {
  color: #fff
}

.colors-e .post .post-meta .post-month {
  border-color: rgba(219, 171, 131, .08) !important
}

.colors-e .post h3 a:hover {
  color: #7a8288
}

.colors-e .post .post-tags {
  color: rgba(223, 223, 223, .85)
}

.colors-e .post .post-month {
  color: #fff
}

.colors-e .sidebar .title {
  color: #7a8288
}

.colors-e .pagination .active a {
  background-color: rgba(219, 171, 131, .85);
  color: #141414
}

.colors-e .pagination .active a:hover {
  color: #141414
}

.colors-e .pagination a {
  background-color: transparent;
  border-color: rgba(219, 171, 131, .4) !important;
  color: #7a8288
}

.colors-e .pagination a:hover {
  color: #fff
}

.colors-e .navbar-collapse {
  max-height: none
}

.colors-e .caption, .colors-e .header-details, .colors-e .subtitle, .colors-e .title {
  color: #7a8288
}

.colors-e .header-details.highlight, .colors-e .slogan, .colors-e .subtitle.highlight, .colors-e .title.highlight {
  color: #fff
}

.colors-e .underline-dashed, .colors-e .underline-dotted {
  border-color: rgba(255, 255, 255, .7)
}

.colors-e .underline, .colors-e .underline-highlight {
  border-color: rgba(255, 255, 255, .5)
}

.colors-e .underline-heading, .colors-e .underline-link {
  border-color: rgba(219, 171, 131, .5)
}

.colors-e .underline-text {
  border-color: rgba(223, 223, 223, .5)
}

.colors-e .top-line:before {
  background-color: rgba(255, 255, 255, .7)
}

.colors-e .bottom-line:after, .colors-e .short-underline:after {
  background-color: #fff
}

.colors-e .stroke-highlight {
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff
}

.colors-e .stroke-heading {
  text-shadow: -1px -1px 0 #7a8288, 1px -1px 0 #7a8288, -1px 1px 0 #7a8288, 1px 1px 0 #7a8288
}

.colors-e .stroke-foreground {
  text-shadow: -1px -1px 0 #dfdfdf, 1px -1px 0 #dfdfdf, -1px 1px 0 #dfdfdf, 1px 1px 0 #dfdfdf
}

.colors-e .stroke-background {
  text-shadow: -1px -1px 0 rgba(20, 20, 20, .88), 1px -1px 0 rgba(20, 20, 20, .88), -1px 1px 0 rgba(20, 20, 20, .88), 1px 1px 0 rgba(20, 20, 20, .88)
}

.colors-e .label-border {
  border-color: rgba(255, 255, 255, .7);
  outline-color: #fff
}

.colors-e .bottom-menu, .colors-e .bottom-menu a, .colors-e .bottom-menu i {
  color: #dfdfdf !important
}

.colors-e .bottom-menu a:hover {
  background-color: transparent;
  color: #fff
}

.colors-e .bottom-menu a:hover i {
  color: #fff
}

footer.colors-e a i:hover {
  color: #dfdfdf !important
}

.colors-e .main-icon, .colors-e .main-icon:hover {
  color: #7a8288
}

.colors-e.content.border-bottom, .colors-e.content.border-top, footer.colors-e.border-bottom, footer.colors-e.border-top {
  border-color: rgba(255, 255, 255, .8)
}

.colors-e.credits-overlay .content-container > ul > li {
  color: #fff
}

.colors-e.credits-overlay .content-container > ul > li li {
  color: #7a8288
}

.colors-e input.button {
  color: #fff
}

.colors-e input.button:hover {
  color: #7a8288
}

.colors-e iframe.example, .colors-e pre.example {
  border-color: rgba(223, 223, 223, .3) !important
}

.colors-e.help-toc a, .colors-e.help-toc li, .colors-e.help-toc ul {
  color: #7a8288
}

.colors-e .help-toc-title {
  border-color: rgba(219, 171, 131, .2)
}

.text-e {
  color: #dfdfdf
}

.background-0-e {
  background-color: transparent !important
}

.background-5-e {
  background-color: rgba(20, 20, 20, .05) !important
}

.background-10-e {
  background-color: rgba(20, 20, 20, .1) !important
}

.background-15-e {
  background-color: rgba(20, 20, 20, .15) !important
}

.background-20-e {
  background-color: rgba(20, 20, 20, .2) !important
}

.background-25-e {
  background-color: rgba(20, 20, 20, .25) !important
}

.background-30-e {
  background-color: rgba(20, 20, 20, .3) !important
}

.background-35-e {
  background-color: rgba(20, 20, 20, .35) !important
}

.background-40-e {
  background-color: rgba(20, 20, 20, .4) !important
}

.background-45-e {
  background-color: rgba(20, 20, 20, .45) !important
}

.background-50-e {
  background-color: rgba(20, 20, 20, .5) !important
}

.background-55-e {
  background-color: rgba(20, 20, 20, .55) !important
}

.background-60-e {
  background-color: rgba(20, 20, 20, .6) !important
}

.background-65-e {
  background-color: rgba(20, 20, 20, .65) !important
}

.background-70-e {
  background-color: rgba(20, 20, 20, .7) !important
}

.background-75-e {
  background-color: rgba(20, 20, 20, .75) !important
}

.background-80-e {
  background-color: rgba(20, 20, 20, .8) !important
}

.background-85-e {
  background-color: rgba(20, 20, 20, .85) !important
}

.background-90-e {
  background-color: rgba(20, 20, 20, .9) !important
}

.background-95-e {
  background-color: rgba(20, 20, 20, .95) !important
}

.background-100-e {
  background-color: #141414 !important
}

.background-e {
  background-color: rgba(20, 20, 20, .88) !important
}

.background-lite-e {
  background-color: rgba(20, 20, 20, .5023176) !important
}

.background-lite-lite-e {
  background-color: rgba(20, 20, 20, .25142857) !important
}

.background-hard-e {
  background-color: rgba(20, 20, 20, .96) !important
}

.background-hard-hard-e {
  background-color: rgba(20, 20, 20, .976) !important
}

.border-background-e {
  border-color: rgba(20, 20, 20, .7) !important
}

.border-text-e {
  border-color: rgba(223, 223, 223, .7) !important
}

.border-highlight-e {
  border-color: rgba(255, 255, 255, .7) !important
}

.border-heading-e, .border-link-e {
  border-color: rgba(219, 171, 131, .7) !important
}

.heading-e {
  color: #7a8288 !important
}

.text-e {
  color: #dfdfdf !important
}

.text-background-e {
  color: #141414 !important
}

.background-highlight-e {
  background-color: #fff !important
}

.background-heading-e {
  background-color: #7a8288 !important
}

.background-text-e {
  background-color: #dfdfdf !important
}

.background-link-e {
  background-color: #7a8288 !important
}

.highlight-e {
  color: #fff !important
}

.link-e {
  color: #7a8288 !important
}

.colors-f {
  color: #efefef;
  background-color: rgba(27, 28, 36, .85)
}

.colors-f .bg-color {
  color: #1b1c24
}

.colors-f .out {
  opacity: .85
}

.colors-f .heading, .colors-f.heading {
  color: #fff
}

.colors-f .highlight, .colors-f .link, .colors-f.highlight, .colors-f.link {
  color: #7a8288
}

.colors-f .background, .colors-f.background {
  background-color: rgba(27, 28, 36, .85)
}

.colors-f .background-solid, .colors-f.background-solid {
  background-color: #1b1c24
}

.colors-f .background-hard, .colors-f.background-hard {
  background-color: rgba(27, 28, 36, .95)
}

.colors-f .background-lite, .colors-f.background-lite {
  background-color: rgba(27, 28, 36, .48519313)
}

.colors-f .col-icon, .colors-f .col-left-marginalia, .colors-f col-right-marginalia, .colors-f h1, .colors-f h2, .colors-f h3, .colors-f h4, .colors-f h5, .colors-f h6 {
  color: #fff
}

.colors-f a, a.colors-f {
  color: #7a8288
}

.colors-f a:hover, a.colors-f:hover {
  color: rgba(219, 171, 131, .75)
}

.colors-f .line {
  border-color: #efefef;
  border-color: rgba(239, 239, 239, .3)
}

.colors-f .line:hover {
  background-color: rgba(27, 28, 36, .48519313)
}

.colors-f .help-block li {
  color: #7a8288
}

.colors-f .grid-example > .row > div:nth-child(1n) {
  color: #fff;
  background-color: rgba(239, 239, 239, .1)
}

.colors-f .grid-example > .row > div:nth-child(2n) {
  color: #fff;
  background-color: rgba(239, 239, 239, .3)
}

.colors-f ::-webkit-input-placeholder {
  color: rgba(239, 239, 239, .6)
}

.colors-f :-moz-placeholder {
  color: rgba(239, 239, 239, .6);
  opacity: 1
}

.colors-f ::-moz-placeholder {
  color: rgba(239, 239, 239, .6);
  opacity: 1
}

.colors-f :-ms-input-placeholder {
  color: rgba(239, 239, 239, .6)
}

.colors-f .border, .colors-f .border-bottom, .colors-f .border-left, .colors-f .border-right, .colors-f .border-top, .colors-f.border, .colors-f.border-bottom, .colors-f.border-left, .colors-f.border-right, .colors-f.border-top {
  border-color: rgba(255, 255, 255, .3) !important
}

.colors-f .border-hard, .colors-f.border-hard {
  border-color: rgba(255, 255, 255, .7) !important
}

.colors-f .border-lite, .colors-f.border-lite {
  border-color: rgba(255, 255, 255, .08) !important
}

.colors-f .text {
  color: #efefef !important
}

.colors-f .text-background, .colors-f.text-background {
  color: #1b1c24 !important
}

.colors-f .background-highlight, .colors-f.background-highlight {
  background-color: #7a8288 !important
}

.colors-f .background-heading, .colors-f.background-heading {
  background-color: #fff !important
}

.colors-f .background-text, .colors-f.background-text {
  background-color: #efefef !important
}

.colors-f .background-link, .colors-f.background-link {
  background-color: #7a8288 !important
}

.colors-f .border-text, .colors-f.border-text {
  border-color: rgba(239, 239, 239, .7) !important
}

.colors-f .border-highlight, .colors-f.border-highlight {
  border-color: rgba(219, 171, 131, .7) !important
}

.colors-f .border-heading, .colors-f.border-heading {
  border-color: rgba(255, 255, 255, .7) !important
}

.colors-f .border-link, .colors-f.border-link {
  border-color: rgba(219, 171, 131, .7) !important
}

.colors-f .background-0, .colors-f.background-0 {
  background-color: transparent !important
}

.colors-f .background-5, .colors-f.background-5 {
  background-color: rgba(27, 28, 36, .05) !important
}

.colors-f .background-10, .colors-f.background-10 {
  background-color: rgba(27, 28, 36, .1) !important
}

.colors-f .background-15, .colors-f.background-15 {
  background-color: rgba(27, 28, 36, .15) !important
}

.colors-f .background-20, .colors-f.background-20 {
  background-color: rgba(27, 28, 36, .2) !important
}

.colors-f .background-25, .colors-f.background-25 {
  background-color: rgba(27, 28, 36, .25) !important
}

.colors-f .background-30, .colors-f.background-30 {
  background-color: rgba(27, 28, 36, .3) !important
}

.colors-f .background-35, .colors-f.background-35 {
  background-color: rgba(27, 28, 36, .35) !important
}

.colors-f .background-40, .colors-f.background-40 {
  background-color: rgba(27, 28, 36, .4) !important
}

.colors-f .background-45, .colors-f.background-45 {
  background-color: rgba(27, 28, 36, .45) !important
}

.colors-f .background-50, .colors-f.background-50 {
  background-color: rgba(27, 28, 36, .5) !important
}

.colors-f .background-55, .colors-f.background-55 {
  background-color: rgba(27, 28, 36, .55) !important
}

.colors-f .background-60, .colors-f.background-60 {
  background-color: rgba(27, 28, 36, .6) !important
}

.colors-f .background-65, .colors-f.background-65 {
  background-color: rgba(27, 28, 36, .65) !important
}

.colors-f .background-70, .colors-f.background-70 {
  background-color: rgba(27, 28, 36, .7) !important
}

.colors-f .background-75, .colors-f.background-75 {
  background-color: rgba(27, 28, 36, .75) !important
}

.colors-f .background-80, .colors-f.background-80 {
  background-color: rgba(27, 28, 36, .8) !important
}

.colors-f .background-85, .colors-f.background-85 {
  background-color: rgba(27, 28, 36, .85) !important
}

.colors-f .background-90, .colors-f.background-90 {
  background-color: rgba(27, 28, 36, .9) !important
}

.colors-f .background-95, .colors-f.background-95 {
  background-color: rgba(27, 28, 36, .95) !important
}

.colors-f .background-100, .colors-f.background-100 {
  background-color: #1b1c24 !important
}

.colors-f h2::after {
  background-color: rgba(255, 255, 255, .4)
}

.colors-f h3 a {
  color: #fff
}

.colors-f h3 a:hover {
  color: rgba(255, 255, 255, .75)
}

.colors-f h3 {
  border-color: rgba(219, 171, 131, .5)
}

.colors-f input, .colors-f textarea {
  background-color: transparent;
  border-color: rgba(255, 255, 255, .6);
  outline-color: rgba(239, 239, 239, 0)
}

.colors-f input:focus, .colors-f textarea:focus {
  outline-color: rgba(239, 239, 239, .37)
}

#top-nav.colors-f {
  border-color: rgba(219, 171, 131, .16);
  color: #fff
}

#top-nav.colors-f li a {
  color: #fff
}

#top-nav.colors-f li a:hover {
  color: inherit
}

#top-nav.colors-f .navbar-brand, #top-nav.colors-f .navbar-brand:hover {
  color: #fff
}

#top-nav.colors-f .nav > li ul {
  background-color: rgba(27, 28, 36, .85)
}

#top-nav.colors-f .nav > li ul a {
  color: #fff
}

#top-nav.colors-f .nav > li ul a.active {
  color: #7a8288
}

#top-nav.colors-f .nav > li ul a:hover {
  background-color: transparent
}

#top-nav.colors-f li.open > a {
  background-color: rgba(27, 28, 36, .48519313)
}

#top-nav.colors-f .navbar-nav a.active {
  color: #7a8288
}

#top-nav.colors-f .navbar-toggle {
  background-color: transparent !important;
  border-color: #fff
}

#top-nav.colors-f .navbar-toggle .icon-bar {
  background-color: #fff
}

.colors-f.page-border {
  border-color: rgba(219, 171, 131, .16)
}

.colors-f.page-border a, .ext-nav a.colors-f .side-title {
  color: #fff
}

.ext-nav a.colors-f .side-label {
  color: #7a8288
}

.ext-nav a.colors-f:hover .side-title {
  color: #fff
}

.ext-nav a.colors-f:hover .side-label {
  color: #7a8288
}

.colors-f .menu-toggle span, .colors-f .menu-toggle span:after, .colors-f .menu-toggle span:before {
  background-color: #fff
}

.colors-f .menu-toggle.navbar-toggle span, .colors-f .menu-toggle.navbar-toggle span:after, .colors-f .menu-toggle.navbar-toggle span:before {
  background-color: #7a8288
}

.colors-f .menu-toggle.show span {
  background-color: transparent
}

.colors-f .carousel .slick-next, .colors-f .carousel .slick-prev, .colors-f .slider .slick-next, .colors-f .slider .slick-prev {
  background-color: #f9f9f9;
  background-color: rgba(255, 255, 255, .8)
}

.colors-f .carousel .slick-next:before, .colors-f .carousel .slick-prev:before, .colors-f .slider .slick-next:before, .colors-f .slider .slick-prev:before {
  color: #222;
  background-color: transparent
}

.colors-f .carousel .slick-next:hover, .colors-f .carousel .slick-prev:hover, .colors-f .slider .slick-next:hover, .colors-f .slider .slick-prev:hover {
  background-color: #efefef;
  background-color: rgba(255, 255, 255, .6)
}

.colors-f .carousel .slick-dots li button:before, .colors-f .slider .slick-dots li button:before {
  color: #efefef;
  color: rgba(239, 239, 239, .4);
  opacity: 1
}

.colors-f .carousel .slick-dots li.slick-active button:before, .colors-f .slider .slick-dots li.slick-active button:before {
  color: #7a8288;
  color: rgba(239, 239, 239, .7);
  opacity: 1
}

.colors-f .carousel .slick-slide {
  background-color: rgba(27, 28, 36, .85);
  -webkit-box-shadow: 0 0 8px #fff;
  -moz-box-shadow: 0 0 8px #fff;
  box-shadow: 0 0 8px #fff
}

.colors-f .gallery .filter .nav li a {
  color: #fff;
  background-color: transparent
}

.colors-f .gallery .filter .nav li a.active {
  color: #7a8288
}

.colors-f.gallery-overlay .field {
  color: #fff
}

.colors-f .cross:after, .colors-f .cross:before {
  background-color: #fff
}

.colors-f.circle {
  border-color: rgba(27, 28, 36, .85);
  color: #fff
}

.colors-f.circle:after {
  background-color: transparent;
  border-color: rgba(27, 28, 36, .85)
}

.colors-f.circle:hover {
  background-color: transparent;
  color: rgba(27, 28, 36, .85)
}

.colors-f.circle:hover:after {
  border-color: rgba(27, 28, 36, .85)
}

.colors-f .process .process-row .process-step .process-box {
  border-color: rgba(239, 239, 239, .7)
}

.colors-f .process .process-row .process-step .process-box:before {
  background-color: #1b1c24
}

#dot-scroll.colors-f li a span {
  background-color: #fff
}

#dot-scroll.colors-f li a.active span, #dot-scroll.colors-f li a:hover.active span {
  background-color: rgba(27, 28, 36, .85)
}

#dot-scroll.colors-f li a.active span, #dot-scroll.colors-f li a.target span, #dot-scroll.colors-f li a.target.active span, #dot-scroll.colors-f li a:hover.active span, #dot-scroll.colors-f li a:hover.target span, #dot-scroll.colors-f li a:hover.target.active span {
  border-color: #fff
}

.colors-f.skew.skew-bottom-left {
  border-bottom-color: transparent;
  border-left-color: #1b1c24;
  background-color: transparent !important
}

.colors-f.skew.skew-bottom-right {
  border-bottom-color: #1b1c24;
  border-left-color: transparent;
  background-color: transparent !important
}

.colors-f.skew.skew-top-right {
  border-top-color: transparent;
  border-right-color: #1b1c24;
  background-color: transparent !important
}

.colors-f.skew.skew-top-left {
  border-top-color: #1b1c24;
  border-right-color: transparent;
  background-color: transparent !important
}

.colors-f .loader {
  border-color: rgba(239, 239, 239, .1)
}

.colors-f .loader li:nth-child(1), .colors-f .loader li:nth-child(2), .colors-f .loader li:nth-child(3), .colors-f .loader li:nth-child(4) {
  background: #efefef
}

.colors-f #comments, .colors-f #comments .comment-list .comment, .colors-f .avatar, .colors-f ul.tags-list li {
  border-color: rgba(255, 255, 255, .3) !important
}

.colors-f .post .post-meta, .colors-f .post .post-meta a {
  color: #fff
}

.colors-f .post .post-meta a:hover {
  color: #7a8288
}

.colors-f .post .post-meta .post-month {
  border-color: rgba(255, 255, 255, .08) !important
}

.colors-f .post h3 a:hover {
  color: #fff
}

.colors-f .post .post-tags {
  color: rgba(239, 239, 239, .85)
}

.colors-f .post .post-month {
  color: #7a8288
}

.colors-f .sidebar .title {
  color: #fff
}

.colors-f .pagination .active a {
  background-color: rgba(255, 255, 255, .85);
  color: #1b1c24
}

.colors-f .pagination .active a:hover {
  color: #1b1c24
}

.colors-f .pagination a {
  background-color: transparent;
  border-color: rgba(255, 255, 255, .4) !important;
  color: #fff
}

.colors-f .pagination a:hover {
  color: #7a8288
}

.colors-f .navbar-collapse {
  max-height: none
}

.colors-f .caption, .colors-f .header-details, .colors-f .subtitle, .colors-f .title {
  color: #fff
}

.colors-f .header-details.highlight, .colors-f .slogan, .colors-f .subtitle.highlight, .colors-f .title.highlight {
  color: #7a8288
}

.colors-f .underline-dashed, .colors-f .underline-dotted {
  border-color: rgba(219, 171, 131, .7)
}

.colors-f .underline, .colors-f .underline-highlight, .colors-f .underline-link {
  border-color: rgba(219, 171, 131, .5)
}

.colors-f .underline-heading {
  border-color: rgba(255, 255, 255, .5)
}

.colors-f .underline-text {
  border-color: rgba(239, 239, 239, .5)
}

.colors-f .top-line:before {
  background-color: rgba(219, 171, 131, .7)
}

.colors-f .bottom-line:after, .colors-f .short-underline:after {
  background-color: #7a8288
}

.colors-f .stroke-highlight {
  text-shadow: -1px -1px 0 #7a8288, 1px -1px 0 #7a8288, -1px 1px 0 #7a8288, 1px 1px 0 #7a8288
}

.colors-f .stroke-heading {
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff
}

.colors-f .stroke-foreground {
  text-shadow: -1px -1px 0 #efefef, 1px -1px 0 #efefef, -1px 1px 0 #efefef, 1px 1px 0 #efefef
}

.colors-f .stroke-background {
  text-shadow: -1px -1px 0 rgba(27, 28, 36, .85), 1px -1px 0 rgba(27, 28, 36, .85), -1px 1px 0 rgba(27, 28, 36, .85), 1px 1px 0 rgba(27, 28, 36, .85)
}

.colors-f .label-border {
  border-color: rgba(219, 171, 131, .7);
  outline-color: #7a8288
}

.colors-f .bottom-menu, .colors-f .bottom-menu a, .colors-f .bottom-menu i {
  color: #efefef !important
}

.colors-f .bottom-menu a:hover {
  background-color: transparent;
  color: #7a8288
}

.colors-f .bottom-menu a:hover i {
  color: #7a8288
}

footer.colors-f a i:hover {
  color: #efefef !important
}

.colors-f .main-icon, .colors-f .main-icon:hover {
  color: #fff
}

.colors-f.content.border-bottom, .colors-f.content.border-top, footer.colors-f.border-bottom, footer.colors-f.border-top {
  border-color: rgba(219, 171, 131, .8)
}

.colors-f input.button, .colors-f.credits-overlay .content-container > ul > li, .colors-f.credits-overlay .content-container > ul > li li {
  color: #7a8288
}

.colors-f input.button:hover {
  color: #fff
}

.colors-f iframe.example, .colors-f pre.example {
  border-color: rgba(239, 239, 239, .3) !important
}

.colors-f.help-toc a, .colors-f.help-toc li, .colors-f.help-toc ul {
  color: #fff
}

.colors-f .help-toc-title {
  border-color: rgba(255, 255, 255, .2)
}

.text-f {
  color: #efefef
}

.background-0-f {
  background-color: transparent !important
}

.background-5-f {
  background-color: rgba(27, 28, 36, .05) !important
}

.background-10-f {
  background-color: rgba(27, 28, 36, .1) !important
}

.background-15-f {
  background-color: rgba(27, 28, 36, .15) !important
}

.background-20-f {
  background-color: rgba(27, 28, 36, .2) !important
}

.background-25-f {
  background-color: rgba(27, 28, 36, .25) !important
}

.background-30-f {
  background-color: rgba(27, 28, 36, .3) !important
}

.background-35-f {
  background-color: rgba(27, 28, 36, .35) !important
}

.background-40-f {
  background-color: rgba(27, 28, 36, .4) !important
}

.background-45-f {
  background-color: rgba(27, 28, 36, .45) !important
}

.background-50-f {
  background-color: rgba(27, 28, 36, .5) !important
}

.background-55-f {
  background-color: rgba(27, 28, 36, .55) !important
}

.background-60-f {
  background-color: rgba(27, 28, 36, .6) !important
}

.background-65-f {
  background-color: rgba(27, 28, 36, .65) !important
}

.background-70-f {
  background-color: rgba(27, 28, 36, .7) !important
}

.background-75-f {
  background-color: rgba(27, 28, 36, .75) !important
}

.background-80-f {
  background-color: rgba(27, 28, 36, .8) !important
}

.background-85-f {
  background-color: rgba(27, 28, 36, .85) !important
}

.background-90-f {
  background-color: rgba(27, 28, 36, .9) !important
}

.background-95-f {
  background-color: rgba(27, 28, 36, .95) !important
}

.background-100-f {
  background-color: #1b1c24 !important
}

.background-f {
  background-color: rgba(27, 28, 36, .85) !important
}

.background-lite-f {
  background-color: rgba(27, 28, 36, .48519313) !important
}

.background-lite-lite-f {
  background-color: rgba(27, 28, 36, .24285714) !important
}

.background-hard-f {
  background-color: rgba(27, 28, 36, .95) !important
}

.background-hard-hard-f {
  background-color: rgba(27, 28, 36, .97) !important
}

.border-background-f {
  border-color: rgba(27, 28, 36, .7) !important
}

.border-text-f {
  border-color: rgba(239, 239, 239, .7) !important
}

.border-highlight-f {
  border-color: rgba(219, 171, 131, .7) !important
}

.border-heading-f {
  border-color: rgba(255, 255, 255, .7) !important
}

.border-link-f {
  border-color: rgba(219, 171, 131, .7) !important
}

.heading-f {
  color: #fff !important
}

.text-f {
  color: #efefef !important
}

.text-background-f {
  color: #1b1c24 !important
}

.background-highlight-f {
  background-color: #7a8288 !important
}

.background-heading-f {
  background-color: #fff !important
}

.background-text-f {
  background-color: #efefef !important
}

.background-link-f {
  background-color: #7a8288 !important
}

.highlight-f, .link-f {
  color: #7a8288 !important
}

.colors-g {
  color: #afafaf;
  background-color: #101010
}

.colors-g .bg-color {
  color: #101010
}

.colors-g .out {
  opacity: 1
}

.colors-g .heading, .colors-g.heading {
  color: #fff
}

.colors-g .link, .colors-g.link {
  color: #afafaf
}

.colors-g .highlight, .colors-g.highlight {
  color: #7a8288
}

.colors-g .background, .colors-g .background-hard, .colors-g .background-solid, .colors-g.background, .colors-g.background-hard, .colors-g.background-solid {
  background-color: #101010
}

.colors-g .background-lite, .colors-g.background-lite {
  background-color: rgba(16, 16, 16, .57081545)
}

.colors-g .col-icon, .colors-g .col-left-marginalia, .colors-g col-right-marginalia, .colors-g h1, .colors-g h2, .colors-g h3, .colors-g h4, .colors-g h5, .colors-g h6 {
  color: #fff
}

.colors-g a, a.colors-g {
  color: #afafaf
}

.colors-g a:hover, a.colors-g:hover {
  color: rgba(175, 175, 175, .75)
}

.colors-g .line {
  border-color: #afafaf;
  border-color: rgba(175, 175, 175, .3)
}

.colors-g .line:hover {
  background-color: rgba(16, 16, 16, .57081545)
}

.colors-g .help-block li {
  color: #7a8288
}

.colors-g .grid-example > .row > div:nth-child(1n) {
  color: #fff;
  background-color: #101010;
  background-color: rgba(175, 175, 175, .1)
}

.colors-g .grid-example > .row > div:nth-child(2n) {
  color: #fff;
  background-color: #101010;
  background-color: rgba(175, 175, 175, .3)
}

.colors-g ::-webkit-input-placeholder {
  color: rgba(175, 175, 175, .6)
}

.colors-g :-moz-placeholder {
  color: rgba(175, 175, 175, .6);
  opacity: 1
}

.colors-g ::-moz-placeholder {
  color: rgba(175, 175, 175, .6);
  opacity: 1
}

.colors-g :-ms-input-placeholder {
  color: rgba(175, 175, 175, .6)
}

.colors-g .border, .colors-g .border-bottom, .colors-g .border-left, .colors-g .border-right, .colors-g .border-top, .colors-g.border, .colors-g.border-bottom, .colors-g.border-left, .colors-g.border-right, .colors-g.border-top {
  border-color: rgba(255, 255, 255, .3) !important
}

.colors-g .border-hard, .colors-g.border-hard {
  border-color: rgba(255, 255, 255, .7) !important
}

.colors-g .border-lite, .colors-g.border-lite {
  border-color: rgba(255, 255, 255, .08) !important
}

.colors-g .text {
  color: #afafaf !important
}

.colors-g .text-background, .colors-g.text-background {
  color: #101010 !important
}

.colors-g .background-highlight, .colors-g.background-highlight {
  background-color: #7a8288 !important
}

.colors-g .background-heading, .colors-g.background-heading {
  background-color: #fff !important
}

.colors-g .background-link, .colors-g .background-text, .colors-g.background-link, .colors-g.background-text {
  background-color: #afafaf !important
}

.colors-g .border-text, .colors-g.border-text {
  border-color: rgba(175, 175, 175, .7) !important
}

.colors-g .border-highlight, .colors-g.border-highlight {
  border-color: rgba(219, 171, 131, .7) !important
}

.colors-g .border-heading, .colors-g.border-heading {
  border-color: rgba(255, 255, 255, .7) !important
}

.colors-g .border-link, .colors-g.border-link {
  border-color: rgba(175, 175, 175, .7) !important
}

.colors-g .background-0, .colors-g.background-0 {
  background-color: transparent !important
}

.colors-g .background-5, .colors-g.background-5 {
  background-color: rgba(16, 16, 16, .05) !important
}

.colors-g .background-10, .colors-g.background-10 {
  background-color: rgba(16, 16, 16, .1) !important
}

.colors-g .background-15, .colors-g.background-15 {
  background-color: rgba(16, 16, 16, .15) !important
}

.colors-g .background-20, .colors-g.background-20 {
  background-color: rgba(16, 16, 16, .2) !important
}

.colors-g .background-25, .colors-g.background-25 {
  background-color: rgba(16, 16, 16, .25) !important
}

.colors-g .background-30, .colors-g.background-30 {
  background-color: rgba(16, 16, 16, .3) !important
}

.colors-g .background-35, .colors-g.background-35 {
  background-color: rgba(16, 16, 16, .35) !important
}

.colors-g .background-40, .colors-g.background-40 {
  background-color: rgba(16, 16, 16, .4) !important
}

.colors-g .background-45, .colors-g.background-45 {
  background-color: rgba(16, 16, 16, .45) !important
}

.colors-g .background-50, .colors-g.background-50 {
  background-color: rgba(16, 16, 16, .5) !important
}

.colors-g .background-55, .colors-g.background-55 {
  background-color: rgba(16, 16, 16, .55) !important
}

.colors-g .background-60, .colors-g.background-60 {
  background-color: rgba(16, 16, 16, .6) !important
}

.colors-g .background-65, .colors-g.background-65 {
  background-color: rgba(16, 16, 16, .65) !important
}

.colors-g .background-70, .colors-g.background-70 {
  background-color: rgba(16, 16, 16, .7) !important
}

.colors-g .background-75, .colors-g.background-75 {
  background-color: rgba(16, 16, 16, .75) !important
}

.colors-g .background-80, .colors-g.background-80 {
  background-color: rgba(16, 16, 16, .8) !important
}

.colors-g .background-85, .colors-g.background-85 {
  background-color: rgba(16, 16, 16, .85) !important
}

.colors-g .background-90, .colors-g.background-90 {
  background-color: rgba(16, 16, 16, .9) !important
}

.colors-g .background-95, .colors-g.background-95 {
  background-color: rgba(16, 16, 16, .95) !important
}

.colors-g .background-100, .colors-g.background-100 {
  background-color: #101010 !important
}

.colors-g h2::after {
  background-color: rgba(255, 255, 255, .4)
}

.colors-g h3 a {
  color: #fff
}

.colors-g h3 a:hover {
  color: rgba(255, 255, 255, .75)
}

.colors-g h3 {
  border-color: rgba(219, 171, 131, .5)
}

.colors-g input, .colors-g textarea {
  background-color: transparent;
  border-color: rgba(255, 255, 255, .6);
  outline-color: rgba(175, 175, 175, 0)
}

.colors-g input:focus, .colors-g textarea:focus {
  outline-color: rgba(175, 175, 175, .37)
}

#top-nav.colors-g {
  border-color: rgba(219, 171, 131, .16);
  color: #fff
}

#top-nav.colors-g li a {
  color: #fff
}

#top-nav.colors-g li a:hover {
  color: inherit
}

#top-nav.colors-g .navbar-brand, #top-nav.colors-g .navbar-brand:hover {
  color: #fff
}

#top-nav.colors-g .nav > li ul {
  background-color: #101010
}

#top-nav.colors-g .nav > li ul a {
  color: #fff
}

#top-nav.colors-g .nav > li ul a.active {
  color: #7a8288
}

#top-nav.colors-g .nav > li ul a:hover {
  background-color: transparent
}

#top-nav.colors-g li.open > a {
  background-color: rgba(16, 16, 16, .57081545)
}

#top-nav.colors-g .navbar-nav a.active {
  color: #7a8288
}

#top-nav.colors-g .navbar-toggle {
  background-color: transparent !important;
  border-color: #fff
}

#top-nav.colors-g .navbar-toggle .icon-bar {
  background-color: #fff
}

.colors-g.page-border {
  border-color: rgba(219, 171, 131, .16)
}

.colors-g.page-border a, .ext-nav a.colors-g .side-title {
  color: #fff
}

.ext-nav a.colors-g .side-label {
  color: #7a8288
}

.ext-nav a.colors-g:hover .side-title {
  color: #fff
}

.ext-nav a.colors-g:hover .side-label {
  color: #7a8288
}

.colors-g .menu-toggle span, .colors-g .menu-toggle span:after, .colors-g .menu-toggle span:before {
  background-color: #fff
}

.colors-g .menu-toggle.navbar-toggle span, .colors-g .menu-toggle.navbar-toggle span:after, .colors-g .menu-toggle.navbar-toggle span:before {
  background-color: #7a8288
}

.colors-g .menu-toggle.show span {
  background-color: transparent
}

.colors-g .carousel .slick-next, .colors-g .carousel .slick-prev, .colors-g .slider .slick-next, .colors-g .slider .slick-prev {
  background-color: #f9f9f9;
  background-color: rgba(255, 255, 255, .8)
}

.colors-g .carousel .slick-next:before, .colors-g .carousel .slick-prev:before, .colors-g .slider .slick-next:before, .colors-g .slider .slick-prev:before {
  color: #222;
  background-color: transparent
}

.colors-g .carousel .slick-next:hover, .colors-g .carousel .slick-prev:hover, .colors-g .slider .slick-next:hover, .colors-g .slider .slick-prev:hover {
  background-color: #efefef;
  background-color: rgba(255, 255, 255, .6)
}

.colors-g .carousel .slick-dots li button:before, .colors-g .slider .slick-dots li button:before {
  color: #afafaf;
  color: rgba(175, 175, 175, .4);
  opacity: 1
}

.colors-g .carousel .slick-dots li.slick-active button:before, .colors-g .slider .slick-dots li.slick-active button:before {
  color: #7a8288;
  color: rgba(175, 175, 175, .7);
  opacity: 1
}

.colors-g .carousel .slick-slide {
  background-color: #101010;
  -webkit-box-shadow: 0 0 8px #fff;
  -moz-box-shadow: 0 0 8px #fff;
  box-shadow: 0 0 8px #fff
}

.colors-g .gallery .filter .nav li a {
  color: #fff;
  background-color: transparent
}

.colors-g .gallery .filter .nav li a.active {
  color: #7a8288
}

.colors-g.gallery-overlay .field {
  color: #fff
}

.colors-g .cross:after, .colors-g .cross:before {
  background-color: #fff
}

.colors-g.circle {
  border-color: #101010;
  color: #fff
}

.colors-g.circle:after {
  background-color: transparent;
  border-color: #101010
}

.colors-g.circle:hover {
  background-color: transparent;
  color: #101010
}

.colors-g.circle:hover:after {
  border-color: #101010
}

.colors-g .process .process-row .process-step .process-box {
  border-color: rgba(175, 175, 175, .7)
}

.colors-g .process .process-row .process-step .process-box:before {
  background-color: #101010
}

#dot-scroll.colors-g li a span {
  background-color: #fff
}

#dot-scroll.colors-g li a.active span, #dot-scroll.colors-g li a:hover.active span {
  background-color: #101010
}

#dot-scroll.colors-g li a.active span, #dot-scroll.colors-g li a.target span, #dot-scroll.colors-g li a.target.active span, #dot-scroll.colors-g li a:hover.active span, #dot-scroll.colors-g li a:hover.target span, #dot-scroll.colors-g li a:hover.target.active span {
  border-color: #fff
}

.colors-g.skew.skew-bottom-left {
  border-bottom-color: transparent;
  border-left-color: #101010;
  background-color: transparent !important
}

.colors-g.skew.skew-bottom-right {
  border-bottom-color: #101010;
  border-left-color: transparent;
  background-color: transparent !important
}

.colors-g.skew.skew-top-right {
  border-top-color: transparent;
  border-right-color: #101010;
  background-color: transparent !important
}

.colors-g.skew.skew-top-left {
  border-top-color: #101010;
  border-right-color: transparent;
  background-color: transparent !important
}

.colors-g .loader {
  border-color: rgba(175, 175, 175, .1)
}

.colors-g .loader li:nth-child(1), .colors-g .loader li:nth-child(2), .colors-g .loader li:nth-child(3), .colors-g .loader li:nth-child(4) {
  background: #afafaf
}

.colors-g #comments, .colors-g #comments .comment-list .comment, .colors-g .avatar, .colors-g ul.tags-list li {
  border-color: rgba(255, 255, 255, .3) !important
}

.colors-g .post .post-meta, .colors-g .post .post-meta a {
  color: #fff
}

.colors-g .post .post-meta a:hover {
  color: #7a8288
}

.colors-g .post .post-meta .post-month {
  border-color: rgba(255, 255, 255, .08) !important
}

.colors-g .post h3 a:hover {
  color: #fff
}

.colors-g .post .post-tags {
  color: rgba(175, 175, 175, .85)
}

.colors-g .post .post-month {
  color: #7a8288
}

.colors-g .sidebar .title {
  color: #fff
}

.colors-g .pagination .active a {
  background-color: rgba(255, 255, 255, .85);
  color: #101010
}

.colors-g .pagination .active a:hover {
  color: #101010
}

.colors-g .pagination a {
  background-color: transparent;
  border-color: rgba(255, 255, 255, .4) !important;
  color: #fff
}

.colors-g .pagination a:hover {
  color: #7a8288
}

.colors-g .navbar-collapse {
  max-height: none
}

.colors-g .caption, .colors-g .header-details, .colors-g .subtitle, .colors-g .title {
  color: #fff
}

.colors-g .header-details.highlight, .colors-g .slogan, .colors-g .subtitle.highlight, .colors-g .title.highlight {
  color: #7a8288
}

.colors-g .underline-dashed, .colors-g .underline-dotted {
  border-color: rgba(219, 171, 131, .7)
}

.colors-g .underline, .colors-g .underline-highlight {
  border-color: rgba(219, 171, 131, .5)
}

.colors-g .underline-link {
  border-color: rgba(175, 175, 175, .5)
}

.colors-g .underline-heading {
  border-color: rgba(255, 255, 255, .5)
}

.colors-g .underline-text {
  border-color: rgba(175, 175, 175, .5)
}

.colors-g .top-line:before {
  background-color: rgba(219, 171, 131, .7)
}

.colors-g .bottom-line:after, .colors-g .short-underline:after {
  background-color: #7a8288
}

.colors-g .stroke-highlight {
  text-shadow: -1px -1px 0 #7a8288, 1px -1px 0 #7a8288, -1px 1px 0 #7a8288, 1px 1px 0 #7a8288
}

.colors-g .stroke-heading {
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff
}

.colors-g .stroke-foreground {
  text-shadow: -1px -1px 0 #afafaf, 1px -1px 0 #afafaf, -1px 1px 0 #afafaf, 1px 1px 0 #afafaf
}

.colors-g .stroke-background {
  text-shadow: -1px -1px 0 #101010, 1px -1px 0 #101010, -1px 1px 0 #101010, 1px 1px 0 #101010
}

.colors-g .label-border {
  border-color: rgba(219, 171, 131, .7);
  outline-color: #7a8288
}

.colors-g .bottom-menu, .colors-g .bottom-menu a, .colors-g .bottom-menu i {
  color: #afafaf !important
}

.colors-g .bottom-menu a:hover {
  background-color: transparent;
  color: #7a8288
}

.colors-g .bottom-menu a:hover i {
  color: #7a8288
}

footer.colors-g a i:hover {
  color: #afafaf !important
}

.colors-g .main-icon, .colors-g .main-icon:hover {
  color: #fff
}

.colors-g.content.border-bottom, .colors-g.content.border-top, footer.colors-g.border-bottom, footer.colors-g.border-top {
  border-color: rgba(219, 171, 131, .8)
}

.colors-g.credits-overlay .content-container > ul > li {
  color: #7a8288
}

.colors-g.credits-overlay .content-container > ul > li li {
  color: #afafaf
}

.colors-g input.button {
  color: #7a8288
}

.colors-g input.button:hover {
  color: #fff
}

.colors-g iframe.example, .colors-g pre.example {
  border-color: rgba(175, 175, 175, .3) !important
}

.colors-g.help-toc a, .colors-g.help-toc li, .colors-g.help-toc ul {
  color: #fff
}

.colors-g .help-toc-title {
  border-color: rgba(255, 255, 255, .2)
}

.text-g {
  color: #afafaf
}

.background-0-g {
  background-color: transparent !important
}

.background-5-g {
  background-color: rgba(16, 16, 16, .05) !important
}

.background-10-g {
  background-color: rgba(16, 16, 16, .1) !important
}

.background-15-g {
  background-color: rgba(16, 16, 16, .15) !important
}

.background-20-g {
  background-color: rgba(16, 16, 16, .2) !important
}

.background-25-g {
  background-color: rgba(16, 16, 16, .25) !important
}

.background-30-g {
  background-color: rgba(16, 16, 16, .3) !important
}

.background-35-g {
  background-color: rgba(16, 16, 16, .35) !important
}

.background-40-g {
  background-color: rgba(16, 16, 16, .4) !important
}

.background-45-g {
  background-color: rgba(16, 16, 16, .45) !important
}

.background-50-g {
  background-color: rgba(16, 16, 16, .5) !important
}

.background-55-g {
  background-color: rgba(16, 16, 16, .55) !important
}

.background-60-g {
  background-color: rgba(16, 16, 16, .6) !important
}

.background-65-g {
  background-color: rgba(16, 16, 16, .65) !important
}

.background-70-g {
  background-color: rgba(16, 16, 16, .7) !important
}

.background-75-g {
  background-color: rgba(16, 16, 16, .75) !important
}

.background-80-g {
  background-color: rgba(16, 16, 16, .8) !important
}

.background-85-g {
  background-color: rgba(16, 16, 16, .85) !important
}

.background-90-g {
  background-color: rgba(16, 16, 16, .9) !important
}

.background-95-g {
  background-color: rgba(16, 16, 16, .95) !important
}

.background-100-g, .background-g {
  background-color: #101010 !important
}

.background-lite-g {
  background-color: rgba(16, 16, 16, .57081545) !important
}

.background-lite-lite-g {
  background-color: rgba(16, 16, 16, .28571429) !important
}

.background-hard-g, .background-hard-hard-g {
  background-color: #101010 !important
}

.border-background-g {
  border-color: rgba(16, 16, 16, .7) !important
}

.border-text-g {
  border-color: rgba(175, 175, 175, .7) !important
}

.border-highlight-g {
  border-color: rgba(219, 171, 131, .7) !important
}

.border-heading-g {
  border-color: rgba(255, 255, 255, .7) !important
}

.border-link-g {
  border-color: rgba(175, 175, 175, .7) !important
}

.heading-g {
  color: #fff !important
}

.text-g {
  color: #afafaf !important
}

.text-background-g {
  color: #101010 !important
}

.background-highlight-g {
  background-color: #7a8288 !important
}

.background-heading-g {
  background-color: #fff !important
}

.background-link-g, .background-text-g {
  background-color: #afafaf !important
}

.highlight-g {
  color: #7a8288 !important
}

.link-g {
  color: #afafaf !important
}

.colors-h {
  color: #efefef;
  background-color: rgba(15, 14, 14, .85)
}

.colors-h .bg-color {
  color: #0f0e0e
}

.colors-h .out {
  opacity: .85
}

.colors-h .heading, .colors-h.heading {
  color: #fff
}

.colors-h .link, .colors-h.link {
  color: #afafaf
}

.colors-h .highlight, .colors-h.highlight {
  color: #7a8288
}

.colors-h .background, .colors-h.background {
  background-color: rgba(15, 14, 14, .85)
}

.colors-h .background-solid, .colors-h.background-solid {
  background-color: #0f0e0e
}

.colors-h .background-hard, .colors-h.background-hard {
  background-color: rgba(15, 14, 14, .95)
}

.colors-h .background-lite, .colors-h.background-lite {
  background-color: rgba(15, 14, 14, .48519313)
}

.colors-h .col-icon, .colors-h .col-left-marginalia, .colors-h col-right-marginalia, .colors-h h1, .colors-h h2, .colors-h h3, .colors-h h4, .colors-h h5, .colors-h h6 {
  color: #fff
}

.colors-h a, a.colors-h {
  color: #afafaf
}

.colors-h a:hover, a.colors-h:hover {
  color: rgba(175, 175, 175, .75)
}

.colors-h .line {
  border-color: #efefef;
  border-color: rgba(239, 239, 239, .3)
}

.colors-h .line:hover {
  background-color: rgba(15, 14, 14, .48519313)
}

.colors-h .help-block li {
  color: #7a8288
}

.colors-h .grid-example > .row > div:nth-child(1n) {
  color: #fff;
  background-color: rgba(239, 239, 239, .1)
}

.colors-h .grid-example > .row > div:nth-child(2n) {
  color: #fff;
  background-color: rgba(239, 239, 239, .3)
}

.colors-h ::-webkit-input-placeholder {
  color: rgba(239, 239, 239, .6)
}

.colors-h :-moz-placeholder {
  color: rgba(239, 239, 239, .6);
  opacity: 1
}

.colors-h ::-moz-placeholder {
  color: rgba(239, 239, 239, .6);
  opacity: 1
}

.colors-h :-ms-input-placeholder {
  color: rgba(239, 239, 239, .6)
}

.colors-h .border, .colors-h .border-bottom, .colors-h .border-left, .colors-h .border-right, .colors-h .border-top, .colors-h.border, .colors-h.border-bottom, .colors-h.border-left, .colors-h.border-right, .colors-h.border-top {
  border-color: rgba(255, 255, 255, .3) !important
}

.colors-h .border-hard, .colors-h.border-hard {
  border-color: rgba(255, 255, 255, .7) !important
}

.colors-h .border-lite, .colors-h.border-lite {
  border-color: rgba(255, 255, 255, .08) !important
}

.colors-h .text {
  color: #efefef !important
}

.colors-h .text-background, .colors-h.text-background {
  color: #0f0e0e !important
}

.colors-h .background-highlight, .colors-h.background-highlight {
  background-color: #7a8288 !important
}

.colors-h .background-heading, .colors-h.background-heading {
  background-color: #fff !important
}

.colors-h .background-text, .colors-h.background-text {
  background-color: #efefef !important
}

.colors-h .background-link, .colors-h.background-link {
  background-color: #afafaf !important
}

.colors-h .border-text, .colors-h.border-text {
  border-color: rgba(239, 239, 239, .7) !important
}

.colors-h .border-highlight, .colors-h.border-highlight {
  border-color: rgba(219, 171, 131, .7) !important
}

.colors-h .border-heading, .colors-h.border-heading {
  border-color: rgba(255, 255, 255, .7) !important
}

.colors-h .border-link, .colors-h.border-link {
  border-color: rgba(175, 175, 175, .7) !important
}

.colors-h .background-0, .colors-h.background-0 {
  background-color: transparent !important
}

.colors-h .background-5, .colors-h.background-5 {
  background-color: rgba(15, 14, 14, .05) !important
}

.colors-h .background-10, .colors-h.background-10 {
  background-color: rgba(15, 14, 14, .1) !important
}

.colors-h .background-15, .colors-h.background-15 {
  background-color: rgba(15, 14, 14, .15) !important
}

.colors-h .background-20, .colors-h.background-20 {
  background-color: rgba(15, 14, 14, .2) !important
}

.colors-h .background-25, .colors-h.background-25 {
  background-color: rgba(15, 14, 14, .25) !important
}

.colors-h .background-30, .colors-h.background-30 {
  background-color: rgba(15, 14, 14, .3) !important
}

.colors-h .background-35, .colors-h.background-35 {
  background-color: rgba(15, 14, 14, .35) !important
}

.colors-h .background-40, .colors-h.background-40 {
  background-color: rgba(15, 14, 14, .4) !important
}

.colors-h .background-45, .colors-h.background-45 {
  background-color: rgba(15, 14, 14, .45) !important
}

.colors-h .background-50, .colors-h.background-50 {
  background-color: rgba(15, 14, 14, .5) !important
}

.colors-h .background-55, .colors-h.background-55 {
  background-color: rgba(15, 14, 14, .55) !important
}

.colors-h .background-60, .colors-h.background-60 {
  background-color: rgba(15, 14, 14, .6) !important
}

.colors-h .background-65, .colors-h.background-65 {
  background-color: rgba(15, 14, 14, .65) !important
}

.colors-h .background-70, .colors-h.background-70 {
  background-color: rgba(15, 14, 14, .7) !important
}

.colors-h .background-75, .colors-h.background-75 {
  background-color: rgba(15, 14, 14, .75) !important
}

.colors-h .background-80, .colors-h.background-80 {
  background-color: rgba(15, 14, 14, .8) !important
}

.colors-h .background-85, .colors-h.background-85 {
  background-color: rgba(15, 14, 14, .85) !important
}

.colors-h .background-90, .colors-h.background-90 {
  background-color: rgba(15, 14, 14, .9) !important
}

.colors-h .background-95, .colors-h.background-95 {
  background-color: rgba(15, 14, 14, .95) !important
}

.colors-h .background-100, .colors-h.background-100 {
  background-color: #0f0e0e !important
}

.colors-h h2::after {
  background-color: rgba(255, 255, 255, .4)
}

.colors-h h3 a {
  color: #fff
}

.colors-h h3 a:hover {
  color: rgba(255, 255, 255, .75)
}

.colors-h h3 {
  border-color: rgba(219, 171, 131, .5)
}

.colors-h input, .colors-h textarea {
  background-color: transparent;
  border-color: rgba(255, 255, 255, .6);
  outline-color: rgba(239, 239, 239, 0)
}

.colors-h input:focus, .colors-h textarea:focus {
  outline-color: rgba(239, 239, 239, .37)
}

#top-nav.colors-h {
  border-color: rgba(219, 171, 131, .16);
  color: #fff
}

#top-nav.colors-h li a {
  color: #fff
}

#top-nav.colors-h li a:hover {
  color: inherit
}

#top-nav.colors-h .navbar-brand, #top-nav.colors-h .navbar-brand:hover {
  color: #fff
}

#top-nav.colors-h .nav > li ul {
  background-color: rgba(15, 14, 14, .85)
}

#top-nav.colors-h .nav > li ul a {
  color: #fff
}

#top-nav.colors-h .nav > li ul a.active {
  color: #7a8288
}

#top-nav.colors-h .nav > li ul a:hover {
  background-color: transparent
}

#top-nav.colors-h li.open > a {
  background-color: rgba(15, 14, 14, .48519313)
}

#top-nav.colors-h .navbar-nav a.active {
  color: #7a8288
}

#top-nav.colors-h .navbar-toggle {
  background-color: transparent !important;
  border-color: #fff
}

#top-nav.colors-h .navbar-toggle .icon-bar {
  background-color: #fff
}

.colors-h.page-border {
  border-color: rgba(219, 171, 131, .16)
}

.colors-h.page-border a, .ext-nav a.colors-h .side-title {
  color: #fff
}

.ext-nav a.colors-h .side-label {
  color: #7a8288
}

.ext-nav a.colors-h:hover .side-title {
  color: #fff
}

.ext-nav a.colors-h:hover .side-label {
  color: #7a8288
}

.colors-h .menu-toggle span, .colors-h .menu-toggle span:after, .colors-h .menu-toggle span:before {
  background-color: #fff
}

.colors-h .menu-toggle.navbar-toggle span, .colors-h .menu-toggle.navbar-toggle span:after, .colors-h .menu-toggle.navbar-toggle span:before {
  background-color: #7a8288
}

.colors-h .menu-toggle.show span {
  background-color: transparent
}

.colors-h .carousel .slick-next, .colors-h .carousel .slick-prev, .colors-h .slider .slick-next, .colors-h .slider .slick-prev {
  background-color: #f9f9f9;
  background-color: rgba(255, 255, 255, .8)
}

.colors-h .carousel .slick-next:before, .colors-h .carousel .slick-prev:before, .colors-h .slider .slick-next:before, .colors-h .slider .slick-prev:before {
  color: #222;
  background-color: transparent
}

.colors-h .carousel .slick-next:hover, .colors-h .carousel .slick-prev:hover, .colors-h .slider .slick-next:hover, .colors-h .slider .slick-prev:hover {
  background-color: #efefef;
  background-color: rgba(255, 255, 255, .6)
}

.colors-h .carousel .slick-dots li button:before, .colors-h .slider .slick-dots li button:before {
  color: #efefef;
  color: rgba(239, 239, 239, .4);
  opacity: 1
}

.colors-h .carousel .slick-dots li.slick-active button:before, .colors-h .slider .slick-dots li.slick-active button:before {
  color: #7a8288;
  color: rgba(239, 239, 239, .7);
  opacity: 1
}

.colors-h .carousel .slick-slide {
  background-color: rgba(15, 14, 14, .85);
  -webkit-box-shadow: 0 0 8px #fff;
  -moz-box-shadow: 0 0 8px #fff;
  box-shadow: 0 0 8px #fff
}

.colors-h .gallery .filter .nav li a {
  color: #fff;
  background-color: transparent
}

.colors-h .gallery .filter .nav li a.active {
  color: #7a8288
}

.colors-h.gallery-overlay .field {
  color: #fff
}

.colors-h .cross:after, .colors-h .cross:before {
  background-color: #fff
}

.colors-h.circle {
  border-color: rgba(15, 14, 14, .85);
  color: #fff
}

.colors-h.circle:after {
  background-color: transparent;
  border-color: rgba(15, 14, 14, .85)
}

.colors-h.circle:hover {
  background-color: transparent;
  color: rgba(15, 14, 14, .85)
}

.colors-h.circle:hover:after {
  border-color: rgba(15, 14, 14, .85)
}

.colors-h .process .process-row .process-step .process-box {
  border-color: rgba(239, 239, 239, .7)
}

.colors-h .process .process-row .process-step .process-box:before {
  background-color: #0f0e0e
}

#dot-scroll.colors-h li a span {
  background-color: #fff
}

#dot-scroll.colors-h li a.active span, #dot-scroll.colors-h li a:hover.active span {
  background-color: rgba(15, 14, 14, .85)
}

#dot-scroll.colors-h li a.active span, #dot-scroll.colors-h li a.target span, #dot-scroll.colors-h li a.target.active span, #dot-scroll.colors-h li a:hover.active span, #dot-scroll.colors-h li a:hover.target span, #dot-scroll.colors-h li a:hover.target.active span {
  border-color: #fff
}

.colors-h.skew.skew-bottom-left {
  border-bottom-color: transparent;
  border-left-color: #0f0e0e;
  background-color: transparent !important
}

.colors-h.skew.skew-bottom-right {
  border-bottom-color: #0f0e0e;
  border-left-color: transparent;
  background-color: transparent !important
}

.colors-h.skew.skew-top-right {
  border-top-color: transparent;
  border-right-color: #0f0e0e;
  background-color: transparent !important
}

.colors-h.skew.skew-top-left {
  border-top-color: #0f0e0e;
  border-right-color: transparent;
  background-color: transparent !important
}

.colors-h .loader {
  border-color: rgba(239, 239, 239, .1)
}

.colors-h .loader li:nth-child(1), .colors-h .loader li:nth-child(2), .colors-h .loader li:nth-child(3), .colors-h .loader li:nth-child(4) {
  background: #efefef
}

.colors-h #comments, .colors-h #comments .comment-list .comment, .colors-h .avatar, .colors-h ul.tags-list li {
  border-color: rgba(255, 255, 255, .3) !important
}

.colors-h .post .post-meta, .colors-h .post .post-meta a {
  color: #fff
}

.colors-h .post .post-meta a:hover {
  color: #7a8288
}

.colors-h .post .post-meta .post-month {
  border-color: rgba(255, 255, 255, .08) !important
}

.colors-h .post h3 a:hover {
  color: #fff
}

.colors-h .post .post-tags {
  color: rgba(239, 239, 239, .85)
}

.colors-h .post .post-month {
  color: #7a8288
}

.colors-h .sidebar .title {
  color: #fff
}

.colors-h .pagination .active a {
  background-color: rgba(255, 255, 255, .85);
  color: #0f0e0e
}

.colors-h .pagination .active a:hover {
  color: #0f0e0e
}

.colors-h .pagination a {
  background-color: transparent;
  border-color: rgba(255, 255, 255, .4) !important;
  color: #fff
}

.colors-h .pagination a:hover {
  color: #7a8288
}

.colors-h .navbar-collapse {
  max-height: none
}

.colors-h .caption, .colors-h .header-details, .colors-h .subtitle, .colors-h .title {
  color: #fff
}

.colors-h .header-details.highlight, .colors-h .slogan, .colors-h .subtitle.highlight, .colors-h .title.highlight {
  color: #7a8288
}

.colors-h .underline-dashed, .colors-h .underline-dotted {
  border-color: rgba(219, 171, 131, .7)
}

.colors-h .underline, .colors-h .underline-highlight {
  border-color: rgba(219, 171, 131, .5)
}

.colors-h .underline-link {
  border-color: rgba(175, 175, 175, .5)
}

.colors-h .underline-heading {
  border-color: rgba(255, 255, 255, .5)
}

.colors-h .underline-text {
  border-color: rgba(239, 239, 239, .5)
}

.colors-h .top-line:before {
  background-color: rgba(219, 171, 131, .7)
}

.colors-h .bottom-line:after, .colors-h .short-underline:after {
  background-color: #7a8288
}

.colors-h .stroke-highlight {
  text-shadow: -1px -1px 0 #7a8288, 1px -1px 0 #7a8288, -1px 1px 0 #7a8288, 1px 1px 0 #7a8288
}

.colors-h .stroke-heading {
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff
}

.colors-h .stroke-foreground {
  text-shadow: -1px -1px 0 #efefef, 1px -1px 0 #efefef, -1px 1px 0 #efefef, 1px 1px 0 #efefef
}

.colors-h .stroke-background {
  text-shadow: -1px -1px 0 rgba(15, 14, 14, .85), 1px -1px 0 rgba(15, 14, 14, .85), -1px 1px 0 rgba(15, 14, 14, .85), 1px 1px 0 rgba(15, 14, 14, .85)
}

.colors-h .label-border {
  border-color: rgba(219, 171, 131, .7);
  outline-color: #7a8288
}

.colors-h .bottom-menu, .colors-h .bottom-menu a, .colors-h .bottom-menu i {
  color: #efefef !important
}

.colors-h .bottom-menu a:hover {
  background-color: transparent;
  color: #7a8288
}

.colors-h .bottom-menu a:hover i {
  color: #7a8288
}

footer.colors-h a i:hover {
  color: #efefef !important
}

.colors-h .main-icon, .colors-h .main-icon:hover {
  color: #fff
}

.colors-h.content.border-bottom, .colors-h.content.border-top, footer.colors-h.border-bottom, footer.colors-h.border-top {
  border-color: rgba(219, 171, 131, .8)
}

.colors-h.credits-overlay .content-container > ul > li {
  color: #7a8288
}

.colors-h.credits-overlay .content-container > ul > li li {
  color: #afafaf
}

.colors-h input.button {
  color: #7a8288
}

.colors-h input.button:hover {
  color: #fff
}

.colors-h iframe.example, .colors-h pre.example {
  border-color: rgba(239, 239, 239, .3) !important
}

.colors-h.help-toc a, .colors-h.help-toc li, .colors-h.help-toc ul {
  color: #fff
}

.colors-h .help-toc-title {
  border-color: rgba(255, 255, 255, .2)
}

.text-h {
  color: #efefef
}

.background-0-h {
  background-color: transparent !important
}

.background-5-h {
  background-color: rgba(15, 14, 14, .05) !important
}

.background-10-h {
  background-color: rgba(15, 14, 14, .1) !important
}

.background-15-h {
  background-color: rgba(15, 14, 14, .15) !important
}

.background-20-h {
  background-color: rgba(15, 14, 14, .2) !important
}

.background-25-h {
  background-color: rgba(15, 14, 14, .25) !important
}

.background-30-h {
  background-color: rgba(15, 14, 14, .3) !important
}

.background-35-h {
  background-color: rgba(15, 14, 14, .35) !important
}

.background-40-h {
  background-color: rgba(15, 14, 14, .4) !important
}

.background-45-h {
  background-color: rgba(15, 14, 14, .45) !important
}

.background-50-h {
  background-color: rgba(15, 14, 14, .5) !important
}

.background-55-h {
  background-color: rgba(15, 14, 14, .55) !important
}

.background-60-h {
  background-color: rgba(15, 14, 14, .6) !important
}

.background-65-h {
  background-color: rgba(15, 14, 14, .65) !important
}

.background-70-h {
  background-color: rgba(15, 14, 14, .7) !important
}

.background-75-h {
  background-color: rgba(15, 14, 14, .75) !important
}

.background-80-h {
  background-color: rgba(15, 14, 14, .8) !important
}

.background-85-h {
  background-color: rgba(15, 14, 14, .85) !important
}

.background-90-h {
  background-color: rgba(15, 14, 14, .9) !important
}

.background-95-h {
  background-color: rgba(15, 14, 14, .95) !important
}

.background-100-h {
  background-color: #0f0e0e !important
}

.background-h {
  background-color: rgba(15, 14, 14, .85) !important
}

.background-lite-h {
  background-color: rgba(15, 14, 14, .48519313) !important
}

.background-lite-lite-h {
  background-color: rgba(15, 14, 14, .24285714) !important
}

.background-hard-h {
  background-color: rgba(15, 14, 14, .95) !important
}

.background-hard-hard-h {
  background-color: rgba(15, 14, 14, .97) !important
}

.border-background-h {
  border-color: rgba(15, 14, 14, .7) !important
}

.border-text-h {
  border-color: rgba(239, 239, 239, .7) !important
}

.border-highlight-h {
  border-color: rgba(219, 171, 131, .7) !important
}

.border-heading-h {
  border-color: rgba(255, 255, 255, .7) !important
}

.border-link-h {
  border-color: rgba(175, 175, 175, .7) !important
}

.heading-h {
  color: #fff !important
}

.text-h {
  color: #efefef !important
}

.text-background-h {
  color: #0f0e0e !important
}

.background-highlight-h {
  background-color: #7a8288 !important
}

.background-heading-h {
  background-color: #fff !important
}

.background-text-h {
  background-color: #efefef !important
}

.background-link-h {
  background-color: #afafaf !important
}

.highlight-h {
  color: #7a8288 !important
}

.link-h {
  color: #afafaf !important
}

.background-transparent {
  background-color: transparent !important
}

.primary {
  background-color: rgba(100, 122, 156, .3)
}

.success {
  background-color: rgba(198, 237, 181, .3)
}

.info {
  background-color: rgba(185, 224, 244, .3)
}

.warning {
  background-color: rgba(247, 239, 199, .3)
}

.danger {
  background-color: rgba(240, 158, 158, .3)
}

body, html {
  -ms-overflow-style: scrollbar
}

body::-webkit-scrollbar-track, html::-webkit-scrollbar-track {
  background-color: #141414;
  border-left: 1px solid rgba(223, 223, 223, .6)
}

body::-webkit-scrollbar-thumb, html::-webkit-scrollbar-thumb {
  border-color: rgba(223, 223, 223, .6) !important;
  background-color: #141414;
  border-radius: 3px
}

body::-webkit-scrollbar, html::-webkit-scrollbar {
  width: 10px;
  height: 10px
}

body::-webkit-scrollbar-thumb, html::-webkit-scrollbar-thumb {
  border: 1px solid
}

.ext-nav, .navbar-collapse, .non-mobile #top-nav, .overlay-window .content-container, textarea {
  -ms-overflow-style: scrollbar;
  scrollbar-face-color: #4d3824;
  scrollbar-track-color: #141414;
  scrollbar-arrow-color: #afafaf;
  scrollbar-highlight-color: #4d3824;
  scrollbar-3dlight-color: #4d3824;
  scrollbar-shadow-color: #4d3824;
  scrollbar-darkshadow-color: #4d3824
}

.ext-nav::-webkit-scrollbar-track, .navbar-collapse::-webkit-scrollbar-track, .non-mobile #top-nav::-webkit-scrollbar-track, .overlay-window .content-container::-webkit-scrollbar-track, textarea::-webkit-scrollbar-track {
  background-color: #141414;
  border-left: 1px solid rgba(223, 223, 223, .6)
}

.ext-nav::-webkit-scrollbar-thumb, .navbar-collapse::-webkit-scrollbar-thumb, .non-mobile #top-nav::-webkit-scrollbar-thumb, .overlay-window .content-container::-webkit-scrollbar-thumb, textarea::-webkit-scrollbar-thumb {
  border-color: rgba(223, 223, 223, .6) !important;
  background-color: #141414;
  border-radius: 3px
}

.ext-nav::-webkit-scrollbar, .navbar-collapse::-webkit-scrollbar, .non-mobile #top-nav::-webkit-scrollbar, .overlay-window .content-container::-webkit-scrollbar, textarea::-webkit-scrollbar {
  width: 10px;
  height: 10px
}

.ext-nav::-webkit-scrollbar-thumb, .navbar-collapse::-webkit-scrollbar-thumb, .non-mobile #top-nav::-webkit-scrollbar-thumb, .overlay-window .content-container::-webkit-scrollbar-thumb, textarea::-webkit-scrollbar-thumb {
  border: 1px solid
}

.example-page, pre {
  -ms-overflow-style: scrollbar;
  scrollbar-face-color: #7a8288;
  scrollbar-track-color: #474340;
  scrollbar-arrow-color: #afafaf;
  scrollbar-highlight-color: #7a8288;
  scrollbar-3dlight-color: #7a8288;
  scrollbar-shadow-color: #7a8288;
  scrollbar-darkshadow-color: #7a8288
}

.example-page::-webkit-scrollbar-track, pre::-webkit-scrollbar-track {
  background-color: #101010;
  border-left: 1px solid rgba(175, 175, 175, .6)
}

.example-page::-webkit-scrollbar-thumb, pre::-webkit-scrollbar-thumb {
  border-color: rgba(255, 255, 255, .6) !important;
  background-color: #0f0e0e;
  border-radius: 3px
}

.example-page::-webkit-scrollbar, pre::-webkit-scrollbar {
  width: 10px;
  height: 10px
}

.example-page::-webkit-scrollbar-thumb, pre::-webkit-scrollbar-thumb {
  border: 1px solid
}

#customize-panel .options {
  -ms-overflow-style: scrollbar;
  scrollbar-face-color: #333;
  scrollbar-track-color: #555;
  scrollbar-arrow-color: #efefef;
  scrollbar-highlight-color: #333;
  scrollbar-3dlight-color: #333;
  scrollbar-shadow-color: #333;
  scrollbar-darkshadow-color: #333
}

#customize-panel .options::-webkit-scrollbar-track {
  background-color: #444;
  border-left: 1px solid #aaa
}

#customize-panel .options::-webkit-scrollbar-thumb {
  border-color: #aaa !important;
  background-color: #444;
  border-radius: 3px
}

#customize-panel .options::-webkit-scrollbar {
  width: 10px;
  height: 10px
}

#customize-panel .options::-webkit-scrollbar-thumb {
  border: 1px solid
}

.scroll-a {
  -ms-overflow-style: scrollbar
}

.scroll-a::-webkit-scrollbar-track {
  background-color: #141414;
  border-left: 1px solid rgba(223, 223, 223, .6)
}

.scroll-a::-webkit-scrollbar-thumb {
  border-color: rgba(223, 223, 223, .6) !important;
  background-color: #141414;
  border-radius: 3px
}

.scroll-a::-webkit-scrollbar {
  width: 10px;
  height: 10px
}

.scroll-a::-webkit-scrollbar-thumb {
  border: 1px solid
}

.scroll-cp {
  -ms-overflow-style: scrollbar
}

.scroll-cp::-webkit-scrollbar-track {
  background-color: #444;
  border-left: 1px solid #aaa
}

.scroll-cp::-webkit-scrollbar-thumb {
  border-color: #aaa !important;
  background-color: #444;
  border-radius: 3px
}

.scroll-cp::-webkit-scrollbar {
  width: 10px;
  height: 10px
}

.scroll-cp::-webkit-scrollbar-thumb {
  border: 1px solid
}

.scroll-b {
  -ms-overflow-style: scrollbar
}

.scroll-b::-webkit-scrollbar-track {
  background-color: #101010;
  border-left: 1px solid rgba(175, 175, 175, .6)
}

.scroll-b::-webkit-scrollbar-thumb {
  border-color: rgba(255, 255, 255, .6) !important;
  background-color: #0f0e0e;
  border-radius: 3px
}

.scroll-b::-webkit-scrollbar {
  width: 10px;
  height: 10px
}

.scroll-b::-webkit-scrollbar-thumb {
  border: 1px solid
}

.ie-scroll-a {
  scrollbar-face-color: #4d3824;
  scrollbar-track-color: #141414;
  scrollbar-arrow-color: #afafaf;
  scrollbar-highlight-color: #4d3824;
  scrollbar-3dlight-color: #4d3824;
  scrollbar-shadow-color: #4d3824;
  scrollbar-darkshadow-color: #4d3824
}

.ie-scroll-b {
  scrollbar-face-color: #7a8288;
  scrollbar-track-color: #474340;
  scrollbar-arrow-color: #afafaf;
  scrollbar-highlight-color: #7a8288;
  scrollbar-3dlight-color: #7a8288;
  scrollbar-shadow-color: #7a8288;
  scrollbar-darkshadow-color: #7a8288
}

.ie-scroll-cp {
  scrollbar-face-color: #333;
  scrollbar-track-color: #555;
  scrollbar-arrow-color: #efefef;
  scrollbar-highlight-color: #333;
  scrollbar-3dlight-color: #333;
  scrollbar-shadow-color: #333;
  scrollbar-darkshadow-color: #333
}